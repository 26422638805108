// Common
import { Component, OnDestroy, Input, OnInit, ViewChild, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Self, Inject } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// RxJS
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-notebooks-list',
  templateUrl: './notebooks-list.component.html',
  styleUrls: ['./notebooks-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class NotebooksListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // ViewChildren
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  // Inputs
  @Input() placeholderText = 'You have no notebooks';
  @Input() filters: Like<NotebooksFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickNotebook = new EventEmitter<Notebook>();
  @Output() openNotebook = new EventEmitter<Notebook>();
  @Output() doubleClickNotebook = new EventEmitter<Notebook>();
  @Output() openNote = new EventEmitter<Note>();

  // Public
  public itemHeight = 94;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  constructor(
    private notebooksService: NotebooksService,
    private notesStateService: NotesAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {

    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.notesStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getRefreshRequired(),
      // Make automatic updates for new notebook
      interval(environment.messageFetchInterval),
      this.notebooksService.getRefreshRequired()
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(
      this.notesStateService.getRefreshAll(),
      this.notebooksService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Notebook[], count: number }> {
    return this.notebooksService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(notebook: Notebook) {
    this.doubleClickNotebook.emit(notebook);
  }

  handleClickItem(notebook: Notebook) {
    this.clickNotebook.emit(notebook);
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
