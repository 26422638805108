// Common
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Message } from '@modules/messages/types/message';
import { Account } from '@modules/account/types/account';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { TimeZone } from '@modules/form-controls/types/timezones';

// Validators
import { NestedValidators } from '@modules/form-controls/validators/nested.validators';

// Services
import { CalendarsService } from '../services/calendars.service';

const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone;

export class Calendar extends Stitch {
  title?: string;
  readOnly?: boolean;
  timeZone?: string;
  parentId?: string;

  constructor(data: any = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.title = data.name || data.title;
      this.readOnly = data.readOnly;
      this.timeZone = data.timeZone || CURRENT_TIMEZONE;
      this.parentId = data.parentId || null;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Calendar {
    const formValues = form.value;

    return new Calendar({
      archived: formValues.archived,
      color: formValues.color,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      title: formValues.title,
      parentId: formValues.parentId,
      readOnly: formValues.readOnly,
      tags: formValues.tags,
      connections: formValues.connections,
      timeZone: formValues.timeZone
    });
  }

  static fromDragData(dragItem: DragData): Calendar {
    return <Calendar>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Calendar {
    return new Calendar({
      title: filters.query || filters.calendar.subject,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.calendar.body,
      parentId: filters.calendar.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted'
    });
  }

  static getDefaultCalendarId(calendars: Calendar[], account: Account, filteredCalendarsIds: string[] = []) {
    const defaultCalendar = calendars.find((calendar) => calendar.title === account.email);

    if (!defaultCalendar) { return; }

    let { id: calendarId } = defaultCalendar;

    if (!filteredCalendarsIds.length) { return calendarId; }

    const writableCalendarsIds = calendars
      .filter(({ readOnly }) => !readOnly)
      .map(({ id }) => id);

    for (const id of filteredCalendarsIds) {
      if (writableCalendarsIds.includes(id)) {
        calendarId = id;
        break;
      }
    }

    return calendarId;
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'parentId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Calendar>, message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_events':
        break;
      case 'all_calendars':
        return { changes: { parentId: null }, message: message += 'Orphan' };
      case 'snoozed':
      case 'archived':
      case 'deleted':
      case 'flagged':
      case 'followed':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.calendar;
  }

  asFormGroup(calendarsService?: CalendarsService): UntypedFormGroup {
    return this.formBuilder.group(
      {
        archived: [this.archived],
        color: [this.color],
        deleted: [this.deleted],
        description: [this.description || ''],
        flagged: [this.flagged],
        followed: [this.followed],
        id: [this.id],
        knots: [this.knots],
        connections: [this.connections],
        linkedInfo: [this.linkedInfo || []],
        title: [this.title, Validators.required],
        parentId: [this.parentId || ''],
        readOnly: [this.readOnly],
        snoozed: [this.snoozed],
        tags: [this.tags || []],
        timeZone: [this.timeZone],
        noNotification: this.noNotification,
        uploads: [this.uploads || [], FileValidators.size(26214400)],
      },
      {
        asyncValidators: [
          NestedValidators.circular('id', 'parentId', calendarsService)
        ]
      }
    );
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      color: this.color,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      name: this.title && this.title.trim(),
      parentId: this.parentId,
      readOnly: !!this.readOnly,
      timeZone: this.timeZone
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title, this.createdAt, this.createdAt, this.createdAt, this.createdAt
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.description = data.bodyText;
    }
  }
}
