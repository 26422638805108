// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { timezones } from '@modules/form-controls/types/timezones';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

// Rx
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './calendar.component.less'],
})
export class CalendarComponent extends BaseStitchComponent<Calendar> implements OnInit, OnDestroy {
  moveDragDataTypes = [DragDataTypes.calendar, DragDataTypes.event];
  dragDataType = DragDataTypes.calendar;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.calendar &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)
  public colorControl = new FormControl('');
  public timezones = timezones;


  constructor (
    injector: Injector,
    protected calendarsService: CalendarsService,
    private eventsService: EventsService,
  ) { super(injector, calendarsService); }

  ngOnInit() {
    this.changed
      .pipe(
        tap(() => this.colorControl.setValue(this.calendar.color, { emitEvent: false })),
        switchMap(() => this.colorControl.valueChanges),
        debounceTime(500),
        switchMap(color => this.calendarsService.update(new Calendar({ ...this.calendar, color }))),
        takeUntil(this.alive)
      )
      .subscribe()

    super.ngOnInit();
  }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    (() => {
      if (dragData.type === DragDataTypes.event) {
        return this.eventsService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { calendarId: this.item.id },
          { message }
        );
      } else if (dragData.type === DragDataTypes.calendar) {
        return this.calendarsService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { parentId: this.item.id },
          { message }
        );
      }
    })()
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe()
  }

  get calendar(): Calendar {
    return this.item;
  }
}
