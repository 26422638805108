import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

@Pipe({
  name: 'lsValue',
  pure: false
})
export class LSValuePipe implements PipeTransform, OnDestroy {
  private currentValue: unknown;
  private subscription: Subscription = null;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {}

  transform(item: LocalStorageItem<unknown>, ...variants: string[]): unknown {
    if (this.subscription) { return this.currentValue; }

    const variant = variants.length ? variants.join('.') : undefined;

    this.currentValue = item.getSync(variant);

    this.subscription = item.get(variant)
      .subscribe(value => {
        this.currentValue = value;
        this.changeDetector.markForCheck();
      });

    return this.currentValue;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
