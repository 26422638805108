// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isNil } from '@modules/common/utils/base';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { RRule, Frequency, Options, ByWeekday, Weekday } from 'rrule'
import { TypedFormGroup } from '@modules/common/utils/form';

// RX
import { Subject, of } from 'rxjs';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

interface RecurrenceForm {
  every?: number,
  everyUnit?: Frequency,
  weeklyDays?: ByWeekday[],
  monthlyType?: 'on-days' | 'on-dates',
  monthlyOnDaysPosition?: number,
  monthlyOnDaysWeekday?: number[],
  monthlyOnDates?: number,
  annuallyOnDaysPosition?: number,
  annuallyOnDaysWeekday?: number[],
  annuallyDays?: number[],
  ends?: 'never' | 'on' | 'after',
  occurences?: number,
  endsOn?: Date,
}

@Component({
  selector: 'app-recurrence-form',
  templateUrl: './recurrence-form.component.html',
  styleUrls: ['./recurrence-form.component.less'],
})
export class RecurrenceFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: FormControl<string>;
  @Input() referenceDate: FormControl<Date>;

  public focused = false;
  public placeholder: string;
  public form = new FormGroup<TypedFormGroup<RecurrenceForm>>({
    every: new FormControl(1),
    everyUnit: new FormControl(RRule.WEEKLY),
    weeklyDays: new FormControl([]),
    monthlyType: new FormControl('on-days'),
    monthlyOnDaysPosition: new FormControl(1),
    monthlyOnDaysWeekday: new FormControl([0]),
    monthlyOnDates: new FormControl(1),
    annuallyOnDaysPosition: new FormControl(1),
    annuallyOnDaysWeekday: new FormControl([0]),
    annuallyDays: new FormControl([]),
    ends: new FormControl('never'),
    occurences: new FormControl(10),
    endsOn: new FormControl(null),
  });
  public everyUnitOptions: DropdownSelectItem<Frequency>[] = [
    { title: 'Day', value: RRule.DAILY },
    { title: 'Week', value: RRule.WEEKLY },
    { title: 'Month', value: RRule.MONTHLY },
    { title: 'Year', value: RRule.YEARLY },
  ];
  public daysOptions = [
    [RRule.MO, 'M'],
    [RRule.TU, 'T'],
    [RRule.WE, 'W'],
    [RRule.TH, 'T'],
    [RRule.FR, 'F'],
    [RRule.SA, 'S'],
    [RRule.SU, 'S'],
  ];
  public monthsOptions = [
    [1, 'Jan'],
    [2, 'Feb'],
    [3, 'Mar'],
    [4, 'Apr'],
    [5, 'May'],
    [6, 'Jun'],
    [7, 'Jul'],
    [8, 'Aug'],
    [9, 'Sep'],
    [10, 'Oct'],
    [11, 'Nov'],
    [12, 'Dec'],
  ];
  public monthlyOnDaysPositionOptions: DropdownSelectItem<number>[] = [
    { title: 'First', value: 1 },
    { title: 'Second', value: 2 },
    { title: 'Third', value: 3 },
    { title: 'Fourth', value: 4 },
    { title: 'Fifth', value: 5 },
    { title: 'Last', value: -1 },
  ];
  public monthlyOnDaysWeekdayOptions: DropdownSelectItem<number>[] = [
    { title: 'Day', value: [0, 1, 2, 3, 4, 5, 6], multiple: true },
    { title: 'Weekday', value: [0, 1, 2, 3, 4], multiple: true },
    { title: 'Weekend Day', value: [5, 6], multiple: true },
    { separator: true },
    { title: 'Monday', value: 0 },
    { title: 'Tuesday', value: 1 },
    { title: 'Wednesday', value: 2 },
    { title: 'Thursday', value: 3 },
    { title: 'Friday', value: 4 },
    { title: 'Saturday', value: 5 },
    { title: 'Sunday', value: 6 },
  ]
  public RRule = RRule;

  private alive = new Subject<void>();
  private inputControlChanged = new Subject<void>();
  private referenceDateChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        switchMap(() => (
          this.control?.valueChanges || of(null))
            .pipe(startWith(this.control?.value)
        )),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        let rule: RRule;

        try {
          rule = RRule.fromString(value);
        } catch (e) {}

        this.placeholder = rule ? rule.toText() : 'Does Not Repeat';

        if (!rule) { return; }

        if (this.ruleFromForm(this.form.value).toString() === this.control.value) { return; }

        this.form.patchValue(this.formFromRule(rule));
      });

    this.inputControlChanged.next();

    this.referenceDateChanged
      .pipe(
        switchMap(() => this.referenceDate?.valueChanges || of(null)),
        filter(date => !!date),
        takeUntil(this.alive)
      )
      .subscribe(date => {
        this.form.controls.monthlyOnDates.setValue(date.getDate())
      });

    this.referenceDateChanged.next();

    this.form.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        const rule = this.ruleFromForm(value);

        if (rule.toString() === this.control.value) { return; }

        this.control.setValue(rule.toString());
        this.control.markAsDirty();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.inputControlChanged.next();
    }

    if ('referenceDate' in changes) {
      this.referenceDateChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  ruleFromForm(value: RecurrenceForm) {
    const ruleObject: Partial<Options> = {
      freq: value.everyUnit,
      interval: value.every,
      dtstart: this.referenceDate?.value,
    };

    switch (value.everyUnit) {
      case RRule.WEEKLY:
        ruleObject.byweekday = value.weeklyDays;
        break;
      case RRule.MONTHLY:
        switch (value.monthlyType) {
          case 'on-days':
            ruleObject.byweekday = value.monthlyOnDaysWeekday
              .map(day => new Weekday(day, value.monthlyOnDaysPosition));
            break;
          case 'on-dates':
            ruleObject.bymonthday = value.monthlyOnDates;
            break;
        }
        break;
      case RRule.YEARLY:
        ruleObject.byweekday = value.annuallyOnDaysWeekday
          .map(day => new Weekday(day, value.annuallyOnDaysPosition));

        ruleObject.bymonth = value.annuallyDays;

        break;
    }

    if (value.ends === 'on' && value.endsOn) {
      ruleObject.until = value.endsOn;
    }

    if (value.ends === 'after' && value.occurences) {
      ruleObject.count = value.occurences;
    }

    return new RRule(ruleObject);
  }

  formFromRule(rule: RRule): RecurrenceForm {
    const values: RecurrenceForm = {
      every: rule?.origOptions?.interval || 1,
      everyUnit: rule?.origOptions?.freq
    };

    console.log('form from rule', rule?.origOptions);

    switch (rule?.origOptions?.freq) {
      case RRule.MONTHLY:
        if (
          Array.isArray(rule?.origOptions?.byweekday) &&
          rule.origOptions.byweekday.length
        ) {
          values.monthlyType = 'on-days';
          values.monthlyOnDaysPosition = rule.origOptions.byweekday[0]['n'];
          values.monthlyOnDaysWeekday = rule.origOptions.byweekday.map(i => i['weekday']);
        } else if (
          typeof rule?.origOptions?.bymonthday === 'number'
        ) {
          values.monthlyType = 'on-dates';
          values.monthlyOnDates = rule.origOptions.bymonthday;
        }

        break;
      case RRule.WEEKLY:
        if (Array.isArray(rule?.origOptions?.byweekday)) {
          values.weeklyDays = rule?.origOptions?.byweekday.filter(i => i['n'] === undefined);
        }
        break;
      case RRule.YEARLY:
        if (
          Array.isArray(rule?.origOptions?.byweekday) &&
          rule.origOptions.byweekday.length
        ) {
          values.annuallyOnDaysPosition = rule.origOptions.byweekday[0]['n'];
          values.annuallyOnDaysWeekday = rule.origOptions.byweekday.map(i => i['weekday']);
        }

        if (Array.isArray(rule?.origOptions?.bymonth)) {
          values.annuallyDays = rule.origOptions.bymonth;
        } else if (!isNil(rule?.origOptions?.bymonth)) {
          values.annuallyDays = [rule.origOptions.bymonth];
        }

        break;
    }

    if (rule?.origOptions?.until) {
      values.endsOn = rule?.origOptions?.until;
      values.ends = 'on';
    } else if (rule?.origOptions?.count) {
      values.occurences = rule?.origOptions?.count;
      values.ends = 'after';
    } else {
      values.ends = 'never';
      values.occurences = 10;
      values.endsOn = null;
    }

    return values;
  }
}
