import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessProperty'
})
export class AccessPropertyPipe implements PipeTransform {
  transform(obj: any, propertyPath: string): any {
    if (!obj || !propertyPath) {
      return obj;
    }

    const properties = propertyPath.split('.');
    let result = obj;

    for (const property of properties) {
      if (result?.hasOwnProperty(property)) {
        result = result[property];
      } else {
        return undefined;
      }
    }

    return result;
  }
}
