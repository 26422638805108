// Common
import { Component, Inject } from '@angular/core';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// RX
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tasking-layout',
  templateUrl: './tasking-layout.component.html',
  styleUrls: ['./tasking-layout.component.less', '../../styles.less']
})
export class TaskingLayoutComponent {
  constructor(@Inject(SPACE_ID) public space: BehaviorSubject<string>) {}
}
