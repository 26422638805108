<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="calendars-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"

  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <!-- calendars -->
  <ng-container *cdkVirtualFor="let calendar of itemsStreamObservable; let index = index">
    <app-calendar
      [item]="calendar"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [debug]="debug"
      (open)="openCalendar.emit(calendar)"
      (appClick)="handleClickItem(calendar)"
      (appDblClick)="handleDoubleClickItem(calendar)"
      [short]="false"
      [withBar]="true"

      [appSelectable]="calendar"
      [appSelectablePosition]="index"
    ></app-calendar>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading && items?.length === 0"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="calendar-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="calendars-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="calendars-stub-image" src="assets/placeholders/placeholder-events.svg"/>
  <p class="calendars-stub-text">{{ placeholderText }}</p>
</div>
