<div class="members">
  <stch-avatar
    *ngFor="let member of members | slice : 0 : 3"
    class="members-avatar"
    [user]="member"
    [size]="24"
    [withStatus]="false"
  />
  <div
    *ngIf="members.length > 3"
    class="members-more"
  >
    {{ members.length > 3 ? '+' + (members.length - 3) : '' }}
  </div>
</div>
