<div class="block">
  <div class="heading">Plans</div>
  <div class="plans">
    <div class="plans-info">
      <div class="heading2">
        @if (currentPlan) {{{ currentPlan.title }}}
        @else if (account?.subscriptionStatus === 'error') { No Plan }
        @else if (account?.subscriptionStatus === 'trial') { Free Plan }
        @else { N/A }
      </div>
      {{ planMessage ?? '' }}
    </div>
      <app-button
        appearance="sapphire-secondary"
        size="m"
        label="Upgrade"
      />
  </div>
  @if (plans?.length === 0) {
    Available plans not found
  } @else {
    <div class="plan-container">
      <div class="stitch-plans">
        <div class="stitch-plans-row stitch-title">
          Choose the best<br/>plan for you
        </div>
        @for (row of rows; track row.value; let index = $index) {
          <div class="stitch-plans-row">
            {{ row.label }}
          </div>
        }
      </div>
      @for (plan of plans; track plan.id) {
        <app-plan-card
          [rows]="rows"
          [badge]="currentPlan?.id === plan.id ? 'Current' : null"
          [plan]="plan"
          [currentPlan]="currentPlan"
          [account]="account"
        />
      }
    </div>
  }

  <br/>
  <br/>
  <div>Bonus Balance: ${{ account?.bonusBalance }}</div>
  <div>Subscription Status: {{ account?.subscriptionStatus }}</div>
  <div>Next Subscription Plan: {{ nextPlanTitle }}</div>
  <div>Next Charge Date: {{ account?.nextChargeDate | date : 'mediumDate' }}</div>
  <div>Next Charge Type: {{ account?.nextChargeType }}</div>
</div>
