// Common
import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-contact-context-menu',
  templateUrl: './contact-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './contact-context-menu.component.less']
})
export class ContactContextMenuComponent extends StitchContextMenuComponent<Contact> implements OnInit, OnChanges {

  @Input() contact: Contact;

  applicationName = Application.contacts;
  gaPrefix = 'contact-context-menu';
  public form: UntypedFormGroup;

  constructor (
    private contactsService: ContactsService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, contactsService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.contact;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('contact' in changes) {
      this.item = this.contact;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) { return; }

    this.contactsService.update(Contact.fromFormGroup(this.form))
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.contact || new Contact()).asFormGroup();
  }

  duplicate() {
    if (!this.contact) { return; }

    const contactsToDuplicate = this.multiple ? this.selectedItems : [this.contact];

    combineLatest(
      contactsToDuplicate.map((contact) => this.contactsService.create(contact, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Contact${ this.multiple ? 's have' : ' has' } been duplicated`
          });
        this.contactsService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.contactsService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`Are you sure you want to remove ${ this.multiple ? 'these' : 'this' } contact${ this.multiple && 's'}?`)
  }
}
