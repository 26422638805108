// Common
import { Component, Input, OnDestroy } from '@angular/core';

// Types
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';

// RX
import { Subject, takeUntil } from 'rxjs';

// Services
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';
import { EditorService } from '@modules/form-controls/services/editor.service';
import { AlertService } from '@modules/alert/services/alert.service';

@Component({
  selector: 'app-settings-messages-quick-reply',
  templateUrl: './quick-reply.component.html',
  styleUrls: ['./quick-reply.component.less'],
  providers: [EditorService],
})
export class QuickReplyComponent implements OnDestroy {
  public editMode = false;
  public form = new QuickReplyTemplate().asFormGroup();
  public submitted = false;

  private alive = new Subject<void>();

  @Input() template: QuickReplyTemplate;
  @Input() first: boolean;

  constructor(
    private quickReplyTemplatesService: QuickReplyTemplatesService,
    private alertsService: AlertService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  toggleEditMode() {
    this.editMode = !this.editMode;

    if (this.editMode) {
      this.form = (this.template || new QuickReplyTemplate()).asFormGroup();
    }
  }

  removeTemplate() {
    this.quickReplyTemplatesService.delete(this.template)
      .pipe(takeUntil(this.alive))
      .subscribe();
  }

  handleRemove() {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Quick Reply Template ${this.template.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeTemplate(),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) { return; }

    const template = QuickReplyTemplate.fromFormGroup(this.form);

    this.quickReplyTemplatesService.upsert(template)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.editMode = false;
        this.form.reset();
        this.submitted = false;
      });
  }
}
