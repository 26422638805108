// Common
import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { Application } from '@modules/common/types/application';
import { StateKey } from '@modules/settings/types/state-key';
import { Team } from '@modules/settings/types/team';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

// RX
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({ template: '' })
export abstract class BaseSidebarContainersTreeComponent<C extends Stitch, I extends Stitch, Filters extends StitchFilters, VirtualFolder> implements OnInit, OnChanges, OnDestroy {
  public items: C[] = [];
  public selectedItemsIds: string[] = [];
  public abstract application: Application;
  public abstract treeStateKey: StateKey;
  public abstract dndPredicate: (stitchItem: Stitch) => (dragData: DragData) => boolean;

  private stitchService: StitchService;
  private searchService: AdvancedSearchService;
  private selectableService: SelectableService;
  private filtersChanged = new BehaviorSubject<void>(null);
  private alertService: AlertService;
  private modalService: ModalService;

  protected abstract selfDragDataTypes: DragDataTypes[];
  protected alive = new Subject<void>();
  protected selectedSynced = false;
  protected popoverService: PopoverService;

  @Input() filters: Filters;
  @Input() team: Team;

  constructor(
    private injector: Injector,
    protected itemsService: BaseStitchService<C, Filters>,
    protected stateService: BaseAppStateService<C, I, VirtualFolder>,
  ) {
    this.searchService = injector.get(AdvancedSearchService);
    this.selectableService = injector.get(SelectableService);
    this.stitchService = injector.get(StitchService);
    this.popoverService = injector.get(PopoverService);
    this.alertService = injector.get(AlertService);
    this.modalService = injector.get(ModalService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectableService.getSelectedItems()
      .pipe(
        filter(() => this.selectedSynced),
        takeUntil(this.alive)
      )
      .subscribe((selectedItems: string[]) => {
        const state = this.searchService.getStateSync();
        state[this.application].containersIds = selectedItems;
        this.searchService.setState(state);
      });

    combineLatest([
      this.searchService.getState(),
      this.filtersChanged
        .pipe(
          filter(() => !!this.filters),
          switchMap(() => this.itemsService.findAll({
            ...this.filters,
            deleted: false,
            archived: false,
            snoozedOnTop: false,
            followedOnTop: false
          }))
        )
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([{ [this.application]: { containersIds } }, items]) => {
        if (this.selectedSynced) {
          this.selectedItemsIds = containersIds;
        } else {
          this.selectedItemsIds = items.reduce((memo, item) => containersIds.includes(item.id) ? [...memo, item.id] : memo, []);

          this.selectableService.setSelectedItems(this.selectedItemsIds.map(id => ({ data: id, identifier: id })));
          this.selectedSynced = true;
        }

        this.items = items;
        this.onItemsReceived();
      });

    this.itemsService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.filtersChanged.next();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.filtersChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  protected onItemsReceived() {}

  abstract handleMove(dragData: DragData, item: Stitch);


  handleClick(item: C) {
    this.stateService.setMainView(item);
  }

  handleDblClick(item: C) {
    this.modalService.openFullForm(item, this.injector);
  }

  public handleStitch(dragData: DragData, item: Stitch) {
    this.stitchService.linkDragData(item, dragData);
  }

  public dndDrop(dragData: DragData, item: Stitch) {
    if (this.selfDragDataTypes.includes(dragData.type)) {
      this.alertService.show({
        title: 'Move or Stitch?',
        body: 'Move or Stitch?',
        rightButtons: [
          {
            title: 'CANCEL',
            close: true
          },
          {
            title: 'Move',
            click: () => {
              this.handleMove(dragData, item);
            },
            close: true
          },
          {
            title: 'Stitch',
            click: () => {
              this.handleStitch(dragData, item);
            },
            close: true
          }
        ]
      });
    } else {
      this.handleStitch(dragData, item);
    }
  }

  protected getIds(items: (Stitch | Tag | Knot | Connection)[]): string[] {
    return items.map(({ id }) => id);
  }
}
