<button
  [type]="type"
  class="button"
  [style.width]="width"
  [class.with-custom-width]="width !== 'auto'"
  [ngClass]="[appearance, size]"
  [class.with-icon]="label && icon"
  [class.with-right-icon]="rightIcon"
  [class.icon-only]="!label && icon"
  [class.disabled]="disabled"
  [disabled]="disabled"
>
  <div *ngIf="icon" class="button-icon-container">
    <mat-icon
      [svgIcon]="icon"
      class="button-icon"
    />
  </div>

  {{ label }}

  <mat-icon
    *ngIf="rightIcon"
    class="button-icon"
    [svgIcon]="rightIcon"
  />
</button>
