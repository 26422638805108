<div class="heading">
  Create New Space

  <div class="filler"></div>

  <app-button
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-close"
    (click)="handleCancel()"
  />
</div>

<div class="form">
  <div class="form-row">
    <div class="form-row-title">Space Name</div>
    <app-input
      class="form-row-input"
      appearance="sapphire-outline"
      size="s"
      placeholder="New Space"
      [inputFormControl]="form.controls.title"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />
  </div>

  <div class="form-row">
    <div class="form-row-title">Color</div>
    <app-color-input
      class="form-row-input"
      [control]="form.controls.color"
      icon="other"
      [size]="16"
      appearance="sapphire-inline"
      palette="spaces"
    />
  </div>

  <div class="form-row form-row-description">
    <div class="form-row-title">Description</div>
    <app-textarea
      class="form-row-input"
      appearance="sapphire-outline"
      [inputFormControl]="form.controls.description"
      [rows]="3"
      label="Description"
    />
  </div>
</div>

<div class="footer">
  <app-button
    label="Create Space"
    appearance="sapphire-primary"
    size="s"
    [disabled]="!form.valid"
    (click)="handleSubmit()"
  />

  <app-button
    label="Cancel"
    appearance="sapphire-secondary"
    size="s"
    (click)="handleCancel()"
  />
</div>
