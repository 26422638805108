// Common
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

// RX
import { EMPTY, fromEvent, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'stch-resizable-container',
  templateUrl: './resizable-container.component.html',
  styleUrls: ['./resizable-container.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableContainerComponent implements OnInit, OnDestroy {
  public isResizing = false;

  private startWidth: number;
  private startHeight: number;
  private startX: number;
  private startY: number;
  private resizing = new Subject<void>();
  private alive = new Subject<void>();

  @Input() horizontalResize = true;
  @Input() verticalResize = true;
  @Input() initialHeight = 120;

  @ViewChild('container', { static: true }) container: ElementRef;

  constructor(
    private ngZone: NgZone,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.resizing
        .pipe(
          switchMap(() => this.isResizing ? fromEvent(document, 'mousemove') : EMPTY),
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) => {
          if (!this.isResizing) { return; }

          let width = this.startWidth;
          let height = this.startHeight;

          if (this.horizontalResize) {
            width = this.startWidth + (event.clientX - this.startX);
          }

          if (this.verticalResize) {
            height = this.startHeight + (event.clientY - this.startY);
          }

          this.container.nativeElement.style.width = `${width}px`;
          this.container.nativeElement.style.height = `${height}px`;
        });
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  onResizeStart(event: MouseEvent): void {
    this.isResizing = true;
    this.startWidth = this.container.nativeElement.offsetWidth;
    this.startHeight = this.container.nativeElement.offsetHeight;
    this.startX = event.clientX;
    this.startY = event.clientY;
    event.preventDefault();

    this.resizing.next();
  }

  @HostListener('document:mouseup')
  onResizeEnd(): void {
    this.isResizing = false;
    this.resizing.next();
  }
}
