<stch-resizable-container
  [initialHeight]="120"
  [horizontalResize]="false"
>
  <editor
    class="editor"
    [formControl]="control"
    [init]="settings"
  />
</stch-resizable-container>

<app-input-errors
  *ngIf="withErrors"
  [inputFormControl]="control"
  [validationMessages]="validationMessages"
/>
