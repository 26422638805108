export enum EditorCommand {
  Bold = 'Bold',
  Italic = 'Italic',
  Underline = 'Underline',
  Strikethrough = 'Strikethrough',
  JustifyLeft = 'JustifyLeft',
  JustifyCenter = 'JustifyCenter',
  JustifyRight = 'JustifyRight',
  InsertOrderedList = 'InsertOrderedList',
  InsertUnorderedList = 'InsertUnorderedList',
}
