export function generateSecondaryColor(hex: string): string {
  if (!hex) { return null; }

  switch (hex) {
    case '#842D44':
      return '#FCF4F4';
    case '#277A79':
      return '#F2FBFA';
    case '#E9590D':
      return '#FFF7ED';
    case '#62A60A':
      return '#F6FFE6';
    case '#8C4CD1':
      return '#FAF6FE';
    case '#2864E8':
      return '#EFF6FF';
  }

  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.ceil(r + (255 - r) * .94);
  g = Math.ceil(g + (255 - g) * .94);
  b = Math.ceil(b + (255 - b) * .94);

  r = r > 255 ? 255 : r;
  g = g > 255 ? 255 : g;
  b = b > 255 ? 255 : b;

  const newHex = '#' +
    (r < 16 ? '0' : '') + r.toString(16) +
    (g < 16 ? '0' : '') + g.toString(16) +
    (b < 16 ? '0' : '') + b.toString(16);

  return newHex.toUpperCase();
}
