// Common
import { Component, Input } from '@angular/core';
import { TeamParticipant } from '@modules/settings/types/team-participant';

@Component({
  selector: 'stch-members-avatars',
  templateUrl: './members-avatars.component.html',
  styleUrls: ['./members-avatars.component.less']
})
export class MembersAvatarsComponent {

  @Input() members: TeamParticipant[] = [];

}
