export class SubscriptionPlan {
  description: string;
  id: string;
  title: string;
  monthlyPrice: number;
  annuallyPrice: number;
  maxSpaces: number;
  maxSpaceMembers: number;
  maxTeams: number;
  maxTeamMembers: number;
  maxInboxes: number;
  maxDiskSpace: number;
  additionalDiskSpacePricePerGb: number;
  taskingAppEnabled: boolean;
  mailAppEnabled: boolean;
  calendarAppEnabled: boolean;
  notesAppEnabled: boolean;
  contactsAppEnabled: boolean;
  filesAppEnabled: boolean;
  knotsEnabled: boolean;
  tagsEnabled: boolean;
  connectionsEnabled: boolean;
  maxKnotsCount: number;
  maxTagsCount: number;
  maxConnectionsCount: number;

  constructor(data: any = {}) {
    this.description = data?.description;
    this.id = data?.id;
    this.title = data?.title;
    this.monthlyPrice = data?.monthlyPrice;
    this.annuallyPrice = data?.annuallyPrice;
    this.maxSpaces = data?.maxSpaces;
    this.maxSpaceMembers = data?.maxSpaceMembers;
    this.maxTeams = data?.maxTeams;
    this.maxTeamMembers = data?.maxTeamMembers;
    this.maxInboxes = data?.maxInboxes;
    this.maxDiskSpace = data?.maxDiskSpace;
    this.additionalDiskSpacePricePerGb = data?.additionalDiskSpacePricePerGb;
    this.taskingAppEnabled = !!data?.taskingAppEnabled;
    this.mailAppEnabled = !!data?.mailAppEnabled;
    this.calendarAppEnabled = !!data?.calendarAppEnabled;
    this.notesAppEnabled = !!data?.notesAppEnabled;
    this.contactsAppEnabled = !!data?.contactsAppEnabled;
    this.filesAppEnabled = !!data?.filesAppEnabled;
    this.knotsEnabled = !!data?.knotsEnabled;
    this.tagsEnabled = !!data?.tagsEnabled;
    this.connectionsEnabled = !!data?.connectionsEnabled;
    this.maxKnotsCount = data?.maxKnotsCount;
    this.maxTagsCount = data?.maxTagsCount;
    this.maxConnectionsCount = data?.maxConnectionsCount;
  }
}
