// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getMonths } from '@modules/common/utils/date';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { Subject, of } from 'rxjs';
import { distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

const CURRENT_YEAR_POSITION = 4;

@Component({
  selector: 'app-dropdown-month-year-input',
  templateUrl: './dropdown-month-year-input.component.html',
  styleUrls: ['./dropdown-month-year-input.component.less'],
})
export class DropdownMonthYearInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() placement: PopoverPlacement = 'bottomLeft';
  @Input() title: string;

  public months = getMonths();
  public years: number[] = [];
  public popoverHide = new Subject();
  public today = new Date();
  public view: 'months' | 'years' = 'months';

  private alive = new Subject<void>();
  private inputControlChanged = new Subject<void>();
  public yearsPage = 0;
  private baseYear = (new Date()).getFullYear();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        switchMap(() => this.control
          ? this.control.valueChanges.pipe(startWith(this.control.value))
          : of(null)
        ),
        filter(value => !!value),
        distinctUntilChanged((a, b) => a.getFullYear() === b.getFullYear()),
        takeUntil(this.alive)
      )
      .subscribe((value) => {
        const newPage = Math.floor((value.getFullYear() - this.baseYear + CURRENT_YEAR_POSITION) / 12);

        if (newPage === this.yearsPage && this.years.length > 0) { return; }

        this.yearsPage = newPage;

        this.generateYearsList();
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleMonthSelect(monthIndex: number): void {
    if (this.disabled) { return; }

    this.control.setValue(new Date(
      this.control.value.getFullYear(),
      monthIndex,
      this.control.value.getDate()
    ));
  }

  handleYearSelect(year: number): void {
    this.control.setValue(new Date(
      year,
      this.control.value.getMonth(),
      this.control.value.getDate()
    ));
  }

  handleChangeYearsViewport(direction: 1 | -1) {
    this.yearsPage += direction;
    this.generateYearsList();
  }

  generateYearsList() {
    const viewportOffset = this.baseYear + this.yearsPage * 12;
    this.years = [...Array(12).keys()].map(index => viewportOffset - CURRENT_YEAR_POSITION + index);
  }
}
