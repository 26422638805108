<div
  class="container"

  stchPopover
  stchPopoverTrigger="click"
  [stchPopoverTemplate]="prioritiesListTemplate"
  stchPopoverPlacement="right"
  stchPopoverAppearance="sapphire-context-menu"
  [stchPopoverShowUntil]="popoverShowUntil"
  (stchPopoverVisibleChange)="open.emit($event)"
>
  <mat-icon
    class="priority-input-item-flag active"
    [svgIcon]="appearance === 'default' ? 'sapphire-priority' : 'sapphire-priority-flag'"
    [ngClass]="control?.value"

    matTooltip="Priority"
  />

  @if (withLabel) { <span class="priority-input-label">{{ control.value | titlecase }}</span> }
</div>

<ng-template #prioritiesListTemplate>
  <div class="priority-input">
    @for(priority of prioritiesList; track priority.name) {
      <div class="priority-input-item">
        <div class="priority-input-item-title">
          {{ priority.title }}
        </div>

        <mat-icon
          class="priority-input-item-flag"
          [class.active]="priority.name === control?.value"
          [ngClass]="priority.name"
          [svgIcon]="appearance === 'default' ? 'sapphire-priority' : 'sapphire-priority-flag'"
          (click)="handleClick(priority.name)"
        />
      </div>
    }
  </div>
</ng-template>
