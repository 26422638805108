<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled Task"
      [inputFormControl]="form.controls.title"
    />

    <app-priority-input
      class="form-heading-priority"
      [control]="form.controls.priority"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <div class="form-date">
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.fromDate"
      [timeControl]="form.controls.fromTime"
    />
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.toDate"
      [timeControl]="form.controls.toTime"
    />
  </div>

  <app-autocomplete-input
    [inputFormControl]="form.controls.projectId"
    [suggestions]="projectsSuggestions"
    [itemTemplate]="projectTemplate"
    appearance="sapphire-inline"
    size="xs"
    placeholder="Add to project"
    [withClear]="true"
  />

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>

<ng-template #projectTemplate let-item="item">
  <div class="project">
    <div class="project-header">
      <app-circular-progress
        [progress]="item.progress"
        [size]="20"
        [priority]="item.priority"
        [completed]="item.completed"
      />
      <span class="project-header-title">{{ item.title }}</span>
    </div>
    <div class="project-date">
      <span class="project-date-item">{{ item.fromDate | date : 'MMM d, hh:mm a' }}</span>
      <div
        *ngIf="item.fromDate && item.toDate"
        class="project-date-separator"
      ></div>
      <span class="project-date-item">{{ item.toDate | date : 'MMM d, hh:mm a' }}</span>
    </div>
  </div>
</ng-template>
