import { RelativeDate } from '@modules/common/types/relative-date';
import { checkExhaustiveness } from './switch';

const MS_PER_DAY = 86400000; // 1000 * 60 * 60 * 24

export const beginningOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const endOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
};

export const beginningOfWeek = (date: Date): Date => {
  return beginningOfDay(new Date(date.setDate(date.getDate() - date.getDay())));
};

export const endOfWeek = (date: Date): Date => {
  return endOfDay(new Date(date.setDate(date.getDate() - date.getDay() + 7)));
};

export const beginningOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
};

export const endOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
};

export const beginningOfYear = (date: Date): Date => {
  return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
};

export const endOfYear = (date: Date): Date => {
  return new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999);
};

export const addDays = (date: Date, daysAmount: number): Date => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + daysAmount,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
};

export const addHours = (date: Date, amount: number = 0) => new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  date.getHours() + amount,
  date.getMinutes(),
  date.getSeconds(),
  date.getMilliseconds()
);

export const isSameDay = (date: Date, date2: Date): boolean => {
  return (
    date && date2 &&
    date.getFullYear() === date2.getFullYear() &&
    date.getMonth() === date2.getMonth() &&
    date.getDate() === date2.getDate()
  );
};

export const getMonths = () => {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
};

export const relativeDateToDate = (range: RelativeDate): Date => {
  const date = new Date();

  switch (range) {
    case 'today':
      return date;
    case 'tomorrow':
      date.setDate(date.getDate() + 1);
      return date;
    case 'thisWeek':
    case 'weekend':
    case 'nextMonday':
      date.setDate(date.getDate() + (7 - date.getDay()) % 7 + 1);
      return date;
    case 'next2Weeks':
      date.setDate(date.getDate() + 14);
      return date;
    case 'nextMonth':
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    default:
      checkExhaustiveness(range);
  }
};

export function getDayOccurrenceOfMonth(date: Date): number {
  const month = date.getMonth();
  const year = date.getFullYear();

  const firstDayOfMonth = new Date(year, month, 1);
  let occurrence = 0;

  for (let d = firstDayOfMonth; d.getMonth() === month; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === date.getDay()) {
      occurrence++;
    }

    if (d.getDate() === date.getDate()) {
      break;
    }
  }

  return occurrence;
};

export function isLastWeekdayOfMonth(date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();

  const lastDayOfMonth = new Date(year, month + 1, 0);

  return lastDayOfMonth.getDate() - date.getDate() < 7;
}

export function daysDifference(startDate: Date, endDate: Date): number {
  return Math.floor((endDate.getTime() - startDate.getTime()) / MS_PER_DAY);
}
