// Common
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, Injector, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { LocalStorageItem, LSBoolean } from 'src/app/decorators/local-storage.decorator';

// Utils
import { relativeDateToDate } from '@modules/common/utils/date';
import { getFirstError } from '@modules/common/utils/form';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, pairwise, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

// Types
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { ListState as StitchListState } from '@modules/linked-info/types/list-state';
import { ChartState as KnowledgeChartState } from '@modules/knowledge/types/chart-state';
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';

@Component({
  template: ''
})
export abstract class FullFormBaseComponent<T extends Stitch> implements OnInit, OnChanges, OnDestroy {

  @Input() autoSaveAllowed = true;
  @Input() tab: string;
  @Input() dp = false;

  @Output() close = new EventEmitter<void>();

  public abstract tabs: Tab[];
  public abstract tabsStateKey: string;
  public abstract changesKey: string;
  public selectedTab: string;
  public dynamicPanel: string;
  public displayScrollShadow = false;
  public stitchListState: StitchListState;
  public chartState: KnowledgeChartState;
  public saveInProgress = false;
  public submitted = false;
  public form: UntypedFormGroup;
  public displayKnowledge: boolean;
  public contracted = true;

  private knowledgeShowVariant: string;
  @LSBoolean({ lsKey: 'ff-display-knowledge', default: true }) protected displayKnowledgeState: LocalStorageItem<boolean>;
  protected stitchItemChanged = new BehaviorSubject<void>(null);
  protected dragDataType: DragDataTypes;
  protected alive = new Subject<void>();

  private modalService: ModalService;
  protected stitchService: StitchService;
  protected kpService: KnowledgePanelService;
  private dynamicPanelService: DynamicPanelService;
  private toasterService: ToasterService;

  public dndPredicate = (dragData: DragData): boolean =>
    this.stitchItem &&
    !(
      dragData.type === this.dragDataType &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.stitchItem.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    protected injector: Injector,
    private stitchItemsService: BaseStitchService<Stitch, StitchFilters>,
    private stitchAppStateService: BaseAppStateService<any, any, any>
  ) {
    this.modalService = this.injector.get(ModalService);
    this.stitchService = this.injector.get(StitchService);
    this.kpService = this.injector.get(KnowledgePanelService);
    this.dynamicPanelService = this.injector.get(DynamicPanelService);
    this.toasterService = this.injector.get(ToasterService)
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (this.tab) { this.selectedTab = this.tab; }
    if (this.dp) { this.tabsStateKey += 'DP'; }
    this.knowledgeShowVariant = `${this.dp ? 'dp' : 'default'}.${ this.stitchItem.getStitchType() }`;

    this.displayKnowledgeState.get(this.knowledgeShowVariant)
      .pipe(takeUntil(this.alive))
      .subscribe(displayKnowledge => {
        this.displayKnowledge = displayKnowledge;
    });

    this.stitchItemChanged
      .pipe(
        tap(() => this.form = this.asFormGroup()),
        filter(() => this.autoSaveAllowed),
        switchMap(() => this.form.valueChanges),
        startWith(<object> this.form?.value || {}),
        debounceTime(2000),
        pairwise(),
        takeUntil(this.alive)
      )
      .subscribe(([prevForm, currentForm]) => {
        const emitUpdate = this.shouldRefreshList(prevForm, currentForm);
        this.handleSubmit(emitUpdate, true);
      });

    this.dynamicPanelService.getFormTab()
      .pipe(takeUntil(this.alive))
      .subscribe((tab) => this.dynamicPanel = tab);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.changesKey in changes) {
      this.kpService.setSelectedStitchItem(this.stitchItem);

      if (
        changes[this.changesKey].previousValue &&
        changes[this.changesKey].currentValue.id !== changes[this.changesKey].previousValue?.id
      ) {
        this.autoSaveAllowed && this.handleSubmit();
      }

      this.stitchItemChanged.next();
    }
  }

  ngOnDestroy() {
    this.autoSaveAllowed && this.handleSubmit();
    this.kpService.setSelectedStitchItem(null);
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  abstract get stitchItem(): Stitch;

  archive() {
    const newValue = !this.form.controls.archived.value;

    this.form.controls.archived.setValue(newValue);
    newValue && this.form.controls.deleted.setValue(false);

    this.form.controls.archived.markAsDirty();
  }

  pin() {
    this.stitchItemsService.pin({ ids: [this.stitchItem?.id] }, !this.stitchItem?.pinned)
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.stitchItem.pinned = !this.stitchItem.pinned;
      });
  }

  flag() {
    this.stitchItemsService.flag({ ids: [this.stitchItem?.id] }, !this.stitchItem?.flagged)
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.stitchItem.flagged = !this.stitchItem.flagged;
      });
  }

  delete() {
    const newValue = !this.form.controls.deleted.value;

    this.form.controls.deleted.setValue(newValue);
    newValue && this.form.controls.archived.setValue(false);

    this.form.controls.deleted.markAsDirty();
  }

  snooze() {
    const date = this.stitchItem.snoozed
      ? null
      : new Date(Date.now() + 5 * 60 * 1000);

    this.stitchItemsService.snooze({ ids: [this.stitchItem.id] }, date )
      .subscribe(() => this.stitchItem.snoozed = date);
  }

  followUp() {
    const date = this.stitchItem.followed
      ? null
      : new Date(relativeDateToDate('tomorrow').setHours(9, 0, 0, 0));

    this.stitchItemsService.followUp({ ids: [this.stitchItem.id] }, date)
      .subscribe(() => this.stitchItem.followed = date);
  }

  openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setFormItem(null);
      this.dynamicPanelService.setFormTab(null);
    } else {
      this.dynamicPanelService.setFormItem(this.stitchItem);
      this.dynamicPanelService.setFormTab('attachments');
    }
  }

  openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'stitch') {
      this.dynamicPanelService.setFormItem(null);
      this.dynamicPanelService.setFormTab(null);
    } else {
      this.dynamicPanelService.setFormItem(this.stitchItem);
      this.dynamicPanelService.setFormTab('stitch');
    }
  }

  /**
  * Handlers
  * */

  dndDrop(dragData: DragData) {
    if (!this.stitchItem) { return; }

    this.stitchService.linkDragData(this.stitchItem, dragData);
  }

  handleClickItem(item: Stitch): void {
    if (this.dp) { return; }
    this.dynamicPanelService.setFormItem(item);
  }

  handleDblClickItem(item: Stitch): void {
    if (this.dp) {
      this.dynamicPanelService.setFormItem(item);
    } else {
      this.modalService.openFullForm(item, this.injector);
    }
  }

  handleClose() {
    this.close.emit();
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleSubmit(emitListUpdate = true, shouldSetId = false) {
    this.submitted = true;

    if (!this.form.valid) {
      this.toasterService.show({ text: getFirstError(this.form), icon: 'sapphire-close-small' }); // TODO remove after FF redesign
      return;
    }

    if (this.form.pristine) { return; }

    this.form.markAsPristine();

    this.saveInProgress = true;

    const stitchItem = this.fromFormGroup();

    (
      this.form.controls.id.value
        ? this.stitchItemsService.update(stitchItem, { emitUpdate: emitListUpdate })
        : this.stitchItemsService.create(stitchItem)
    )
      .pipe(take(1))
      .subscribe(({ id }) => {
        this.saveInProgress = false;
        if (shouldSetId && !stitchItem.id) {
          this.stitchItem.id = id; // To prevent second unnecessary submitting which will be triggered in ngOnChanges
          stitchItem.id = id;
          this.stitchAppStateService.setMainView(stitchItem);
        }
      },
        () => this.handleError());
  }

  showKnowledge() {
    this.displayKnowledgeState.set(!this.displayKnowledge, this.knowledgeShowVariant);
  }

  handleResize([width]) {
    this.contracted = width < 500;
  }

  /**
   * Helpers
   */

  protected abstract shouldRefreshList(prev: object, current: object): boolean;
  protected abstract fromFormGroup(): T;
  protected abstract asFormGroup(): FormGroup;
}
