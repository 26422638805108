<span class="placeholder" *ngIf="items.length === 0">No Notebooks found</span>

<div appSelectableList>
  <app-sidebar-item
    *nestedFor="
      let notebook of items;
      let index = index;
      let depth = depth;
      let expandable = expandable;
      let itemExpanded = expanded;
      stateKey: treeStateKey;
    "
    [title]="notebook.title"
    [actions]="[{
      icon: 'sapphire-plus',
      template: notebookQuickFormTemplate,
      context: { parentId: notebook.id },
      until: quickFormPopoverClose
    }]"
    [depth]="team ? depth + 2 : depth"
    [badge]="{ template: badgeTemplate, context: { flagged: notebook.flagged, pinned: notebook.pinned } }"
    [iconPadding]="false"
    [expandable]="expandable"
    [expanded]="itemExpanded.value"
    [active]="selectedItemsIds | includesBy : notebook?.id"
    (onExpand)="itemExpanded.next($event)"
    (appClick)="handleClick(notebook)"
    (appDblClick)="handleDblClick(notebook)"
    [appNotebookContextMenu]="notebook"
    [appSelectable]="notebook?.id"
    [appSelectablePosition]="index"
    [appSelectableIdentifier]="notebook?.id"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate(notebook)"
    (appDroppableDrop)="dndDrop($event, notebook)"

    appDraggable
    [appDraggableData]="items | selectedItemsDragData: selectedItemsIds: notebook"
    [appDraggablePlaceholder]="placeholder"
    [appDraggablePlaceholderContext]="{ item: notebook }"
  />
</div>

<ng-template #badgeTemplate let-flagged=flagged let-pinned=pinned>
  <div class="badge-template">
    <mat-icon
      class="badge-template-icon pinned"
      *ngIf="pinned"
      svgIcon="pin-direct"
    />

    <mat-icon
      *ngIf="flagged"
      class="badge-template-icon flagged"
      svgIcon="starred"
    />
  </div>
</ng-template>
