// Common
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@environment';

// Types
import { Team } from '@modules/settings/types/team';
import { TeamParticipant } from '@modules/settings/types/team-participant';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { TeamParticipantsService } from '@modules/settings/services/team-participants.service';

@Component({
  selector: 'stch-members-popover',
  templateUrl: './members-popover.component.html',
  styleUrls: ['./members-popover.component.less']
})
export class MembersPopoverComponent implements OnInit, OnDestroy {

  public members: TeamParticipant[] = [];
  public searchControl = new FormControl<string>('');
  public mailUrl = environment.mailUrl

  private alive = new Subject<void>();
  private reload = new Subject<void>();

  @Input() team: Team;

  @Output() onClose = new EventEmitter();

  constructor(
    private teamParticipantsService: TeamParticipantsService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.teamParticipantsService.search({
          sortBy: 'fullName',
          sortOrder: 'asc',
          limit: 200,
          title: this.searchControl.value,
        }, { team: this.team })),
        takeUntil(this.alive)
      ) 
      .subscribe(({ items: members }) => {
        this.members = members;
      });

    this.searchControl.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });

    this.reload.next();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  hide() {
    this.onClose.emit();
  }
}
