import { Component, Input } from '@angular/core';

@Component({
  selector: 'stch-skeleton',
  templateUrl: './stitch-skeleton.component.html',
  styleUrl: './stitch-skeleton.component.less'
})
export class StitchSkeletonComponent {
  @Input() withBar = true;
  @Input() short = false;
}
