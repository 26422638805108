// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TagsModule } from '@modules/tags/tags.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AttachmentsListComponent } from '@modules/elements/components/attachments-list/attachments-list.component';
import { CalendarComponent } from '@modules/elements/components/calendar/calendar.component';
import { ContactComponent } from './components/contact/contact.component';
import { EventComponent } from './components/event/event.component';
import { EventPopoverPreviewComponent } from './components/event-popover-preview/event-popover-preview.component';
import { FileComponent } from './components/file/file.component';
import { FolderComponent } from './components/folder/folder.component';
import { GroupComponent } from './components/group/group.component';
import { KnowledgeChart2DComponent } from './components/knowledge-chart/knowledge-chart-2d/knowledge-chart-2d.component';
import { KnowledgeChart3DComponent } from './components/knowledge-chart/knowledge-chart-3d/knowledge-chart-3d.component';
import { KnowledgeChartComponent } from './components/knowledge-chart/knowledge-chart.component';
import { LinkInfoBarComponent } from './components/link-info/link-info-bar/link-info-bar.component';
import { MessageComponent } from './components/message/message.component';
import { MessageFolderComponent } from './components/message-folder/message-folder.component';
import { MessageSnippetComponent } from './components/message-snippet/message-snippet.component';
import { MoveToComponent } from './components/move-to/move-to.component';
import { NoteComponent } from './components/note/note.component';
import { NotebookComponent } from './components/notebook/notebook.component';
import { ProjectComponent } from './components/project/project.component';
import { SidebarKnotsComponent } from './components/sidebar-knots/sidebar-knots.component';
import { SidebarTagsComponent } from './components/sidebar-tags/sidebar-tags.component';
import { SidebarTagsKnotsContextMenuComponent } from './components/sidebar-tags-knots-context-menu/sidebar-tags-knots-context-menu.component';
import { StitchBottomBarComponent } from '@modules/elements/components/stitch-bottom-bar/stitch-bottom-bar.component';
import { StitchItemLogoComponent } from '@modules/elements/components/stitch-item-logo/stitch-item-logo.component';
import { StitchPostponeIndicatorsComponent } from '@modules/elements/components/stitch-postpone-indicators/stitch-postpone-indicators.component';
import { StitchSkeletonComponent } from '@modules/elements/components/stitch-skeleton/stitch-skeleton.component';
import { StitchTypeContextMenuItemComponent } from './components/stitch-type-context-menu-item/stitch-type-context-menu-item.component';
import { TaskComponent } from './components/task/task.component';

// Stitch Info Components
import { LinkedInfoListComponent } from './components/link-info/linked-info-list/linked-info-list.component';
import { LinkInfoMessageFolderComponent } from './components/link-info/link-info-message-folder/link-info-message-folder.component';
import { LinkInfoMessageComponent } from './components/link-info/link-info-message/link-info-message.component';
import { LinkInfoEventComponent } from './components/link-info/link-info-event/link-info-event.component';
import { LinkInfoNotebookComponent } from './components/link-info/link-info-notebook/link-info-notebook.component';
import { LinkInfoNoteComponent } from './components/link-info/link-info-note/link-info-note.component';
import { LinkInfoProjectComponent } from './components/link-info/link-info-project/link-info-project.component';
import { LinkInfoTaskComponent } from './components/link-info/link-info-task/link-info-task.component';
import { LinkInfoGroupComponent } from './components/link-info/link-info-group/link-info-group.component';
import { LinkInfoContactComponent } from './components/link-info/link-info-contact/link-info-contact.component';
import { LinkInfoFolderComponent } from './components/link-info/link-info-folder/link-info-folder.component';
import { LinkInfoFileComponent } from './components/link-info/link-info-file/link-info-file.component';
import { StitchToComponent } from './components/link-info/stitch-to/stitch-to.component';
import { StitchInfoPopoverComponent } from './components/link-info/stitch-info-popover/stitch-info-popover.component';

// Quick Forms Components
import { CalendarQuickFormComponent } from './components/quick-forms/calendar-quick-form/calendar-quick-form.component';
import { ContactQuickFormComponent } from './components/quick-forms/contact-quick-form/contact-quick-form.component';
import { EventQuickFormComponent } from './components/quick-forms/event-quick-form/event-quick-form.component';
import { FileQuickFormComponent } from './components/quick-forms/file-quick-form/file-quick-form.component';
import { FolderQuickFormComponent } from './components/quick-forms/folder-quick-form/folder-quick-form.component';
import { GroupQuickFormComponent } from './components/quick-forms/group-quick-form/group-quick-form.component';
import { MessageQuickFormComponent } from '@modules/elements/components/quick-forms/message-quick-form/message-quick-form.component';
import { MessageFolderQuickFormComponent } from './components/quick-forms/message-folder-quick-form/message-folder-quick-form.component';
import { NoteQuickFormComponent } from './components/quick-forms/note-quick-form/note-quick-form.component';
import { NotebookQuickFormComponent } from './components/quick-forms/notebook-quick-form/notebook-quick-form.component';
import { ProjectQuickFormComponent } from './components/quick-forms/project-quick-form/project-quick-form.component';
import { QuickFormComponent } from './components/quick-forms/quick-form/quick-form.component';
import { SmartQuickFormComponent } from './components/quick-forms/smart-quick-form/smart-quick-form.component';
import { TaskQuickFormComponent } from './components/quick-forms/task-quick-form/task-quick-form.component';

// Directives
import { StitchToPopoverDirective } from './directives/stitch-to-popover.directive';
import { StitchInfoPopoverDirective } from './directives/stitch-info-popover.directive';
import { SidebarTagsKnotsContextMenuDirective } from './directives/sidebar-tags-knots-context-menu.directive';

// Pipes
import { AppGroupTitlePipe } from './pipes/app-group-title.pipe';
import { IsMessageSenderVip } from '@modules/elements/pipes/is-message-sender-vip';
import { MessageSenderNamePipe } from '@modules/elements/pipes/message-sender-name.pipe';

// Context Menues
import { CalendarContextMenuComponent } from './components/context-menus/calendar-context-menu/calendar-context-menu.component';
import { CalendarContextMenuDirective } from './directives/calendar-context-menu.directive';
import { ContactContextMenuComponent } from './components/context-menus/contact-context-menu/contact-context-menu.component';
import { ContactContextMenuDirective } from './directives/contact-context-menu.directive';
import { EventContextMenuComponent } from './components/context-menus/event-context-menu/event-context-menu.component';
import { EventContextMenuDirective } from './directives/event-context-menu.directive';
import { FileContextMenuComponent } from './components/context-menus/file-context-menu/file-context-menu.component';
import { FileContextMenuDirective } from './directives/file-context-menu.directive';
import { FolderContextMenuComponent } from './components/context-menus/folder-context-menu/folder-context-menu.component';
import { FolderContextMenuDirective } from './directives/folder-context-menu.directive';
import { GroupContextMenuComponent } from './components/context-menus/group-context-menu/group-context-menu.component';
import { GroupContextMenuDirective } from './directives/group-context-menu.directive';
import { MessageContextMenuComponent } from './components/context-menus/message-context-menu/message-context-menu.component';
import { MessageContextMenuDirective } from './directives/message-context-menu.directive';
import { MessageFolderContextMenuComponent } from './components/context-menus/message-folder-context-menu/message-folder-context-menu.component';
import { MessageFolderContextMenuDirective } from './directives/message-folder-context-menu.directive';
import { NoteContextMenuComponent } from './components/context-menus/note-context-menu/note-context-menu.component';
import { NoteContextMenuDirective } from './directives/note-context-menu.directive';
import { NotebookContextMenuComponent } from './components/context-menus/notebook-context-menu/notebook-context-menu.component';
import { NotebookContextMenuDirective } from './directives/notebook-context-menu.directive';
import { ProjectContextMenuComponent } from './components/context-menus/project-context-menu/project-context-menu.component';
import { ProjectContextMenuDirective } from './directives/project-context-menu.directive';
import { StitchListContextMenuComponent } from './components/context-menus/stitch-list-context-menu/stitch-list-context-menu.component';
import { StitchListContextMenuDirective } from './directives/stitch-list-context-menu.directive';
import { KnowledgeChartContextMenuComponent } from './components/context-menus/knowledge-chart-context-menu/knowledge-chart-context-menu.component';
import { KnowledgeChartContextMenuDirective } from './directives/knowledge-chart-context-menu.directive';
import { TaskContextMenuComponent } from './components/context-menus/task-context-menu/task-context-menu.component';
import { TaskContextMenuDirective } from './directives/task-context-menu.directive';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    SplitViewModule,
    TagsModule,
    UserAvatarModule,
    UtilsModule,
  ],
  declarations: [
    AttachmentsListComponent,
    CalendarComponent,
    ContactComponent,
    EventComponent,
    EventPopoverPreviewComponent,
    FileComponent,
    FolderComponent,
    GroupComponent,
    KnowledgeChart2DComponent,
    KnowledgeChart3DComponent,
    KnowledgeChartComponent,
    LinkInfoBarComponent,
    MessageComponent,
    MessageFolderComponent,
    MessageSnippetComponent,
    NoteComponent,
    NotebookComponent,
    ProjectComponent,
    SidebarKnotsComponent,
    SidebarTagsComponent,
    SidebarTagsKnotsContextMenuComponent,
    StitchBottomBarComponent,
    StitchItemLogoComponent,
    StitchPostponeIndicatorsComponent,
    StitchSkeletonComponent,
    StitchTypeContextMenuItemComponent,
    TaskComponent,

    // Directives
    StitchToPopoverDirective,
    StitchInfoPopoverDirective,
    SidebarTagsKnotsContextMenuDirective,

    // Stitch Info
    LinkInfoMessageFolderComponent,
    LinkInfoMessageComponent,
    LinkInfoEventComponent,
    LinkInfoProjectComponent,
    LinkInfoTaskComponent,
    LinkInfoNotebookComponent,
    LinkInfoNoteComponent,
    LinkInfoGroupComponent,
    LinkInfoContactComponent,
    LinkInfoFolderComponent,
    LinkInfoFileComponent,
    LinkedInfoListComponent,
    StitchToComponent,
    StitchInfoPopoverComponent,

    // Quick Forms
    CalendarQuickFormComponent,
    ContactQuickFormComponent,
    EventQuickFormComponent,
    FileQuickFormComponent,
    FolderQuickFormComponent,
    GroupQuickFormComponent,
    MessageQuickFormComponent,
    MessageFolderQuickFormComponent,
    NoteQuickFormComponent,
    NotebookQuickFormComponent,
    ProjectQuickFormComponent,
    QuickFormComponent,
    SmartQuickFormComponent,
    TaskQuickFormComponent,

    // Pipes
    IsMessageSenderVip,
    MessageSenderNamePipe,
    AppGroupTitlePipe,

    // Context Menues
    CalendarContextMenuComponent,
    CalendarContextMenuDirective,
    ContactContextMenuComponent,
    ContactContextMenuDirective,
    EventContextMenuComponent,
    EventContextMenuDirective,
    FileContextMenuComponent,
    FileContextMenuDirective,
    FolderContextMenuComponent,
    FolderContextMenuDirective,
    GroupContextMenuComponent,
    GroupContextMenuDirective,
    MessageContextMenuComponent,
    MessageContextMenuDirective,
    MessageFolderContextMenuComponent,
    MessageFolderContextMenuDirective,
    NoteContextMenuComponent,
    NoteContextMenuDirective,
    NotebookContextMenuComponent,
    NotebookContextMenuDirective,
    ProjectContextMenuComponent,
    ProjectContextMenuDirective,
    StitchListContextMenuComponent,
    StitchListContextMenuDirective,
    KnowledgeChartContextMenuComponent,
    KnowledgeChartContextMenuDirective,
    TaskContextMenuComponent,
    TaskContextMenuDirective,
    MoveToComponent,
  ],
  exports: [
    AttachmentsListComponent,
    CalendarComponent,
    ContactComponent,
    EventComponent,
    EventPopoverPreviewComponent,
    FileComponent,
    FolderComponent,
    GroupComponent,
    KnowledgeChartComponent,
    LinkInfoBarComponent,
    MessageComponent,
    MessageFolderComponent,
    MessageSnippetComponent,
    NoteComponent,
    NotebookComponent,
    ProjectComponent,
    SidebarKnotsComponent,
    SidebarTagsComponent,
    StitchItemLogoComponent,
    StitchTypeContextMenuItemComponent,
    TaskComponent,

    // Directives
    StitchToPopoverDirective,

    // Stitch Info
    LinkInfoMessageFolderComponent,
    LinkInfoMessageComponent,
    LinkInfoEventComponent,
    LinkInfoProjectComponent,
    LinkInfoTaskComponent,
    LinkInfoNotebookComponent,
    LinkInfoNoteComponent,
    LinkInfoGroupComponent,
    LinkInfoContactComponent,
    LinkInfoFolderComponent,
    LinkInfoFileComponent,
    LinkedInfoListComponent,
    StitchToComponent,

    // Quick Forms
    CalendarQuickFormComponent,
    ContactQuickFormComponent,
    EventQuickFormComponent,
    FileQuickFormComponent,
    FolderQuickFormComponent,
    GroupQuickFormComponent,
    MessageQuickFormComponent,
    MessageFolderQuickFormComponent,
    NoteQuickFormComponent,
    NotebookQuickFormComponent,
    ProjectQuickFormComponent,
    QuickFormComponent,
    SmartQuickFormComponent,
    TaskQuickFormComponent,

    // Context Menues
    CalendarContextMenuDirective,
    ContactContextMenuDirective,
    EventContextMenuDirective,
    FileContextMenuDirective,
    FolderContextMenuDirective,
    GroupContextMenuDirective,
    KnowledgeChartContextMenuDirective,
    MessageContextMenuDirective,
    MessageFolderContextMenuDirective,
    NoteContextMenuDirective,
    NotebookContextMenuDirective,
    ProjectContextMenuDirective,
    StitchListContextMenuDirective,
    TaskContextMenuDirective,
  ]
})
export class ElementsModule { }
