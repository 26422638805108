<!-- Avatar -->
<div
  class="user-image"
  container="body"
  [ngStyle]="{
    'width.px': size,
    'height.px': size,
    'line-height.px': size,
    'background-color': !avatar || avatar.isNotFound ? color : '',
    'cursor': usePopover && contact ? 'pointer' : 'inherit',
    'fontSize.px': size / 2
  }"
  stchPopover
  [stchPopoverArrow]="true"
  [stchPopoverContent]="userPopover"
  [stchPopoverDisabled]="!usePopover"
  stchPopoverTrigger="hover"
  stchPopoverPlacement="top"
>
  {{ initials }}

  <img class="photo"
    *ngIf="avatar && !avatar.isNotFound"
    [style.opacity]="opacity"
    [src]="avatar?.url || ''"
    (load)="saveAvatar()"
    (error)="getAvatar()"
   />
  <ng-content></ng-content>
</div>

<ng-template #userPopover>
  <div class="contact-popover">
    <div class="name">{{contact?.name}}</div>
    <div class="email">{{contact?.email}}</div>
  </div>
</ng-template>
