<div
  *ngIf="appearance === 'default'"
  class="container"
  [ngClass]="appearance"
  [style.height.px]="size"
  [style.width.px]="size"
>
  <mat-icon
    *ngIf="appearance === 'default'"
    class="icon"
    [ngClass]="appearance"
    [svgIcon]="icon"
    [style.color]="control?.value"
    [style.height.px]="size"
    [style.width.px]="size"
    stchPopover
    [stchPopoverContent]="popoverTemplate"
    stchPopoverTrigger="click"
    stchPopoverPlacement="bottom"
    matTooltip="Color"
  />
</div>

<div
  *ngIf="appearance === 'sapphire'"
  class="container"
  [ngClass]="appearance"
  [style.height.px]="size"
  [style.width.px]="size"
>
  <div
    class="circle"
    [style.backgroundColor]="control?.value || '#655AFA'"
    stchPopover
    [stchPopoverContent]="popoverTemplate"
    stchPopoverTrigger="click"
    stchPopoverPlacement="bottom"
    (stchPopoverVisibleChange)="opened = $event"
  >
    <mat-icon
      class="icon"
      [class.opened]="opened"
      svgIcon="sapphire-chevron-down"
    />
  </div>
</div>

<ng-template #popoverTemplate>
  <div
    class="popover"
    [ngClass]="appearance"
  >
    <div class="list">
      <div
        class="container"
        *ngFor="let color of palettes[palette]"
      >
        <div
          class="circle"
          [style.backgroundColor]="color"
          (click)="handleSelect(color)"
        >
          <mat-icon
            *ngIf="control?.value === color"
            class="check-mark"
            svgIcon="check-mark"
          />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  *ngIf="appearance === 'sapphire-inline'"
  class="inline-container"
>
  <div
    class="item"
    *ngFor="let color of palettes[palette]"
    [style.background-color]="color"
    (click)="handleSelect(color)"
  >
    <mat-icon
      *ngIf="control?.value === color"
      class="item-check-mark"
      svgIcon="sapphire-check"
    />
  </div>
</div>
