// Common
import { Component, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { Team } from '@modules/settings/types/team';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';
import { Tab } from '@modules/common/types/tab';
import { FormControl } from '@angular/forms';
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { TeamsFilters } from '@modules/settings/types/teams-filters';
import { Account } from '@modules/account/types/account';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { TeamsService } from '@modules/settings/services/teams.service';
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { TeamParticipantsService } from '@modules/settings/services/team-participants.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, merge } from 'rxjs';

// Components
import { TeamFormComponent } from './team-form/team-form.component';

@Component({
  selector: 'app-settings-teams-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.less']
})
export class SpacesTeamsComponent implements OnInit, OnDestroy {

  public teams: Team[] = [];
  public currentPlan: SubscriptionPlan;
  public selectedTab = 'default';
  public tabs: Tab[] = [
    { title: 'Your Teams', value: 'default'},
    { title: 'Archived Teams', value: 'archived'},
  ];
  public searchControl = new FormControl<string>('');
  public columns: TableColumn[];
  public page = 0;
  public perPage = 10;
  public sortBy: TeamsFilters['sortBy'] = 'title';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public total = 0;

  private reload = new Subject<void>();
  private alive = new Subject<void>();
  private account: Account;

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<void>;
  @ViewChild('participantsTemplate', { static: true }) participantsTemplate: TemplateRef<void>;

  constructor (
    private alertsService: AlertService,
    private teamsService: TeamsService,
    private injector: Injector,
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
    private elementRef: ElementRef,
    private router: Router,
    private teamParticipantsService: TeamParticipantsService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columns = [
      { key: 'title', title: 'Team Name', sortable: true, template: this.nameTemplate },
      { key: 'owner.fullName', title: 'Created By' },
      { key: 'participants', title: 'Members', template: this.participantsTemplate },
      { key: null, title: 'Last Active', value: '15 min ago', legend: 'Not Implemented' },
      { key: 'createdAt', title: 'Created On', sortable: true, dateFormat: 'd MMM y' },
      { key: null, title: '', template: this.actionsTemplate, align: 'right' },
    ];

    combineLatest([
      this.accountService.getAccount(true),
      this.plansService.search()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.currentPlan = plans.find(plan => plan.id === account.subscriptionPlanId);
        this.account = account;
        this.reload.next();
      });

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.teamsService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage,
          title: this.searchControl.value,
          archived: this.selectedTab === 'archived',
        }, { account: this.account })),
        takeUntil(this.alive)
      )
      .subscribe(({ items: teams, count, total }) => {
        this.teams = teams;
        this.count = count || 0;
        this.total = total;
      });

    merge(
      this.teamsService.getRefresh(),
      this.searchControl.valueChanges
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: TeamsFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  openForm(team = new Team()) {
    this.alertsService.show({
      appearance: 'sapphire-modal-custom',
      component: TeamFormComponent,
      origin: this.elementRef.nativeElement,
      injector: this.injector,
    });
  }

  removeTeam(item: Team) {
    this.teamsService.delete(item);
  }

  removeTeamPrompt(item: Team) {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Team ${item.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeTeam(item),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }

  handleEdit(team: Team) {
    this.router.navigate([`/settings/teams/${team.id}`]);
  }

  handleTabChange(tab: string) {
    this.selectedTab = tab;
    this.reload.next();
  }

  handleArchive(team: Team) {
    const message = team.archived ? `Team ${ team.title } restored from archive` : `Team ${ team.title } archived`;
    team.archived = !team.archived;

    this.teamsService.update(team, { emit: true, toast: true, message })
      .pipe(takeUntil(this.alive))
      .subscribe();
  }

  accept(team: Team, accepted: boolean) {
    this.teamParticipantsService.accept(team, accepted)
      .pipe(takeUntil(this.alive))
      .subscribe(team => {
        this.reload.next();
      });
  }
}
