@if (message) {
  <div
    class="stitch-list-item"
    [class.selected]="selected && !isDragging"
    [class.hovered]="contextMenuOpened"
    [class.with-bar]="withBar"
    [class.short]="short"

    [appMessageContextMenu]="message"
    (appMessageContextMenuOpened)="contextMenuOpened = $event"
    (appMessageContextMenuOpenMessage)="open.emit()"

    appDraggable
    [appDraggableDisabled]="!dragEnabled"
    [appDraggableData]="dragData"
    [appDraggablePlaceholder]="dragPlaceholder"
    (appDraggableDraggingChanged)="isDragging = $event"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"

    (appClick)="onClick.emit(message)"
    (appDblClick)="onDoubleClick.emit(message)"
  >
    <div class="stitch-list-item-body">
      <div class="stitch-list-item-body-title">
        @if (message.unread) { <div class="stitch-list-item-body-title-unread"></div> }

        <div class="stitch-list-item-body-title-text">
          {{ message | messageSenderName }}

          @if (message | isMessageSenderVip) {
            <mat-icon
              class="stitch-list-item-body-title-text-vip"
              svgIcon="sapphire-vip"
            />
          }
        </div>

        @if (!(message.snoozed | past) && !(message.followed | past)) {
          @if (message.attachmentsCount > 0) {
            <mat-icon
              class="stitch-list-item-body-title-attachment"
              svgIcon="attachment"
            />
          }

          <div class="stitch-list-item-body-title-date">
            {{ message.date | relativeDate }}
          </div>
        }

        <stch-postpone-indicators
          class="stitch-list-item-body-title-postpone-indicators"
          [snoozed]="message.snoozed"
          [followed]="message.followed"
        />
      </div>

      <div class="stitch-list-item-body-item">
        {{ message.subject || 'No Subject' }}
      </div>

      @if (!withTags && !withKnots && !short) {
        <div class="stitch-list-item-body-item">
          {{ message.snippet || 'No body' }}
        </div>
      }

      @if (withTags) {
        <app-list-item-tags
          class="stitch-list-item-body-knowledge"
          [tags]="message.tags"
        />
      }

      @if (withKnots) {
        <app-list-item-knots
          class="stitch-list-item-body-knowledge"
          [knots]="message.knots"
        />
      }

      @if (withBar) {
        <stch-bottom-bar
          [stitchItem]="message"
          [showNested]="false"
          (expand)="barExpanded = $event"
        />
      }

      @if (message.pinned || message.flagged) {
        <div class="stitch-list-item-body-markers">
          @if (message.pinned) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-pin"
            />
          }
          @if (message.flagged) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-flagged"
            />
          }
        </div>
      }
    </div>

    @if (dragEnabled && actionsButtonEnabled) {
      <div class="stitch-list-item-hover-actions" [appClickStopPropagation]="true">
        <stch-quick-action
          icon="sapphire-reply"

          matTooltip="Reply"

          [appClickStopPropagation]="true"
        />

        <stch-quick-action
          icon="sapphire-stitch"

          matTooltip="Stitch To"

          [appStitchToPopover]="message"
          (appStitchToPopoverOpened)="contextMenuOpened = $event"
        />

        <stch-quick-action
          icon="sapphire-archive"
          [active]="message.archived"
          (onClick)="archive()"

          [matTooltip]="message.archived ? 'UnArchive' : 'Archive'"

          [appClickStopPropagation]="true"
        />

        <stch-quick-action
          icon="sapphire-trash"
          [active]="message.deleted"
          (onClick)="delete()"

          [matTooltip]="message?.deleted ? 'Restore from Trash' : 'Trash'"

          [appClickStopPropagation]="true"
        />

        <stch-quick-action
          icon="sapphire-ellipsis"

          matTooltip="More actions"

          stchPopover
          stchPopoverAppearance="sapphire-context-menu"
          stchPopoverPlacement="bottomLeft"
          stchPopoverTrigger="click"
          [stchPopoverShowUntil]="popoverClose"
          [stchPopoverTemplate]="quickActionsContextMenuTemplate"
          (stchPopoverVisibleChange)="contextMenuOpened = $event"

          [appClickStopPropagation]="true"
        />
      </div>
    }

    <div class="drop-zone"></div>

    @if (debug) { <div class="debug">{{ message.score }}</div> }
  </div>
} @else {
  <stch-skeleton [withBar]="withBar" [short]="short"/>
}

<ng-template #dragPlaceholder>
  <app-message [item]="message" [dragEnabled]="false" [withBar]="withBar" [short]="short"/>
</ng-template>

<!--Hover actions context menu-->
<ng-template #quickActionsContextMenuTemplate>
  <app-context-menu-item
    icon="sapphire-pin"
    activeIcon="sapphire-pin-filled"
    appearance="sapphire"
    [title]="message.pinned ? 'Unpin' : 'Pin'"
    [active]="message.pinned"
    (execute)="pin($event)"
  />

  <app-context-menu-item
    icon="sapphire-flagged"
    activeIcon="sapphire-flagged-filled"
    appearance="sapphire"
    [title]="message.flagged ? 'Remove Favorites' : 'Add to Favorite'"
    [active]="message.flagged"
    (execute)="flag($event)"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    icon="sapphire-snooze"
    appearance="sapphire"
    [title]="!!message.snoozed ? 'UnSnooze' : 'Snooze'"
    [active]="!!message.snoozed"
    (execute)="snooze()"
  />

  <app-context-menu-item
    icon="sapphire-follow-up"
    appearance="sapphire"
    [title]="!!message.followed ? 'UnFollow' : 'Follow Up'"
    [active]="!!message.followed"
    (execute)="followUp()"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    icon="sapphire-email-unread"
    activeIcon="sapphire-email"
    appearance="sapphire"
    [title]="!!message.unread ? 'Mark as Read' : 'Mark as Unread'"
    [active]="!!message.unread"
  />

  <app-context-menu-item
    icon="sapphire-spam"
    appearance="sapphire"
    [title]="!!message.spam ? 'Restore from Spam' : 'Move to Spam'"
    [active]="!!message.spam"
  />
</ng-template>
