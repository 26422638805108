// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { Note } from '@modules/notes/types/note';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Message } from '@modules/messages/types/message';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-note-context-menu',
  templateUrl: './note-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './note-context-menu.component.less']
})
export class NoteContextMenuComponent extends StitchContextMenuComponent<Note> implements OnInit, OnChanges {

  @Input() note: Note;

  applicationName = Application.notes;
  gaPrefix = 'notes-context-menu';
  public form: UntypedFormGroup;

  constructor (
    private notesService: NotesService,
    private toasterService: ToasterService,
    private messagesStateService: MailAppStateService,
    injector: Injector,
  ) {
    super(injector, notesService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.note;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('note' in changes) {
      this.item = this.note;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  createMail() {
    this.messagesStateService.composeMessage({
      injector: this.injector,
      message: new Message(this.note)
    });
  }

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.notesService.update(Note.fromFormGroup(this.form))
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.note || new Note()).asFormGroup();
  }

  duplicate() {
    if (!this.note) { return; }

    const notesToDuplicate = this.multiple ? this.selectedItems : [this.note];

    combineLatest(
      notesToDuplicate.map((note) => this.notesService.create(note, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Note${ this.multiple ? 's have' : ' has' } been duplicated`
          });
        this.notesService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.notesService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`Are you sure you want to remove ${ this.multiple ? 'these' : 'this' } note${ this.multiple && 's'}?`)
  }
}
