<div class="stitch-plan-card-row stitch-title" [class.without-badge]="!badge">
  @if (badge) {
    <app-badge [label]="badge"/>
  }
  <div class="heading3">{{ plan.title }}</div>
  <div class="price-container">
    <span class="dollar-sign">$ </span>
    <span class="price">{{ plan.monthlyPrice }}</span>
    <span class="price-period"> / month</span>
  </div>
  <app-button
    class="plan-button"
    appearance="sapphire-primary"
    size="m"
    width="80%"
    [label]="buttonTitle"
    [disabled]="plan.id === currentPlan?.id"
    (appClick)="select()"
  />
</div>
@for (row of rows; track row.value) {
  <div class="stitch-plan-card-row">
    @if (row.type === 'boolean' && plan[row.value]) {
      <mat-icon svgIcon="sapphire-check"/>
    }
    @if (row.type !== 'boolean') {
      {{ row.template ? (row.template | template : plan[row.value]) : plan[row.value] }}
    }
  </div>
}

<ng-template #modalTemplate>
  <div class="modal">
    You`ll upgrade to:
    <ul class="features-list">
      <li>Disk Space: {{ plan.maxDiskSpace }}Gb</li>
      <li>Additional Disk Space Per Gb: ${{ plan.additionalDiskSpacePricePerGb }}</li>
      <li>Tags Count: {{ plan.maxTagsCount }}</li>
      <li>Knots Count: {{ plan.maxKnotsCount }}</li>
      <li>Connections Count: {{ plan.maxConnectionsCount }}</li>
      <li>Inboxes: {{ plan.maxInboxes }}</li>
      <li>Spaces: {{ plan.maxSpaces }}</li>
    </ul>
    Charges will continue until you cancel, and previous charges won’t be refunded unless it’s legally required.
  </div>
</ng-template>
