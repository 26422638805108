// Types
import { GroupsListState } from './groups-list-state';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { ContactEmail, ContactPhone } from '@modules/contacts/types/contact-info';
import { Country } from '@modules/common/types/country';
import { VirtualFolder } from './virtual-folder';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { isNil } from '@modules/common/utils/base';

export class GroupsFilters extends StitchFilters {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  email?: ContactEmail;
  phone?: ContactPhone;
  country?: Country;
  vip?: boolean;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'score' | 'sharedViaLinkAt';

  constructor (filters?: Like<GroupsFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.sortBy = filters?.sortBy;
    this.vip = filters?.vip;
    this.email = new ContactEmail(filters?.email);
    this.phone = new ContactPhone(filters?.phone);
    this.country = new Country(filters?.country);
  }

  applyListState(state: GroupsListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }
    if (!isNil(this.vip)) { result['vip'] = this.vip; }

    if (this.email?.value) {
      result['email'] = this.email.value;
      result['email_category'] = this.email.category;
    }
    if (this.phone?.value) {
      result['phone'] = this.phone.value;
      result['phone_category'] = this.phone.category;
    }

    if (this.country?.iso3) { result['country'] = this.country.iso3; }

    result['sort_by'] =  this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.contacts;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = state.name;
    this.esBody = state.description;
    this.email = state.email;
    this.phone = state.phone;
    this.country = state.country;

    // Sidebar
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      case 'all_contacts':
      case 'all_groups':
      case 'upcoming':
      case 'frequent':
      case 'recent':
        break;
      case 'vip':
        this.vip = true;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
