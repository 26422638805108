<app-context-menu-item
  *ngIf="!(['all_folders', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New File"
>
  <app-file-quick-form
    class="quick-form"
    [file]="stitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  ></app-file-quick-form>
</app-context-menu-item>

<app-context-menu-item
  *ngIf="!(['all_files', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New Folder"
>
  <app-folder-quick-form
    class="quick-form"
    [folder]="containerStitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  ></app-folder-quick-form>
</app-context-menu-item>

<app-context-menu-divider
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
></app-context-menu-divider>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'flagged'"
  title="Unflag all"
  [close]="true"
  (execute)="unFlagAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'followed'"
  title="Unfollow all"
  [close]="true"
  (execute)="unFollowAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'snoozed'"
  title="Unsnooze all"
  [close]="true"
  (execute)="unSnoozeAll()"
></app-context-menu-item>

<app-context-menu-divider
  *ngIf="(['flagged', 'snoozed', 'followed'] | includesBy: sidebarFilterKey)"
></app-context-menu-divider>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'archived'"
  title="Delete all"
  [close]="true"
  (execute)="deleteAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'deleted'"
  title="Empty trash"
  [close]="true"
  (execute)="emptyTrash()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Settings"
  [close]="true"
  (execute)="openSettings()"
></app-context-menu-item>
