// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { AutoReplyTemplate } from '@modules/account/types/auto-reply-template';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AutoReplyTemplatesService } from '@modules/account/services/auto-reply-templates.service';

@Component({
  selector: 'app-settings-messages-auto-replies',
  templateUrl: './auto-replies.component.html',
  styleUrls: ['./auto-replies.component.less']
})
export class AutoRepliesComponent implements OnInit, OnDestroy {
  public autoReplyTemplates: AutoReplyTemplate[];

  private alive = new Subject<void>();

  constructor(
    private autoReplyTemplatesService: AutoReplyTemplatesService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.autoReplyTemplatesService.search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: autoReplyTemplates  }) => this.autoReplyTemplates = autoReplyTemplates);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
