// Common
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// Types
import { EditorCommand } from '../types/editor-command';

@Injectable()
export class EditorService {
  private command = new Subject<EditorCommand>;
  private format = new BehaviorSubject<{ [format: string]: boolean }>({});

  execCommand(command: EditorCommand) {
    this.command.next(command);
  }

  getCommand(): Observable<EditorCommand> {
    return this.command.asObservable();
  }

  setFormat(state: { [format: string]: boolean }) {
    this.format.next({ ...this.format.getValue(), ...state });
  }

  getFormat() {
    return this.format.asObservable();
  }
}
