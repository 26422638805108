<div
  class="errors"
  [ngClass]="[appearance, type, size]"
>
  <div *ngFor="let error of inputFormControl?.errors | keyvalue">
    {{
      validationMessages[error.key]
        ? (validationMessages[error.key] | replace : '%m' : error.value)
        : error.key
    }}
  </div>
</div>
