// Common
import { Pipe, PipeTransform } from '@angular/core';
import mime from '@modules/common/utils/mime';

@Pipe({
  name: 'fileTypeIcon'
})
export class FileTypeToIconPipe implements PipeTransform {
  transform(fileType: string): string {
    const extension = mime.getExtension(fileType)

    switch (extension) {
      case 'doc':
        return 'sapphire-file-word'
      case 'docx':
      case 'txt':
      case 'html':
        return 'sapphire-file-document'
      case 'pdf':
        return 'sapphire-file-pdf'
      case 'xlsx':
        return 'sapphire-file-spreadsheet'
      case 'xls':
        return 'sapphire-file-excel'
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'svg':
        return 'sapphire-file-other'
      case 'zip':
        return 'sapphire-file-zip'
      case 'jar':
        return 'sapphire-file-archive'
      default:
        return 'sapphire-file-other'
    }
  }
}
