<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled Note"
      [inputFormControl]="form.controls.title"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <app-textarea
    appearance="sapphire-inline"
    placeholder="Description"
    [resize]="false"
    [rows]="2"
    [inputFormControl]="form.controls.body"
  />

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>
