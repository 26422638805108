<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled Event"
      [inputFormControl]="form.controls.title"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <div class="form-date">
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.startDate"
      [timeControl]="form.controls.startTime"
    />
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.endDate"
      [timeControl]="form.controls.endTime"
    />
  </div>

  <app-checkbox
    appearance="sapphire"
    title="All Day"
    size="s"
    [inputFormControl]="form.controls.allDay"
  />

  <app-autocomplete-input
    [inputFormControl]="form.controls.calendarId"
    [suggestions]="calendarsSuggestions"
    [itemTemplate]="calendarTemplate"
    appearance="sapphire-inline"
    size="xs"
    placeholder="Add to calendar"
    [withClear]="true"
  />

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        *ngIf="withCancel"
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>

<ng-template #calendarTemplate let-item=item>
  <div class="calendar">
    <div class="calendar-header">
      <div class="calendar-header-color" [style.background-color]="item.color"></div>
      <span class="calendar-header-title">{{ item.title }}</span>
    </div>
    <span class="calendar-description">{{ item.description }}</span>
  </div>
</ng-template>
