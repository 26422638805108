<div class="dropdown">
  <div class="header">
    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-backward"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-chevron-left"
      (click)="handleNextMonthClick(-1)"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-chevron-right"
      (click)="handleNextMonthClick(1)"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-forward"
    />

    <div class="filler"></div>

    <app-dropdown-month-year-input
      [control]="viewDate"
      appearance="sapphire-inline"
      placement="bottomLeft"
      size="l"
      [title]="viewDate.value | date : 'MMM yyyy'"
    />

    <div class="filler"></div>
    <div class="filler"></div>

    <app-button
      appearance="sapphire-primary-ghost"
      size="xs"
      (click)="setToday()"
      label="Today"
    />
  </div>

  @if (showQuickDateActions) {
    <div class="quick-actions">
      <stch-quick-action
        icon="quick-today"
        matTooltip="Today"
        [active]="control?.value | compareToRelativeDate: 'today'"
        (onClick)="handleQuickDateSelect('today')"
      />

      <stch-quick-action
        icon="quick-tomorrow"
        matTooltip="Tomorrow"
        [active]="control?.value | compareToRelativeDate: 'tomorrow'"
        (onClick)="handleQuickDateSelect('tomorrow')"
      />

      <stch-quick-action
        icon="quick-week"
        matTooltip="Next Monday"
        [active]="control?.value | compareToRelativeDate: 'nextMonday'"
        (onClick)="handleQuickDateSelect('nextMonday')"
      />

      <stch-quick-action
        icon="sapphire-quick-two-weeks"
        matTooltip="Next Two Weeks"
        [active]="control?.value | compareToRelativeDate: 'next2Weeks'"
        (onClick)="handleQuickDateSelect('next2Weeks')"
      />

      <stch-quick-action
        icon="sapphire-quick-end-month"
        matTooltip="Next Month"
        [active]="control?.value | compareToRelativeDate: 'nextMonth'"
        (onClick)="handleQuickDateSelect('nextMonth')"
      />
    </div>
  }

  <stch-calendar-month-small-di
    class="calendar"
    [selectedDate]="control?.value"
    [viewDate]="viewDate.value"
    [min]="min"
    [max]="max"
    [range]="range"
    (onDayClick)="handleDateSelect($event)"
  />
</div>
