@if (group) {
  <div
    class="stitch-list-item"
    [class.selected]="selected && !isDragging"
    [class.with-bar]="withBar"
    [class.short]="short"
    [class.bar-expanded]="barExpanded"

    [appGroupContextMenu]="group"
    (appGroupContextMenuOpened)="contextMenuOpened = $event"
    (appGroupContextMenuOpenGroup)="open.emit()"
    (appGroupContextMenuOpenContact)="openContact.emit($event)"

    appDraggable
    [appDraggableDisabled]="!dragEnabled"
    [appDraggableData]="dragData"
    [appDraggablePlaceholder]="dragPlaceholder"
    (appDraggableDraggingChanged)="isDragging = $event"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"
  >
    <div class="stitch-list-item-body">
      <div class="stitch-list-item-body-title">
        <stch-item-logo
          [title]="group.title"
          size="xs"
          color="grey"
        />

        <div class="stitch-list-item-body-title-text">
          {{ group.title || 'Untitled Group' }}
        </div>

        <stch-postpone-indicators
          class="stitch-list-item-body-title-postpone-indicators"
          [snoozed]="group.snoozed"
          [followed]="group.followed"
        />
      </div>

      <div class="stitch-list-item-body-item inline">
        Created: {{ group.createdAt | relativeDate : true : false }}
        <div class="stitch-list-item-body-item-separator"></div>
        Updated: {{ group.updatedAt | relativeDate : true : false }}
      </div>

      @if (!withTags && !withKnots && !short) {
        <div class="stitch-list-item-body-description">
          {{ group.description || 'No description' }}
        </div>
      }

      @if (withTags) {
        <app-list-item-tags
          class="stitch-list-item-body-knowledge"
          [tags]="group.tags"
        />
      }

      @if (withKnots) {
        <app-list-item-knots
          class="stitch-list-item-body-knowledge"
          [knots]="group.knots"
        />
      }

      @if (withBar) {
        <stch-bottom-bar
          [stitchItem]="group"
          (expand)="barExpanded = $event"
        />
      }

      @if (group.pinned || group.flagged) {
        <div class="stitch-list-item-body-markers">
          @if (group.pinned) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-pin"
            />
          }

          @if (group.flagged) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-flagged"
            />
          }
        </div>
      }
    </div>

    @if (dragEnabled && actionsButtonEnabled) {
      <div class="stitch-list-item-hover-actions" [appClickStopPropagation]="true">
        <stch-quick-action
          icon="sapphire-email"
          (onClick)="createMail($event)"

          matTooltip="Send by email"
        />

        <stch-quick-action
          icon="sapphire-stitch"

          matTooltip="Stitch To"

          [appStitchToPopover]="group"
          (appStitchToPopoverOpened)="contextMenuOpened = $event"
        />

        <stch-quick-action
          icon="sapphire-trash"
          [active]="group.deleted"
          (onClick)="delete()"

          [matTooltip]="group?.deleted ? 'Restore from Trash' : 'Trash'"
        />

        <stch-quick-action
          icon="sapphire-ellipsis"

          matTooltip="More actions"

          stchPopover
          stchPopoverAppearance="sapphire-context-menu"
          stchPopoverPlacement="bottomLeft"
          stchPopoverTrigger="click"
          [stchPopoverShowUntil]="popoverClose"
          [stchPopoverTemplate]="quickActionsContextMenuTemplate"
          (stchPopoverVisibleChange)="contextMenuOpened = $event"

          [appClickStopPropagation]="true"
        />
      </div>
    }

    <div class="drop-zone"></div>

    @if (debug) { <div class="debug">{{ group.score }}</div> }
  </div>
} @else {
  <stch-skeleton [withBar]="withBar" [short]="short" />
}

<ng-template #dragPlaceholder>
  <app-group [item]="group" [dragEnabled]="false" [withBar]="withBar" [short]="short"/>
</ng-template>

<!--Hover actions context menu-->
<ng-template #quickActionsContextMenuTemplate>
  <app-context-menu-item
    icon="sapphire-pin"
    activeIcon="sapphire-pin-filled"
    appearance="sapphire"
    [title]="group.pinned ? 'Unpin' : 'Pin'"
    [active]="group.pinned"
    (execute)="pin($event)"
  />

  <app-context-menu-item
    icon="sapphire-flagged"
    activeIcon="sapphire-flagged-filled"
    appearance="sapphire"
    [title]="group.flagged ? 'Remove Favorites' : 'Add to Favorite'"
    [active]="group.flagged"
    (execute)="flag($event)"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    icon="sapphire-snooze"
    appearance="sapphire"
    [title]="!!group.snoozed ? 'UnSnooze' : 'Snooze'"
    [active]="!!group.snoozed"
    (execute)="snooze()"
  />

  <app-context-menu-item
    icon="sapphire-follow-up"
    appearance="sapphire"
    [title]="!!group.followed ? 'UnFollow' : 'Follow Up'"
    [active]="!!group.followed"
    (execute)="followUp()"
  />
</ng-template>
