// Common
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { SendLater } from '../types/send-later';

@Pipe({
  name: 'formatSendLater',
  pure: true
})
export class FormatSendLaterPipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) {}

  transform(value: SendLater): string {
    if (!value) { return '' }

    if (value instanceof Date) {
      const today = new Date()

      if (value.getFullYear() === today.getFullYear()) {
        return this.datePipe.transform(value, 'MMM, d h:mmaaaaa\'m\'');
      } else {
        return this.datePipe.transform(value, 'MMM, d, y h:mmaaaaa\'m\'');
      }
    }

    switch (value) {
      case 'endOfDay':
        return 'End of the day'
      case 'tomorrow':
        return 'Tomorrow'
      default:
        const intervals = {
          'hour': 3600,
          'minute': 60,
          'second': 1
        };

        let counter;

        for (const i in intervals) {
          counter = Math.floor(value / intervals[i]);

          if (counter > 0 ) {
            if (counter === 1) {
              return counter + ' ' + i; // singular (1 hour)
            } else {
              return counter + ' ' + i + 's'; // plural (2 hours)
            }
          }
        }
    }
  }
}
