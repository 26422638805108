<div class="stitch">
  <div class="stitch-title">
    Stitch To
  </div>

  <app-simple-input
    class="stitch-input"
    [suggestions]="suggestions"
    [inputFormControl]="inputControl"
    placeholder=""
    [itemTemplate]="autocompleteTemplate"
    (onSelect)="handleSelect($event)"
    [sideOptions]="inputSideOptions"
    [sideValue]="inputSideValue"
    (onSideValueChange)="setInputSideValue($event)"
  ></app-simple-input>

  <div
    class="stitch-quick-form"
    [ngSwitch]="inputSideValue"
  >
    <app-quick-form
      *ngSwitchCase="'message'"
      placeholder="Compose New Message"
    >
      <app-message-quick-form (afterSave)="handleQuickAfterSave($event)"/>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'event'"
      placeholder="Add New Event"
    >
      <app-event-quick-form (afterSave)="handleQuickAfterSave($event)"></app-event-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'project'"
      placeholder="Add New Project"
    >
      <app-project-quick-form (afterSave)="handleQuickAfterSave($event)"></app-project-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'task'"
      placeholder="Add New Task"
    >
      <app-task-quick-form (afterSave)="handleQuickAfterSave($event)"></app-task-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'notebook'"
      placeholder="Add New Notebook"
    >
      <app-notebook-quick-form (afterSave)="handleQuickAfterSave($event)"></app-notebook-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'note'"
      placeholder="Add New Note"
    >
      <app-note-quick-form (afterSave)="handleQuickAfterSave($event)"></app-note-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'group'"
      placeholder="Add New Group"
    >
      <app-group-quick-form (afterSave)="handleQuickAfterSave($event)"></app-group-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'contact'"
      placeholder="Add New Contact"
    >
      <app-contact-quick-form (afterSave)="handleQuickAfterSave($event)"></app-contact-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'folder'"
      placeholder="Add New Folder"
    >
      <app-folder-quick-form (afterSave)="handleQuickAfterSave($event)"></app-folder-quick-form>
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'file'"
      placeholder="Add New File"
    >
      <app-file-quick-form (afterSave)="handleQuickAfterSave($event)"></app-file-quick-form>
    </app-quick-form>
  </div>

  <app-scroll
    class="stitch-list"
    (top)="scrollShadowTop = !$event"
    (bottom)="scrollShadowBottom = !$event"
  >
    <div class="shadow" *ngIf="scrollShadowTop"></div>
    <app-linked-info-list
      class="stitch-list-component"
      [linkedInfo]="displayedLinkedInfo"
      [state]="stitchListState"
      [withDraggable]="false"
      [withContextMenu]="false"
      [removeOnly]="true"
      [withUnlink]="false"
      placeholder="Search or Add New Items to Stitch Them Together"
      unlinkAllLabel="Remove All"
      (removeItem)="removeItem($event)"
      (unlinkAll)="unlinkAll()"
    ></app-linked-info-list>
  </app-scroll>

  <div class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <div class="stitch-buttons">
    <app-button
      appearance="square"
      label="Cancel"
      size="medium"
      [disabled]="loading"
      (click)="handleCancel()"
    />

    <app-button
      appearance="square-blue"
      label="Stitch"
      size="medium"
      [disabled]="loading"
      (click)="handleSave()"
    />
  </div>
</div>

<ng-template #autocompleteTemplate let-item="item">
  <ng-container [ngSwitch]="inputSideValue">

    <app-link-info-message
      *ngSwitchCase="'message'"
      [message]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-message>

    <app-link-info-event
      *ngSwitchCase="'event'"
      [event]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-event>

    <app-link-info-project
      *ngSwitchCase="'project'"
      [project]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-project>

    <app-link-info-task
      *ngSwitchCase="'task'"
      [task]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-task>

    <app-link-info-notebook
      *ngSwitchCase="'notebook'"
      [notebook]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-notebook>

    <app-link-info-note
      *ngSwitchCase="'note'"
      [note]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-note>

    <app-link-info-group
      *ngSwitchCase="'group'"
      [group]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-group>

    <app-link-info-contact
      *ngSwitchCase="'contact'"
      [contact]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-contact>

    <app-link-info-folder
      *ngSwitchCase="'folder'"
      [folder]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-folder>

    <app-link-info-file
      *ngSwitchCase="'file'"
      [file]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="false"
      [withUnlink]="false"
      viewType="short"
    ></app-link-info-file>
  </ng-container>
</ng-template>
