<ng-container *ngIf="sidebarFilterKey === 'all_messages'">
  <div class="item">
    White List
    <app-toggle-switch
      [inputFormControl]="filters.controls.whiteListEnabled"
    ></app-toggle-switch>
  </div>

  <div class="item">
    Black List
    <app-toggle-switch
      [inputFormControl]="filters.controls.blackListEnabled"
    ></app-toggle-switch>
  </div>

  <div class="item">
    Vip List
    <app-toggle-switch
      [inputFormControl]="filters.controls.vipListEnabled"
    ></app-toggle-switch>
  </div>

  <div class="item">
    Company Domains
    <app-toggle-switch
      [inputFormControl]="filters.controls.companyDomainsListEnabled"
    ></app-toggle-switch>
  </div>
  <app-context-menu-divider></app-context-menu-divider>
</ng-container>

<app-context-menu-item
  *ngIf="!(['sent', 'draft', 'scheduled', 'all_folders'] | includesBy : sidebarFilterKey)"
  title="Mark all as read"
  [close]="true"
  (execute)="readAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'scheduled'"
  title="Send all"
  [close]="true"
  (execute)="sendAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'all_folders'"
  title="Add New Folder"
>
  <app-message-folder-quick-form
    class="quick-form"
    [folder]="containerStitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  ></app-message-folder-quick-form>
</app-context-menu-item>

<app-context-menu-divider *ngIf="!(['sent', 'draft'] | includesBy : sidebarFilterKey)"></app-context-menu-divider>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'flagged'"
  title="Unflag all"
  [close]="true"
  (execute)="unFlagAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'followed'"
  title="Unfollow all"
  [close]="true"
  (execute)="unFollowAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'snoozed'"
  title="Unsnooze all"
  [close]="true"
  (execute)="unSnoozeAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="(['spam', 'archived'] | includesBy: sidebarFilterKey)"
  title="Delete all"
  [close]="true"
  (execute)="deleteAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'deleted'"
  title="Empty trash"
  [close]="true"
  (execute)="emptyTrash()"
></app-context-menu-item>

<app-context-menu-divider *ngIf="(['flagged', 'snoozed', 'followed', 'spam'] | includesBy : sidebarFilterKey)"></app-context-menu-divider>

<app-context-menu-item
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Settings"
  [close]="true"
  (execute)="openSettings()"
></app-context-menu-item>
