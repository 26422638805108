@if (task) {
  <div
    class="stitch-list-item"
    [class.selected]="selected && !isDragging"
    [class.hovered]="contextMenuOpened"
    [class.with-bar]="withBar"
    [class.short]="short"
    [class.bar-expanded]="barExpanded"

    [appTaskContextMenu]="task"
    (appTaskContextMenuOpened)="contextMenuOpened = $event"
    (appTaskContextMenuOpenTask)="open.emit()"
    (appTaskContextMenuOpenSubtask)="openSubtask.emit($event)"

    appDraggable
    [appDraggableDisabled]="!dragEnabled"
    [appDraggableData]="dragData"
    [appDraggablePlaceholder]="dragPlaceholder"
    (appDraggableDraggingChanged)="isDragging = $event"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"
  >
    <div
      class="stitch-list-item-body"
      [class.completed]="task.completed"
    >
      <div class="stitch-list-item-body-title">
        <app-circular-progress
          appearance="sapphire"
          size="16"
          [progress]="task.progress"
          [priority]="task.priority"
          [completed]="task.completed"
          (onClick)="handleComplete()"
        />

        <div
          class="stitch-list-item-body-title-text"
          [class.blank]="!task.title"
        >
          @if (showIssueKey && task.issueKeyTitle) {
            <span class="stitch-list-item-body-title-text issue-key">
              {{ task.issueKeyTitle }}
            </span>
          }

          {{ task.title || 'Untitled Task' }}
        </div>

        <stch-postpone-indicators
          class="stitch-list-item-body-title-postpone-indicators"
          [snoozed]="task.snoozed"
          [followed]="task.followed"
        />

        <div class="stitch-list-item-body-title-column">
          {{ task.columnTitle }}
        </div>
      </div>

      <div class="stitch-list-item-body-item date">
        <stch-date-time-picker
          appearance="inline"
          [dateControl]="form.controls.fromDate"
          [timeControl]="form.controls.fromTime"
          [appClickStopPropagation]="true"
        />

        <div class="stitch-list-item-body-item-separator"></div>

        <stch-date-time-picker
          appearance="inline"
          [dateControl]="form.controls.toDate"
          [timeControl]="form.controls.toTime"
          [appClickStopPropagation]="true"
        />
      </div>

      @if (!withTags && !withKnots && !short) {
        <div class="stitch-list-item-body-item">
          {{ task.description || 'No description' }}
        </div>
      }

<!--      TODO Waiting the solution for avatar from designer team-->
<!--    <stch-avatar-->
<!--      *ngIf="task.assignee"-->
<!--      class="task-body-assignee"-->
<!--      [user]="task.assignee"-->
<!--    />-->

      @if (withTags) {
        <app-list-item-tags
          class="stitch-list-item-body-knowledge"
          [tags]="task.tags"
        />
      }

      @if (withKnots) {
        <app-list-item-knots
          class="stitch-list-item-body-knowledge"
          [knots]="task.knots"
        />
      }

      @if (withBar) {
        <stch-bottom-bar
          [stitchItem]="task"
          [showNested]="false"
          (expand)="barExpanded = $event"
        />
      }

      @if (task.pinned || task.flagged) {
        <div class="stitch-list-item-body-markers">
          @if (task.pinned) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-pin"
            />
          }
          @if (task.flagged) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-flagged"
            />
          }
        </div>
      }
    </div>

    @if (dragEnabled && actionsButtonEnabled) {
      <div class="stitch-list-item-hover-actions" [appClickStopPropagation]="true">
        <app-priority-input
          appearance="sapphire"
          [control]="form.controls.priority"
          (open)="contextMenuOpened = $event"

          matTooltip="Priority"
        />

        <stch-quick-action
          icon="sapphire-stitch"

          matTooltip="Stitch To"

          [appStitchToPopover]="task"
          (appStitchToPopoverOpened)="contextMenuOpened = $event"
        />

        <stch-quick-action
          icon="sapphire-email"
          (onClick)="createMail($event)"

          matTooltip="Send by email"
        />

        <stch-quick-action
          icon="sapphire-trash"
          [active]="task.deleted"
          (onClick)="delete()"

          [matTooltip]="task?.deleted ? 'Restore from Trash' : 'Trash'"
        />

        <stch-quick-action
          icon="sapphire-ellipsis"

          matTooltip="More actions"

          stchPopover
          stchPopoverAppearance="sapphire-context-menu"
          stchPopoverPlacement="bottomLeft"
          stchPopoverTrigger="click"
          [stchPopoverShowUntil]="popoverClose"
          [stchPopoverTemplate]="quickActionsContextMenuTemplate"
          (stchPopoverVisibleChange)="contextMenuOpened = $event"

          [appClickStopPropagation]="true"
        />
      </div>
    }

    <div class="drop-zone"></div>

    @if (debug) { <div class="debug">{{ task.score }}</div> }
  </div>
} @else {
  <stch-skeleton [withBar]="withBar" [short]="short" />
}

<ng-template #dragPlaceholder>
  <app-task [item]="task" [dragEnabled]="false" [withBar]="withBar" [short]="short"/>
</ng-template>

<!--Hover actions context menu-->
<ng-template #quickActionsContextMenuTemplate>
  <app-context-menu-item
    icon="sapphire-pin"
    activeIcon="sapphire-pin-filled"
    appearance="sapphire"
    [title]="task.pinned ? 'Unpin' : 'Pin'"
    [active]="task.pinned"
    (execute)="pin($event)"
  />

  <app-context-menu-item
    icon="sapphire-flagged"
    activeIcon="sapphire-flagged-filled"
    appearance="sapphire"
    [title]="task.flagged ? 'Remove Favorites' : 'Add to Favorite'"
    [active]="task.flagged"
    (execute)="flag($event)"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    icon="sapphire-snooze"
    appearance="sapphire"
    [title]="!!task.snoozed ? 'UnSnooze' : 'Snooze'"
    [active]="!!task.snoozed"
    (execute)="snooze()"
  />

  <app-context-menu-item
    icon="sapphire-follow-up"
    appearance="sapphire"
    [title]="!!task.followed ? 'UnFollow' : 'Follow Up'"
    [active]="!!task.followed"
    (execute)="followUp()"
  />
</ng-template>
