<div class="heading">
  <div class="title">Spaces</div>

  <mat-icon
    class="info-icon"
    svgIcon="sapphire-info2"
    matTooltip="Info"
  />

  <div class="filler"></div>

  <app-button
    appearance="sapphire-primary"
    size="m"
    label="Create Space"
    icon="sapphire-plus"
    (click)="openForm()"
  />
</div>

<div class="body">
  <div class="stats">
    <div class="stats-block">
      <div class="stats-block-title">Your Plan</div>
      <div class="stats-block-value">Standard</div>
    </div>
    <div class="stats-block">
      <div class="stats-block-title">Number Of Spaces</div>
      <div class="stats-block-value">{{ total }}</div>
    </div>
    <div class="stats-block">
      <div class="stats-block-title">Maximum Space Members</div>
      <div class="stats-block-value">{{ currentPlan?.maxSpaceMembers || 'N/A' }}</div>
    </div>
  </div>

  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [value]="selectedTab"
    (valueChange)="handleTabChange($event)"
    size="m"
  />

  <app-input
    class="search-input"
    appearance="sapphire-outline"
    [inputFormControl]="searchControl"
    placeholder="Search Spaces"
    size="m"
    leftIcon="sapphire-search"
  />

  <stch-table
    class="items"
    [items]="spaces"
    [columns]="columns"
    [page]="page"
    [perPage]="perPage"
    [count]="count"
    [sortBy]="sortBy"
    [sortOrder]="sortOrder"
    [placeholderTemplate]="placeholderTemplate"
    (pageChange)="handlePageChange($event)"
    (sortByChange)="handleSortByChange($event)"
    (sortOrderChange)="handleSortOrderChange($event)"
  />
</div>

<ng-template #nameTemplate let-value=value let-space=item>
  <div
    class="logo"
    [style.backgroundColor]="space.color | secondaryColor"
  >
    {{ value | initials : 3 }}
  </div>
  {{ value }}
</ng-template>

<ng-template #actionsTemplate let-value=value let-space=item>
  <app-button
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-ellipsis"
    matTooltip="More"

    stchPopover
    stchPopoverPlacement="bottomRight"
    stchPopoverAppearance="sapphire-context-menu"
    [stchPopoverFallbackPlacements]="['topRight']"
    [stchPopoverTemplate]="actionsDropdownTemplate"
    [stchPopoverContext]="{ space: space }"
    stchPopoverTrigger="click"
  />
</ng-template>

<ng-template #participantsTemplate let-participants=value let-space="item">
  <stch-members-avatars
    *ngIf="space.currentUserStatus !== 'pending'"
    [members]="participants"
  />

  <app-button
    *ngIf="space.currentUserStatus === 'pending'"
    appearance="sapphire-text-accent"
    size="s"
    label="Accept"
    (click)="accept(space, true)"
  />

  <app-button
    *ngIf="space.currentUserStatus === 'pending'"
    appearance="sapphire-text-accent"
    size="s"
    label="Decline"
    (click)="accept(space, false)"
  />
</ng-template>

<ng-template #actionsDropdownTemplate let-space="space">
  <app-context-menu-item
    title="Manage"
    appearance="sapphire"
    (execute)="handleEdit(space)"
  />

  <app-context-menu-item
    [title]="space?.archived ? 'Restore from Archive' : 'Archive Space'"
    appearance="sapphire"
    (execute)="handleArchive(space)"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    title="Delete Space"
    appearance="sapphire"
    [dangerous]="true"
    (execute)="removeSpacePrompt(space)"
  />
</ng-template>

<ng-template #placeholderTemplate>
  <div
    *ngIf="!spaces.length"
    class="placeholder"
  >
    <img
      class="placeholder-image"
      src="assets/placeholders/teams.png"
    />

    <div class="placeholder-text">
      You don`t have any Spaces right now, start by adding a Space
    </div>

    <app-button
      appearance="sapphire-text-accent"
      size="l"
      label="Invite Members"
      icon="sapphire-plus"
    />
  </div>
</ng-template>
