// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class TeamsFilters extends Filters {
  sortBy?: 'createdAt' | 'title';
  title?: string;
  archived?: boolean;
  spaceId?: string;

  constructor (filters?: Like<TeamsFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
    this.title = filters?.title;
    this.archived = filters?.archived;
    this.spaceId = filters?.spaceId;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'createdAt';

    if (!isNil(this.archived)) { result['archived'] = this.archived; }

    if (this.title?.trim()) result['title'] = this.title;
    if (this.spaceId !== undefined) result['spaceUid'] = this.spaceId;

    return result;
  }
}
