// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-calendar-quick-form',
  templateUrl: './calendar-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './calendar-quick-form.component.less'],
})
export class CalendarQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() calendar: Calendar = new Calendar();

  // Outputs
  @Output() more: EventEmitter<Calendar> = new EventEmitter<Calendar>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Calendar> = new EventEmitter();

  constructor (
    private calendarsService: CalendarsService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('calendar' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const calendar = Calendar.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(calendar);
      return;
    }

    this.saveInProgress = true;

    this.calendarsService.create(calendar)
      .pipe(takeUntil(this.alive))
      .subscribe(
        calendarInstance => {
          this.afterSave.emit(calendarInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.calendar) { return; }
    this.form = this.calendar.asFormGroup();
    super.reset();
  }

  clickMore() {
    this.more.emit(Calendar.fromFormGroup(this.form));
    super.clickMore();
  }
}
