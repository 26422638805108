// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-multiple-input',
  templateUrl: './dropdown-multiple-input.component.html',
  styleUrls: ['./dropdown-multiple-input.component.less'],
})
export class DropdownMultipleInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputFormControl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() options: DropdownSelectItem<unknown>[] = [];
  @Input() placement: PopoverPlacement = 'bottomFullWidth';
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() noBorder = false;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  /**
   * [size] Only for 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark'
   */
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() invertedColor = false;
  @Input() itemStyles: any = {};
  @Input() iconOnly = false;

  @Output() select = new EventEmitter<DropdownSelectItem<unknown>>();

  public popoverHide = new Subject();
  public selectedItems: DropdownSelectItem<unknown>[] = [];

  private alive = new Subject<void>();
  private inputControlChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl.valueChanges.pipe(startWith(this.inputFormControl.value))),
        filter(values => Array.isArray(values)),
        takeUntil(this.alive)
      )
      .subscribe(values => {
        this.selectedItems = values
          .map(value => this.options.find(option => {
            if (Array.isArray(option.value) && !Array.isArray(value)) {
              return null;
            }

            return Array.isArray(option.value) ? option.value.join() === value?.join() : option.value === value;
          }))
          .filter(item => !!item);
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(option: DropdownSelectItem<unknown>) {
    if (!this.inputFormControl) { return; }

    if (option.multiple) {
      this.inputFormControl.setValue(option.value);
    } else {
      const currentValue = this.inputFormControl.value || [];

      const newValue =  currentValue.some(item => item === option.value)
        ? currentValue.filter(item => item !== option.value)
        : [...currentValue, option.value ];

      this.inputFormControl.setValue(newValue);
    }

    this.select.emit(option);
  }

  removeItem(event: MouseEvent, item: DropdownSelectItem<unknown>) {
    event.stopPropagation();

    if (!this.inputFormControl) { return; }

    this.inputFormControl.setValue(this.inputFormControl.value.filter(value => value !== item.value));
  }
}
