// Common
import { Component, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { Space } from '@modules/settings/types/space';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';
import { Tab } from '@modules/common/types/tab';
import { FormControl } from '@angular/forms';
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { SpacesFilters } from '@modules/settings/types/spaces-filters';
import { Account } from '@modules/account/types/account';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { SpacesService } from '@modules/settings/services/spaces.service';
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { SpaceParticipantsService } from '@modules/settings/services/space-participants.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, merge } from 'rxjs';

// Components
import { SpaceFormComponent } from './space-form/space-form.component';

@Component({
  selector: 'app-settings-spaces-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.less']
})
export class SpacesSpacesComponent implements OnInit, OnDestroy {

  public spaces: Space[] = [];
  public currentPlan: SubscriptionPlan;
  public selectedTab = 'default';
  public tabs: Tab[] = [
    { title: 'Your Spaces', value: 'default'},
    { title: 'Archived Spaces', value: 'archived'},
  ];
  public searchControl = new FormControl<string>('');
  public columns: TableColumn[];
  public page = 0;
  public perPage = 10;
  public sortBy: SpacesFilters['sortBy'] = 'title';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public total = 0;

  private reload = new Subject<void>();
  private alive = new Subject<void>();
  private account: Account;

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<void>;
  @ViewChild('participantsTemplate', { static: true }) participantsTemplate: TemplateRef<void>;

  constructor (
    private alertsService: AlertService,
    private spacesService: SpacesService,
    private injector: Injector,
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
    private elementRef: ElementRef,
    private router: Router,
    private spaceParticipantsService: SpaceParticipantsService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columns = [
      { key: 'title', title: 'Space Name', sortable: true, template: this.nameTemplate },
      { key: 'owner.fullName', title: 'Created By' },
      { key: 'participants', title: 'Members', template: this.participantsTemplate },
      { key: null, title: 'Last Active', value: '15 min ago', legend: 'Not Implemented' },
      { key: 'createdAt', title: 'Created On', sortable: true, dateFormat: 'd MMM y' },
      { key: null, title: '', template: this.actionsTemplate, align: 'right' },
    ];

    combineLatest([
      this.accountService.getAccount(true),
      this.plansService.search()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.currentPlan = plans.find(plan => plan.id === account.subscriptionPlanId);
        this.account = account;
        this.reload.next();
      });

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.spacesService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage,
          title: this.searchControl.value,
          archived: this.selectedTab === 'archived',
        }, { account: this.account })),
        takeUntil(this.alive)
      )
      .subscribe(({ items: spaces, count, total }) => {
        this.spaces = spaces;
        this.count = count || 0;
        this.total = total;
      });

    merge(
      this.spacesService.getRefresh(),
      this.searchControl.valueChanges
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: SpacesFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  openForm(space = new Space()) {
    this.alertsService.show({
      appearance: 'sapphire-modal-custom',
      component: SpaceFormComponent,
      origin: this.elementRef.nativeElement,
      injector: this.injector,
    });
  }

  removeSpace(item: Space) {
    this.spacesService.delete(item);
  }

  removeSpacePrompt(item: Space) {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Space ${item.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeSpace(item),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }

  handleEdit(space: Space) {
    this.router.navigate([`/settings/spaces/${space.id}`]);
  }

  handleTabChange(tab: string) {
    this.selectedTab = tab;
    this.reload.next();
  }

  handleArchive(space: Space) {
    const message = space.archived ? `Space ${ space.title } restored from archive` : `Space ${ space.title } archived`;
    space.archived = !space.archived;

    this.spacesService.update(space, { emit: true, toast: true, message })
      .pipe(takeUntil(this.alive))
      .subscribe();
  }

  accept(space: Space, accepted: boolean) {
    this.spaceParticipantsService.accept(space, accepted)
      .pipe(takeUntil(this.alive))
      .subscribe(space => {
        this.reload.next();
      });
  }
}
