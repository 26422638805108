<div
  *ngIf="option.separator"
  class="separator"
>

</div>

<div
  *ngIf="!option.separator"
  [class.item]="!itemTemplate"
  [class.template-item]="itemTemplate"
  [class.active]="active"
  [class.disabled]="option.disabled"
  [ngClass]="appearance"
>
  <ng-container *ngIf="!itemTemplate">
    <mat-icon
      *ngIf="option.icon"
      class="item-icon"
      [style.color]="option.iconColor"
      [svgIcon]="option.icon"
    />

    <div class="item-title">{{ option.title }}</div>
  </ng-container>

  <ng-container *ngIf="itemTemplate">
    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: option.source, type: option.type }" />
  </ng-container>
</div>
