<div class="header">
  <div class="header-title">{{ team?.title }}</div>

  <div class="header-count">{{ team?.participants?.length }}</div>

  <div class="header-divider"></div>

  <app-button
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-close"
    (click)="hide()"
  />
</div>

<app-input
  appearance="sapphire-outline"
  [inputFormControl]="searchControl"
  placeholder="Search Members"
  size="m"
  leftIcon="sapphire-search"
/>

<div class="list">
  @for(member of members; track member.id) {
    <div class="list-item">
      <div class="list-item-name">
        <stch-avatar [user]="member" [size]="24" />
        <div class="list-item-name-text">{{ member.fullName }}{{ member.owner ? ' (owner)' : '' }}</div>
      </div>
      <div class="list-item-address">{{ member.userName }}{{ mailUrl }}</div>
    </div>
  } @empty {
    No members found.
  }
</div>
