// Common
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

// Types
import { SpaceParticipant } from './space-participant';
import { Account } from '@modules/account/types/account';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Space {
  formBuilder = injector.get(UntypedFormBuilder);

  color: string;
  createdAt?: Date;
  description?: string;
  archived?: boolean;
  id?: string;
  owner: SpaceParticipant;
  participants: SpaceParticipant[] = [];
  title: string;
  currentUserStatus: 'pending' | 'accepted' | 'rejected';

  constructor(data: any = {}, currentUser?: Account) {
    this.color = data.color;
    this.createdAt = data.createdAt && new Date(data.createdAt);
    this.description = data.description;
    this.archived = data.archived;
    this.id = data.id;
    this.title = data.title;

    if (data instanceof Space) {
      this.participants = data.participants || [];
    } else {
      this.participants = (data.participants || []).map(i => new SpaceParticipant(i))
    }

    this.owner = this.participants.find(({ owner }) => owner);

    this.currentUserStatus = this.participants.find(({ id }) => id === currentUser?.id)?.status;
  }

  static fromFormGroup(form: UntypedFormGroup): Space {
    const formValues = form.value;

    return new Space({
      color: formValues.color,
      description: formValues.description,
      archived: formValues.archived,
      id: formValues.id,
      title: formValues.title,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      color: [this.color],
      description: [this.description],
      archived: [this.archived],
      id: [this.id],
      title: [this.title, Validators.required],
    });
  }

  asPayloadJSON() {
    return {
      color: this.color,
      description: this.description,
      archived: this.archived,
      id: this.id,
      title: this.title,
    };
  }
}
