// Common
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Utils
import { getFirstError } from '@modules/common/utils/form';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { EventsService } from '@modules/calendar-app/services/events.service';

@Component({
  selector: 'stch-event-popover-form',
  templateUrl: './event-popover-form.component.html',
  styleUrls: ['./event-popover-form.component.less'],
})
export class EventPopoverFormComponent implements OnInit, OnChanges, OnDestroy {

  public calendarSuggestions: AutocompleteFactory<Calendar>;
  public form = (new CalendarEvent).asFormGroup();
  public submitted = false;
  public saveInProgress = false;

  private stitchItemChanged = new BehaviorSubject<void>(null);
  private alive = new Subject<void>();

  @Input() event = new CalendarEvent();

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    private calendarsService: CalendarsService,
    private stateService: CalendarAppStateService,
    private toasterService: ToasterService,
    private eventsService: EventsService,
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.calendarSuggestions = this.calendarsService.getAutocompleteSuggestions();

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.form = this.asFormGroup();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('event' in changes) {
      this.stitchItemChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      this.toasterService.show({ text: getFirstError(this.form), icon: 'sapphire-close-small' });
      return;
    }

    if (this.form.pristine) { return; }

    this.form.markAsPristine();

    this.saveInProgress = true;

    const stitchItem = this.fromFormGroup();

    (
      this.form.controls.id.value
        ? this.eventsService.update(stitchItem, { emit: true })
        : this.eventsService.create(stitchItem, { emit: true })
    )
      .pipe(takeUntil(this.alive))
      .subscribe(({ id }) => {
        this.saveInProgress = false;
        this.handleClose();
      },
        () => this.handleError());
  }

  handleMore() {
    this.stateService.setMainView(CalendarEvent.fromFormGroup(this.form));
    this.handleClose();
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleClose() {
    this.close.emit();
    this.closePopoverToken?.next();
  }

  /**
   * Helpers
   */

  protected shouldRefreshList(prev, current) {
    return CalendarEvent.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): CalendarEvent {
    return CalendarEvent.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return (this.event || new CalendarEvent()).asFormGroup();
  }
}
