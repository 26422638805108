// Common
import { Component, Injector } from '@angular/core';

// Utils
import { addHours } from '@modules/common/utils/date';
import { Like } from '@modules/common/types/like';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Pipes
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';

// Types
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';

@Component({
  selector: 'app-sidebar-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: ['../../../common/components/base-sidebar-context-menu/base-sidebar-context-menu.component.less', './sidebar-context-menu.component.less']
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<Task, Project, VirtualFolder> {

  advancedSearchStateKey = Application.tasking;

  private dateByDayIndexPipe = new DateByDayIndexPipe();

  constructor (
    injector: Injector,
    tasksService: TasksService,
    tasksStateService: TaskingAppStateService,
    projectsService: ProjectsService,
  ) {
    super(injector, tasksService, projectsService, tasksStateService);
  }

  protected getCommonParams(): Like<Task | Project> {
    let params: Like<Task | Project> = {};
    let date = new Date();

    switch (this.sidebarFilterKey) {
      case 'all_tasks':
      case 'all_projects':
      case 'unscheduled':
        break;
      case 'scheduled':
      case 'overdue':
        date.setHours(9, 0, 0, 0);

        params.toTime = date;
        params.toDate = date;
        break;
      case 'today':
        date = addHours(date, 1);
        params.fromTime = date;
        params.fromDate = date;
        break;
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
      case 'week':
        date = this.dateByDayIndexPipe.transform(this.sidebarFilterKey);
        date.setHours(9, 0, 0 , 0);

        params.fromTime = date;
        params.fromDate = date;
        break;
      case 'priority':
        params.priority = 'high';
        break;
      case 'low':
      case 'normal':
      case 'high':
        params.priority = this.sidebarFilterKey;
        break;
      case 'snoozed':
      case 'followed':
      case 'flagged':
      case 'deleted':
      case 'archived':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Like<Task>): Task {
    return new Task({ ...params, projectId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Like<Project>): Project {
    return new Project({ ...params, parentId: this.selectedContainerStitchItemId });
  }
}
