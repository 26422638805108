// Common
import { Directive, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SPACE_ID } from '../injection-tokens/space-id.injection-token';
import { STCH_HTTP_INTERCEPTORS } from '@modules/common/injection-tokens/http-interceptor.injection-token';
import { HttpClient } from '@angular/common/http';

// RX
import { BehaviorSubject, Subject } from 'rxjs';

// Interceptors
import { SpacesHttpInterceptor } from '@modules/common/interceptors/spaces.interceptor';
import { AuthHttpInterceptor } from '../interceptors/auth.interceptor';

// Services
import { SpaceStateService } from '../services/space-state.service';
import { SpacesService } from '@modules/settings/services/spaces.service';
import { TeamsService } from '@modules/settings/services/teams.service';
import { SpaceParticipantsService } from '@modules/settings/services/space-participants.service';
import { TeamParticipantsService } from '@modules/settings/services/team-participants.service';
import { SpaceRolesService } from '@modules/settings/services/space-roles.service';
import { TeamRolesService } from '@modules/settings/services/team-roles.service';
import { StchHttpService } from '@modules/common/services/http.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { AutomationRulesService } from '@modules/settings/services/automation-rules.service';
import { OnlineStatusService } from '@modules/account/services/online-status.service';
import { StitchService } from '../services/stitch.service';
import { StitchServiceFactory } from '../factories/stitch-service.factory';
import { NotificationsService } from '@modules/notification/services/notifications.service';
import { NotificationsSettingsService } from '@modules/notification/services/settings.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { CommentsService } from '@modules/comments/services/comments.service';
import { SpaceSettingsService } from '@modules/account/services/space-settings.service';
// Knowledge
import { TagsService } from '@modules/tags/services/tags.service';
import { ConnectionsService } from '@modules/connections/services/connections.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { KnowledgeService } from '@modules/knowledge/services/knowledge.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
// Mail App
import { MessageFoldersService} from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { PermissionParticipantsService } from '@modules/messages/services/permission-participants.service';
import { MailAppSettingsService } from '@modules/messages/services/settings.service';
import { ImportsService } from '@modules/messages/services/imports.service';
// Calendar App
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { SectionsService as CalendarSectionsService } from '@modules/calendar-app/services/sections.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { CalendarAppSettingsService } from '@modules/calendar-app/services/settings.service';
// Tasking App
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { SectionsService as TaskingSectionsService } from '@modules/tasks/services/sections.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { RowsService } from '@modules/tasks/services/rows.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';
// Notes App
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { SectionsService as NotesSectionsService } from '@modules/notes/services/sections.service';
import { NotesAppSettingsService } from '@modules/notes/services/settings.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
// Contacts App
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { SectionsService as ContactsSectionsService } from '@modules/contacts/services/sections.service';
import { ContactsAppSettingsService } from '@modules/contacts/services/settings.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
// Files App
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';
import { SectionsService as FilesSectionsService } from '@modules/files/services/sections.service';
import { UploadsService } from '@modules/common/services/uploads.service';
import { FilesAppSettingsService } from '@modules/files/services/settings.service';
import { FilesAppStateService } from '@modules/files/services/state.service';

@Directive({
  selector: '[spaceContext]'
})
export class SpaceContextDirective implements OnInit, OnDestroy {
  @Input() spaceContext: string;

  private stateService: SpaceStateService;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private parentInjector: Injector
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.viewContainer.clear();

    const injector = Injector.create({
      providers: [
        { provide: SPACE_ID, useValue: new BehaviorSubject<string>(undefined) },
        { provide: STCH_HTTP_INTERCEPTORS, useClass: SpacesHttpInterceptor, multi: true, deps: [SPACE_ID] },
        { provide: STCH_HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true, deps: [] },
        { provide: HttpClient, useClass: StchHttpService },
        OnlineStatusService,
        { provide: StitchService, useClass: StitchService },
        SpacesService,
        TeamsService,
        SpaceParticipantsService,
        TeamParticipantsService,
        SpaceRolesService,
        TeamRolesService,
        {
          provide: SpaceStateService,
          useFactory: (spacesService: SpacesService, spaceIdToken: Subject<string>) => {
            this.stateService = new SpaceStateService(spacesService, spaceIdToken);

            this.stateService.init(this.spaceContext);

            return this.stateService;
          },
          deps: [SpacesService, SPACE_ID]
        },
        {
          provide: AdvancedSearchService,
          useFactory: (httpClient: HttpClient, toasterService: ToasterService) => {
            const instance = new AdvancedSearchService(httpClient, toasterService);
            instance.init(this.spaceContext);
            return instance;
          },
          deps: [HttpClient, ToasterService]
        },
        { provide: CommentsService, useClass: CommentsService },
        { provide: AutomationRulesService, useClass: AutomationRulesService },
        SpaceSettingsService,
        // Knowledge
        { provide: ConnectionsService, useClass: ConnectionsService },
        { provide: KnotsService, useClass: KnotsService },
        { provide: KnowledgeService, useClass: KnowledgeService },
        { provide: LinkedInfoService, useClass: LinkedInfoService },
        { provide: TagsService, useClass: TagsService },
        // Mail App
        { provide: MessagesService, useClass: MessagesService },
        { provide: MessageFoldersService, useClass: MessageFoldersService },
        MailAppStateService,
        { provide: PermissionParticipantsService, useClass: PermissionParticipantsService },
        MailAppSettingsService,
        { provide: ImportsService, useClass: ImportsService },
        // Calendar App
        { provide: EventsService, useClass: EventsService },
        { provide: CalendarsService, useClass: CalendarsService },
        { provide: CalendarSectionsService, useClass: CalendarSectionsService },
        CalendarAppStateService,
        CalendarAppSettingsService,
        // Tasking App
        { provide: TasksService, useClass: TasksService },
        { provide: ProjectsService, useClass: ProjectsService },
        { provide: TaskingSectionsService, useClass: TaskingSectionsService },
        TaskingAppStateService,
        { provide: ColumnsService, useClass: ColumnsService },
        { provide: RowsService, useClass: RowsService },
        TaskingAppSettingsService,
        // Notes App
        { provide: NotesService, useClass: NotesService },
        { provide: NotebooksService, useClass: NotebooksService },
        { provide: NotesSectionsService, useClass: NotesSectionsService },
        NotesAppSettingsService,
        NotesAppStateService,
        // Contacts App
        { provide: ContactsService, useClass: ContactsService },
        { provide: GroupsService, useClass: GroupsService },
        { provide: ContactsSectionsService, useClass: ContactsSectionsService },
        ContactsAppSettingsService,
        ContactsAppStateService,
        // Files App
        { provide: FilesService, useClass: FilesService },
        { provide: FoldersService, useClass: FoldersService },
        { provide: FilesSectionsService, useClass: FilesSectionsService },
        FilesAppSettingsService,
        FilesAppStateService,
        // Higher Level Services
        { provide: StitchServiceFactory, useClass: StitchServiceFactory },
        NotificationsService,
        NotificationsSettingsService,
        { provide: UploadsService, useClass: UploadsService }
      ],
      parent: this.parentInjector
    });

    this.viewContainer.createEmbeddedView(this.templateRef, {}, { injector });
  }

  ngOnDestroy(): void {
    this.stateService.detach();
  }
}
