<div
  class="heading"
  [class.empty]="!template"
  [class.first]="first"
>
  <div class="heading-info">
    <div class="heading-info-title">
      @if (template && !editMode) {
        {{ template.title }}
      } @else if (!template) {
        Quick Email Answers
      }
    </div>

    <div class="heading-info-text">
      @if (template && !editMode) {
        {{ template.content | plainText }}
      } @else if (!template) {
        Quickly reply to emails with pre-set templates, saving you time and keeping your inbox organized.
      }
    </div>

    @if (editMode) {
      <div class="heading-form">
        <app-input
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.title"
          placeholder="Title"
          withClear="true"
          [validationMessages]="{ required: 'Please enter a value' }"
          [withErrors]="submitted"
          size="m"
        />

        <stch-editor-toolbar />

        <stch-simple-editor
          [control]="form.controls.content"
        />

        <div class="heading-form-buttons">
          <app-button
            appearance="sapphire-primary"
            size="s"
            label="Save"
            (click)="handleSubmit()"
          />

          <app-button
            appearance="sapphire-secondary"
            size="s"
            label="Cancel"
            (click)="toggleEditMode()"
          />
        </div>
      </div>
    }
  </div>

  <div class="heading-actions">
    @if (template) {
      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-ellipsis"
        matTooltip="More Options"

        stchPopover
        stchPopoverPlacement="bottomRight"
        stchPopoverAppearance="sapphire-context-menu"
        [stchPopoverFallbackPlacements]="['topRight']"
        [stchPopoverTemplate]="actionsDropdownTemplate"
        stchPopoverTrigger="click"
      />
    } @else {
      <app-button
        appearance="sapphire-secondary"
        size="s"
        label="Add Quick Answer"
        [disabled]="editMode"
        (click)="toggleEditMode()"
      />
    }
  </div>
</div>

<ng-template #actionsDropdownTemplate let-close="close">
  <app-context-menu-item
    title="Edit"
    appearance="sapphire"
    (execute)="toggleEditMode(); close.next();"
  />

  <app-context-menu-item
    title="Delete Quick Answer"
    appearance="sapphire"
    [dangerous]="true"
    (execute)="handleRemove(); close.next();"
  />
</ng-template>
