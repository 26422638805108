// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from 'src/app/decorators/warm-up-observable';
import { environment } from '@environment';

// RX
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

// Types
import { Account } from '../types/account';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable()
export class AccountService {

  private account = new BehaviorSubject<Account>(null);
  private accountLoading = false;

  constructor (
    private http: HttpClient,
    private toaster: ToasterService,
    private authService: AuthService,
  ) { }

  private handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text: error?.error?.message || error?.error?.error || error?.message });
    return throwError(error);
  }

  reloadAccount() {
    this.accountLoading = true;

    this.http.get(environment.baseUrl + '/api/account').subscribe(
      response => {
        this.accountLoading = false;
        this.account.next(new Account(response));
      },
      error => {
        console.error(error);
        this.accountLoading = false;
        this.account.next(new Account({}));
        this.authService.authenticate(null);
      }
    );
  }

  getAccount(force: boolean = false): Observable<Account> {
    if (force || (!this.account.value?.id && !this.accountLoading)) {
      this.reloadAccount();
    }

    return this.account.pipe(filter(account => !!account));
  }

  cleanUp() {
    this.account.next(null);
  }

  update(account: Partial<Account>) {
    return this.http.put(
      environment.baseUrl + '/api/account',
      {
        firstName: account.firstName,
        lastName: account.lastName,
        recoveryEmail: account.recoveryEmail,
        tempAvatarId: account.tempAvatarId,
      }
    )
      .pipe(
        map(response => {
          this.account.next(new Account(response));
        })
      );
  }

  changePassword(password: string, newPassword: string) {
    return this.http.put(
      environment.baseUrl + '/api/account/change-password',
      { password, newPassword }
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }

  initRestorePassword(email) {
    return this.http.post(
      environment.baseUrl + '/api/account/restore-password',
      { email }
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }

  restorePassword({ token, email, newPassword }) {
    return this.http.put(
      environment.baseUrl + '/api/account/restore-password',
      { email, newPassword, token }
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  removeAvatar() {
    return this.http.delete<boolean>(environment.baseUrl + '/api/account/avatar');
  }

  cancel(): Observable<object> {
    return this.http.delete(environment.baseUrl + '/api/account')
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }
}
