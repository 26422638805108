<div
  stchPopover
  [stchPopoverArrow]="true"
  [stchPopoverContent]="popoverTemplate"
  [stchPopoverInnerShadow]="true"
  stchPopoverPlacement="right"
  [stchPopoverDisabled]="!minimized"
  stchPopoverTrigger="click"
>
  <app-sidebar-item
    title="Knots"
    icon="sapphire-knots"
    [depth]="team ? 2 : 0"
    [expandable]="true"
    [expanded]="expanded | lsValue : sidebarSplitViewKey : team?.id"
    [minimized]="minimized"
    [actions]="[{
      icon: 'sapphire-plus',
      template: addKnotsTemplate,
      until: quickFormPopoverClose
    }]"
    (onExpand)="expanded.set($event, sidebarSplitViewKey, team?.id)"

    appSidebarTagsKnotsContextMenu="knots"
  >
    <app-context-menu-state-indicator
      appearance="sapphire"
      svgIcon="sapphire-filters"
      [appKnotsManageContextMenu]="true"
      [appKnotsManageContextMenuStateKey]="stateKey"
      (appKnotsManageContextMenuStateChange)="setState($event)"
      [appKnotsManageContextMenuWithTemporalExpressions]="false"
    />
  </app-sidebar-item>
</div>

<div
  *ngIf="!minimized"
  class="collapseable-body"
  [@heightAnimation]="(expanded | lsValue : sidebarSplitViewKey : team?.id) ? 'show' : 'hide'"
>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

<ng-template #popoverTemplate>
  <div
    *ngIf="minimized"
    class="popover-body"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <div
    *ngIf="!count"
    class="placeholder"
    [class.team]="team"
  >
    No pinned Knots found
  </div>

  <cdk-virtual-scroll-viewport
    itemSize="24"
    class="scroll-container"
    [class.team]="team"
  >
    <div *cdkVirtualFor="let knot of dataSource" class="scroll-container-item">
      <mat-icon
        class="scroll-container-item-unread"
        [class.inactive]="knot?.unreadCount === 0"
        svgIcon="other"
      ></mat-icon>

      <app-knot
        *ngIf="knot"
        class="knot"
        [knot]="knot"
        (click)="handleClick(knot)"
      ></app-knot>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #addKnotsTemplate>
  <app-add-knots
    (close)="closePopovers()"
  ></app-add-knots>
</ng-template>
