<div class="heading">
  Create New Role

  <div class="filler"></div>

  <app-button
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-close"
    (click)="handleCancel()"
  />
</div>

<div class="form">
  <div class="form-row">
    <div class="form-row-title">Role Name</div>
    <app-input
      class="form-row-input"
      appearance="sapphire-outline"
      size="s"
      placeholder="New Role"
      [inputFormControl]="form.controls.title"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />
  </div>
</div>

<div class="footer">
  <app-button
    label="Create Role"
    appearance="sapphire-primary"
    size="s"
    [disabled]="!form.valid"
    (click)="handleSubmit()"
  />

  <app-button
    label="Cancel"
    appearance="sapphire-secondary"
    size="s"
    (click)="handleCancel()"
  />
</div>
