<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled File"
      [inputFormControl]="form.controls.title"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <app-tabs
    [values]="tabs"
    [(value)]="selectedTab"
    size="m"
    appearance="sapphire"
  />

  @switch (selectedTab) {
    @case ('file') {
      <app-files-input
        [titleFormControl]="form.controls.title"
        [inputFormControl]="form.controls.attachment"
        [withErrors]="submitted"
        [validationMessages]="{
          required: 'Please select a file to upload',
          size: 'File is too large. Maximum size 25MB.',
          uploading: 'Can\'t upload file'
        }"
      />
      <span class="form-description">The maximum size per file is 25 MB</span>
    }
    @case ('link') {
      <app-input
        appearance="sapphire-inline"
        size="xs"
        placeholder="Paste a file link"
        [inputFormControl]="form.controls.title"
      />
      <span class="form-description">Works with links of PDFs, Google Drive, ect...</span>
    }
  }

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>
