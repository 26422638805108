<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{ team: undefined }"
/>

<app-sidebar-items-divider *ngIf="teams.length" />

<app-sidebar-item
  *ngIf="teams.length"
  title="Teams"
  icon="sapphire-groups"
  [depth]="0"
  [expandable]="true"
  [expanded]="expanded | lsValue"
  [minimized]="minimized"
  (onExpand)="expanded.set($event)"

  stchPopover
  [stchPopoverContent]="popoverTemplate"
  stchPopoverPlacement="right"
  [stchPopoverDisabled]="!minimized"
  stchPopoverTrigger="click"
/>

<div
  *ngIf="!minimized && teams.length"
  class="collapseable-body"
  [@heightAnimation]="(expanded | lsValue) ? 'show' : 'hide'"
>
  <ng-container *ngTemplateOutlet="contentTemplate" />
</div>

<ng-template #popoverTemplate>
  <div
    *ngIf="minimized"
    class="popover-body"
  >
    <ng-container *ngTemplateOutlet="contentTemplate" />
  </div>
</ng-template>

<ng-template #contentTemplate>
  <ng-container
    *ngFor="let team of teams"
  >
    <app-sidebar-item
      [title]="team.title"
      icon="sapphire-groups"
      [depth]="1"
      [expandable]="true"
      [expanded]="expanded | lsValue : team.id"
      [minimized]="minimized"
      (onExpand)="expanded.set($event, team.id)"
    >
      <stch-members-avatars
        [members]="team.participants"
        stchPopover
        [stchPopoverTemplate]="membersTemplate"
        [stchPopoverContext]="{ team: team }"
        stchPopoverPlacement="right"
        stchPopoverTrigger="click"
      />
    </app-sidebar-item>

    <div
      class="collapseable-body"
      [@heightAnimation]="(expanded | lsValue : team.id) ? 'show' : 'hide'"
    >
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ team: team }"
      />
    </div>
  </ng-container>
</ng-template>

<ng-template #membersTemplate let-team="team" let-close="close">
  <stch-members-popover
    [team]="team"
    (onClose)="close?.next()"
  />
</ng-template>
