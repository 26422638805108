<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled Contact"
      [inputFormControl]="form.controls.title"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <div class="form-input-container">
    <app-input
      class="form-input-container-input"
      appearance="sapphire-inline"
      size="xs"
      placeholder="Enter Email"
      [inputFormControl]="form.controls.emails['controls'][0].controls.value"
    />
    <app-dropdown-input
      appearance="sapphire-inline"
      size="s"
      placement="bottomRight"
      [inputFormControl]="form.controls.emails['controls'][0].controls.category"
      [options]="contactInfoTypes"
    />
  </div>

  <div class="form-input-container">
    <app-input
      class="form-input-container-input"
      appearance="sapphire-inline"
      size="xs"
      placeholder="Phone Number"
      [inputFormControl]="form.controls.phones['controls'][0].controls.value"
    />
    <app-dropdown-input
      appearance="sapphire-inline"
      size="s"
      placement="bottomRight"
      [inputFormControl]="form.controls.phones['controls'][0].controls.category"
      [options]="contactInfoTypes"
    />
  </div>

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>
