// Common
import { Component, Injector } from '@angular/core';

// Services
import { NotesService } from '@modules/notes/services/notes.service';

// Types
import { Note } from '@modules/notes/types/note';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './note.component.less']
})
export class NoteComponent extends BaseStitchComponent<Note> {
  moveDragDataTypes = [];
  dragDataType = DragDataTypes.note;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.note &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor(
    injector: Injector,
    notesService: NotesService,
  ) { super(injector, notesService); }

  handleMove(dragData: DragData): void {}

  get note(): Note {
    return this.item;
  }
}
