<div class="container">
  <app-settings-messages-auto-reply/>

  @for(template of autoReplyTemplates; track template.id; let first = $first) {
    <app-settings-messages-auto-reply
      [template]="template"
      [first]="first"
    />
  }
</div>
