// Common
import {
  Component, ViewContainerRef, Type, ViewChild, Output, EventEmitter, ElementRef, Renderer2,
  NgZone, OnDestroy, ComponentRef, Input, AfterViewInit, OnInit, Injector, OnChanges, SimpleChanges,
} from '@angular/core';
import { LSNumber, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { DragDrop, DragRef, Point } from '@angular/cdk/drag-drop';

// Components
import { BaseModalComponent } from './../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Icon } from '@modules/icons/types/icons';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

const HEADER_HEIGHT = 56;

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.less']
})
export class ModalWrapperComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() appearance: 'amethyst' | 'default' = 'amethyst';
  @Input() frame: ModalFrame;
  @Input() icon: Icon;
  @Input() childComponent: Type<any>;

  @Output() onClose = new EventEmitter();
  @Output() collapsed = new EventEmitter<boolean>();

  public childComponentData: Object;
  public overlaps: boolean;
  public resize: boolean;
  public minimized: boolean;
  public collapsible: boolean;
  public expandable: boolean;
  public headerBounding = { x: 0, y: 0, width: 0, height: 0 };
  public dragOffset = { x: 0, y: 0 };

  @ViewChild('modalAnchor', { read: ViewContainerRef, static: true }) private modalAnchor: ViewContainerRef;

  private componentRef: ComponentRef<BaseModalComponent>;
  private sizeChanges = new Subject<void>();
  private alive = new Subject<void>();
  private dragElement: DragRef;
  private minSize = { width: 400, height: 300 };
  private closeSubject = new Subject<void>();
  private variant: string;
  @LSNumber({ lsKey: 'modal', min: 0 }) private bounding: LocalStorageItem<number>;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private dragDrop: DragDrop,
    private elementRef: ElementRef,
    private injector: Injector,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.variant = this.childComponent.name
      .replace('Component', '')
      .replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

    const injector = Injector.create({
      providers: [{ provide: CloseToken, useFactory: () => this.closeSubject }],
      parent: this.injector
    });

    this.componentRef = this.modalAnchor.createComponent(this.childComponent, { injector });

    // Inject data and handlers
    if (this.childComponentData) {
      Object.keys(this.childComponentData).forEach(key => {
        this.componentRef.instance[key] = this.childComponentData[key];
      });
    }

    this.componentRef.instance.collapsed
      ?.pipe(takeUntil(this.alive))
      ?.subscribe((minimized) => {
        if (this.dragElement) {
          this.dragElement.disabled = minimized;
        }
        this.collapsed.emit(minimized);
      });

    this.componentRef.instance.closed
      ?.pipe(takeUntil(this.alive))
      ?.subscribe(() => this.onClose.emit());

    this.setSizeAndPosition(this.frame);

    if (this.overlaps) {
      this.overlapsPosition();
    }

    this.closeSubject
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.close();
      });
  }

  ngAfterViewInit() {
    const dragHandler = this.elementRef.nativeElement.querySelector('.modal-heading');

    if (dragHandler) {
      this.dragElement = this.dragDrop.createDrag(this.elementRef.nativeElement);
      // this.dragElement.withBoundaryElement(document.body);
      this.dragElement.withHandles([dragHandler]);
      this.dragElement.constrainPosition = (userPointerPosition: Point, dragRef: DragRef) => ({
        x: Math.min(
          Math.max(
            userPointerPosition.x - this.dragOffset.x,
            40 - this.headerBounding.width
          ),
          window.innerWidth - 40
        ),
        y: Math.min(
          Math.max(
            userPointerPosition.y - this.dragOffset.y,
            0
          ),
          window.innerHeight - HEADER_HEIGHT
        )
      });

      this.dragElement.ended
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          this.saveSizeAndPosition();
        });

      this.dragElement.started
        .pipe(takeUntil(this.alive))
        .subscribe(({ event }) => {
          if (event instanceof MouseEvent) {
            this.headerBounding = this.elementRef.nativeElement.getBoundingClientRect();
            this.dragOffset = { x: event.pageX - this.headerBounding.x, y: event.pageY - this.headerBounding.y };
          }
        });
    }
  }

  ngOnDestroy() {
    this.sizeChanges.next();
    this.sizeChanges.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Public Methods
   */

  doCollapse() {
    this.minimized = !this.minimized;
    this.dragElement.disabled = this.minimized;

    this.collapsed.emit(this.minimized);
  }

  public collapse(minimized: boolean, index: number = 0) {
    this.minimized = minimized;

    if (minimized) {
      const minimizedFrame: ModalFrame = {
        x: 'calc(100% - 370px)',
        y: `calc(100% - ${(index + 1) * 32 + index * 1}px)`,
        width: '320px',
        height: '32px'
      };
      this.setSizeAndPosition(minimizedFrame);
    } else {
      this.setSizeAndPosition(this.frame);
    }
  }

  /**
   * Resize
   */

  handleResize(event: MouseEvent) {
    this.ngZone.runOutsideAngular(() => {
      this.sizeChanges.next();

      let lastCoordinates = {
        x: event.x,
        y: event.y
      };

      fromEvent(window, 'mousemove')
        .pipe(takeUntil(this.sizeChanges))
        .subscribe((moveEvent: MouseEvent) => {
          const delta = {
            x: moveEvent.x - lastCoordinates.x,
            y: moveEvent.y - lastCoordinates.y,
          };

          const currentSize = {
            width: this.hostElement.nativeElement.clientWidth,
            height: this.hostElement.nativeElement.clientHeight
          };

          const newSize = {
            width: currentSize.width + delta.x > this.minSize.width ? currentSize.width + delta.x : this.minSize.width,
            height: currentSize.height + delta.y > this.minSize.height ? currentSize.height + delta.y : this.minSize.height
          };

          if (currentSize.width !== newSize.width) {
            this.renderer.setStyle(this.hostElement.nativeElement, 'width', `${newSize.width}px`);
          }

          if (currentSize.height !== newSize.height) {
            this.renderer.setStyle(this.hostElement.nativeElement, 'height', `${newSize.height}px`);
          }

          // Ovverride previous coordinates to actual amount of pixels windiw size changed
          lastCoordinates.x = lastCoordinates.x + (this.hostElement.nativeElement.clientWidth - currentSize.width);
          lastCoordinates.y = lastCoordinates.y + (this.hostElement.nativeElement.clientHeight - currentSize.height);
        });

      fromEvent(window, 'mouseup')
        .pipe(takeUntil(this.sizeChanges))
        .subscribe((mouseEvent: MouseEvent) => {
          this.saveSizeAndPosition();
          lastCoordinates = null;
          this.sizeChanges.next();
        });
    });
  }

  overlapsPosition() {
    const modalShiftSize = 5;
    const rect = this.hostElement.nativeElement.getBoundingClientRect();
    this.bounding.set(rect.x + modalShiftSize, `${this.variant}.x`);
    this.bounding.set(rect.y + modalShiftSize, `${this.variant}.y`);
    this.setSizeAndPosition();
  }

  saveSizeAndPosition() {
    const rect = this.hostElement.nativeElement.getBoundingClientRect();
    this.bounding.set(rect.x, `${this.variant}.x`);
    this.bounding.set(rect.y, `${this.variant}.y`);
    if (this.resize) {
      this.bounding.set(rect.width, `${this.variant}.width`);
      this.bounding.set(rect.height, `${this.variant}.height`);
    }
  }

  setSizeAndPosition(frame?: ModalFrame) {
    const element = this.hostElement.nativeElement;

    // Local store
    const x = this.bounding.getSync(`${this.variant}.x`);
    const y = this.bounding.getSync(`${this.variant}.y`);
    if (x) { frame.x = `${x}px`; }
    if (y) { frame.y = `${y}px`; }

    if (this.resize) {
      const width = this.bounding.getSync(`${this.variant}.width`);
      const height = this.bounding.getSync(`${this.variant}.height`);
      if (width) { frame.width = `${width}px`; }
      if (height) { frame.height = `${height}px`; }
    }

    // Set styles
    if (frame.x) { this.renderer.setStyle(element, 'left', frame.x); }
    if (frame.y) { this.renderer.setStyle(element, 'top', frame.y); }
    if (frame.width) { this.renderer.setStyle(element, 'width', frame.width); }
    if (frame.height) { this.renderer.setStyle(element, 'height', frame.height); }
    if (this.minimized) { this.renderer.removeStyle(element, 'transform'); }
    this.renderer.setStyle(element, 'z-index', (this.minimized ? 210 : 200));

    // Set last size to child modal
    if (!this.minimized) {
      const rect = this.hostElement.nativeElement.getBoundingClientRect();
      this.componentRef.instance.maximizedSize = new ModalFrame(rect.width, rect.height, rect.x, rect.y);
    }
  }

  close() {
    this.onClose.emit();
  }

  expand() {
    // this.popupService.compose(this.message, this.maximizedSize.popupSize());
    this.close();
  }
}
