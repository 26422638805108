<app-context-menu-item
  *ngIf="!(['all_projects', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New Task"
>
  <app-task-quick-form
    class="quick-form"
    [task]="stitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  ></app-task-quick-form>
</app-context-menu-item>

<app-context-menu-item
  *ngIf="!(['all_tasks', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New Project"
>
  <app-project-quick-form
    class="quick-form"
    [project]="containerStitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  ></app-project-quick-form>
</app-context-menu-item>

<app-context-menu-divider
  *ngIf="!(['all_tasks', 'deleted', 'archived', 'all_projects'] | includesBy: sidebarFilterKey)"
></app-context-menu-divider>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'flagged'"
  title="Unflag all"
  [close]="true"
  (execute)="unFlagAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'followed'"
  title="Unfollow all"
  [close]="true"
  (execute)="unFollowAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'snoozed'"
  title="Unsnooze all"
  [close]="true"
  (execute)="unSnoozeAll()"
></app-context-menu-item>

<app-context-menu-divider
  *ngIf="(['flagged', 'followed', 'snoozed'] | includesBy: sidebarFilterKey)"
></app-context-menu-divider>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'archived'"
  title="Delete all"
  [close]="true"
  (execute)="deleteAll()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'deleted'"
  title="Empty trash"
  [close]="true"
  (execute)="emptyTrash()"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Settings"
  [close]="true"
  (execute)="openSettings()"
></app-context-menu-item>
