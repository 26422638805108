// Common
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'template'
})
export class TemplatePipe implements PipeTransform {
  transform(template: string, ...values: any[]): string {
    if (!template) { return null; }

    return template.replace(/\$(\d+)/g, (match, index) => {
      const valueIndex = parseInt(index, 10) - 1;
      return values[valueIndex] !== undefined ? values[valueIndex] : match;
    });
  }
}
