
export const icons = [
  ['activity', '../assets/icons/activity.svg'],
  ['alarm-clock', '../assets/icons/alarm-clock.svg'],
  ['archive', '../assets/icons/archive.svg'],
  ['arrow-down', '../assets/icons/arrow-down.svg'],
  ['arrow-left-thin', '../assets/icons/arrow-left-thin.svg'],
  ['arrow-right', '../assets/icons/arrow-right.svg'],
  ['arrow-up', '../assets/icons/arrow-up.svg'],
  ['attachment', '../assets/icons/attachment.svg'],
  ['attachment-bold', '../assets/icons/attachment-bold.svg'],
  ['bug', '../assets/icons/bug.svg'],
  ['calendar', '../assets/icons/calendar.svg'],
  ['calendar-today', '../assets/icons/calendar-today.svg'],
  ['check', '../assets/icons/check.svg'],
  ['check-mark', '../assets/icons/check-mark.svg'],
  ['check-new', '../assets/icons/check-new.svg'],
  ['checkbox-off', '../assets/icons/checkbox-off.svg'],
  ['checkbox-on', '../assets/icons/checkbox-on.svg'],
  ['chevron-down', '../assets/icons/chevron-down.svg'],
  ['chevron-down2', '../assets/icons/chevron-down2.svg'],
  ['chevron-right', '../assets/icons/chevron-right.svg'],
  ['close', '../assets/icons/close.svg'],
  ['close-small', '../assets/icons/close-small.svg'],
  ['close-thin', '../assets/icons/close-thin.svg'],
  ['close2', '../assets/icons/close2.svg'],
  ['collapse', '../assets/icons/collapse.svg'],
  ['collapse-down', '../assets/icons/collapse-down.svg'],
  ['computer', '../assets/icons/computer.svg'],
  ['contact-small', '../assets/icons/contact-small.svg'],
  ['contacts', '../assets/icons/contacts.svg'],
  ['copy', '../assets/icons/copy.svg'],
  ['crown', '../assets/icons/crown.svg'],
  ['description', '../assets/icons/description.svg'],
  ['down', '../assets/icons/down.svg'],
  ['download', '../assets/icons/download.svg'],
  ['download-large', '../assets/icons/download-large.svg'],
  ['drag-icon', '../assets/icons/drag-icon.svg'],
  ['drag-icon-small', '../assets/icons/drag-icon-small.svg'],
  ['dropdown-down', '../assets/icons/dropdown-down.svg'],
  ['dropdown-new', '../assets/icons/dropdown-new.svg'],
  ['dropdown-right', '../assets/icons/dropdown-right.svg'],
  ['edit', '../assets/icons/edit.svg'],
  ['email', '../assets/icons/email.svg'],
  ['event', '../assets/icons/event.svg'],
  ['expand', '../assets/icons/expand.svg'],
  ['file', '../assets/icons/file.svg'],
  ['filters', '../assets/icons/filters.svg'],
  ['flag', '../assets/icons/flag.svg'],
  ['folder', '../assets/icons/folder.svg'],
  ['follow-up-small', '../assets/icons/follow-up-small.svg'],
  ['followup', '../assets/icons/followup.svg'],
  ['fullscreen', '../assets/icons/fullscreen.svg'],
  ['groups', '../assets/icons/groups.svg'],
  ['important', '../assets/icons/important.svg'],
  ['info', '../assets/icons/info.svg'],
  ['kp', '../assets/icons/kp.svg'],
  ['link-bold', '../assets/icons/link-bold.svg'],
  ['loading', '../assets/icons/loading.svg'],
  ['mail', '../assets/icons/mail.svg'],
  ['manage', '../assets/icons/manage.svg'],
  ['map-pin', '../assets/icons/map-pin.svg'],
  ['minus-medium', '../assets/icons/minus-medium.svg'],
  ['more-actions', '../assets/icons/more-actions.svg'],
  ['note', '../assets/icons/note.svg'],
  ['note-small', '../assets/icons/note-small.svg'],
  ['notebook', '../assets/icons/notebook.svg'],
  ['other', '../assets/icons/other.svg'],
  ['pdf-fullscreen', '../assets/icons/pdf-fullscreen.svg'],
  ['pdf-rotate', '../assets/icons/pdf-rotate.svg'],
  ['pdf-zoom-in', '../assets/icons/pdf-zoom-in.svg'],
  ['pdf-zoom-out', '../assets/icons/pdf-zoom-out.svg'],
  ['pellets', '../assets/icons/pellets.svg'],
  ['pin', '../assets/icons/pin.svg'],
  ['pin-direct', '../assets/icons/pin-direct.svg'],
  ['pin-small', '../assets/icons/pin-small.svg'],
  ['plus', '../assets/icons/plus.svg'],
  ['plus-manage', '../assets/icons/plus-manage.svg'],
  ['plus-medium', '../assets/icons/plus-medium.svg'],
  ['plus-small', '../assets/icons/plus-small.svg'],
  ['preview', '../assets/icons/preview.svg'],
  ['print', '../assets/icons/print.svg'],
  ['priority', '../assets/icons/priority-icon.svg'],
  ['project', '../assets/icons/project.svg'],
  ['quick-calendar', '../assets/icons/quick-calendar.svg'],
  ['quick-calendar-move', '../assets/icons/quick-calendar-move.svg'],
  ['quick-month', '../assets/icons/quick-month.svg'],
  ['quick-project', '../assets/icons/quick-project.svg'],
  ['quick-today', '../assets/icons/quick-today.svg'],
  ['quick-tomorrow', '../assets/icons/quick-tomorrow.svg'],
  ['quick-week', '../assets/icons/quick-week.svg'],
  ['radio-button-off', '../assets/icons/radio-button-off.svg'],
  ['radio-button-on', '../assets/icons/radio-button-on.svg'],
  ['red-underscore', '../assets/icons/red-underscore.svg'],
  ['reply', '../assets/icons/reply.svg'],
  ['resize', '../assets/icons/resize.svg'],
  ['retry', '../assets/icons/retry.svg'],
  ['saved', '../assets/icons/saved.svg'],
  ['search-small', '../assets/icons/search-small.svg'],
  ['send', '../assets/folders/sent.svg'],
  ['send-later', '../assets/icons/send-later.svg'],
  ['send-notify', '../assets/icons/send-notify.svg'],
  ['snooze', '../assets/icons/snooze.svg'],
  ['starred', '../assets/icons/starred.svg'],
  ['startdate', '../assets/icons/startdate.svg'],
  ['stitch', '../assets/icons/stitch.svg'],
  ['stitch-horizontal', '../assets/icons/stitch-horizontal.svg'],
  ['subtask', '../assets/icons/subtask.svg'],
  ['subtasks', '../assets/icons/subtasks.svg'],
  ['tags', '../assets/icons/tags.svg'],
  ['tags-small', '../assets/icons/tags-small.svg'],
  ['task', '../assets/icons/task.svg'],
  ['time-zone', '../assets/icons/time-zone.svg'],
  ['topics', '../assets/icons/topics.svg'],
  ['trash', '../assets/icons/trash.svg'],
  ['unlink', '../assets/icons/unlink.svg'],
  ['unread', '../assets/icons/unread.svg'],
  ['up', '../assets/icons/up.svg'],

  // Mail
  ['mail-reply', '../assets/icons/mail-reply.svg'],
  ['mail-star', '../assets/icons/mail-star.svg'],
  ['mail-unread', '../assets/icons/mail-unread.svg'],

  // Buttons
  ['button-nav-compose', '../assets/buttons/button-nav-compose.svg'],
  ['button-nav-notification', '../assets/buttons/button-nav-notification.svg'],
  ['button-nav-add', '../assets/buttons/button-nav-add.svg'],

  // Files
  ['pdf', '../assets/icons/pdf.svg'],
  ['jpg', '../assets/icons/jpg.svg'],

  // App menu
  ['app-menu-inbox', '../assets/app-menu/inbox.svg'],

  // Tasks
  ['today', '../assets/icons/today.svg'],
  ['week', '../assets/icons/week.svg'],

  // Social
  ['social-hangouts', '../assets/social/hangouts.svg'],
  ['social-no-conf', '../assets/social/no-conf.svg'],
  ['social-skype', '../assets/social/skype.svg'],
  ['social-slack', '../assets/social/slack.svg'],
  ['social-zoom', '../assets/social/zoom.svg'],

  // Placeholders
  ['placeholder-files', '../assets/placeholders/placeholder-files.svg'],

  // Sapphire
  ['sapphire-archive', '../assets/icons/sapphire/archive.svg'],
  ['sapphire-archive2', '../assets/icons/sapphire/archive2.svg'],
  ['sapphire-arrow-left', '../assets/icons/sapphire/arrow-left.svg'],
  ['sapphire-arrow-right', '../assets/icons/sapphire/arrow-right.svg'],
  ['sapphire-attachment', '../assets/icons/sapphire/attachment.svg'],
  ['sapphire-backward', '../assets/icons/sapphire/backward.svg'],
  ['sapphire-bulk', '../assets/icons/sapphire/bulk.svg'],
  ['sapphire-calendars', '../assets/icons/sapphire/calendars.svg'],
  ['sapphire-check', '../assets/icons/sapphire/check.svg'],
  ['sapphire-checkbox-null', '../assets/icons/sapphire/checkbox-null.svg'],
  ['sapphire-checkbox-off', '../assets/icons/sapphire/checkbox-off.svg'],
  ['sapphire-checkbox-on', '../assets/icons/sapphire/checkbox-on.svg'],
  ['sapphire-chevron-down', '../assets/icons/sapphire/chevron-down.svg'],
  ['sapphire-chevron-down-small', '../assets/icons/sapphire/chevron-down.svg'],
  ['sapphire-chevron-left', '../assets/icons/sapphire/chevron-left.svg'],
  ['sapphire-chevron-right', '../assets/icons/sapphire/chevron-right.svg'],
  ['sapphire-chevron-up', '../assets/icons/sapphire/chevron-up.svg'],
  ['sapphire-close', '../assets/icons/sapphire/close.svg'],
  ['sapphire-close-small', '../assets/icons/sapphire/close-small.svg'],
  ['sapphire-contact', '../assets/icons/sapphire/contact.svg'],
  ['sapphire-date', '../assets/icons/sapphire/date.svg'],
  ['sapphire-download', '../assets/icons/sapphire/download.svg'],
  ['sapphire-draft', '../assets/icons/sapphire/draft.svg'],
  ['sapphire-editor-align-center', '../assets/icons/sapphire/editor-align-center.svg'],
  ['sapphire-editor-align-left', '../assets/icons/sapphire/editor-align-left.svg'],
  ['sapphire-editor-align-right', '../assets/icons/sapphire/editor-align-right.svg'],
  ['sapphire-editor-bold', '../assets/icons/sapphire/editor-bold.svg'],
  ['sapphire-editor-italic', '../assets/icons/sapphire/editor-italic.svg'],
  ['sapphire-editor-ordered-list', '../assets/icons/sapphire/editor-ordered-list.svg'],
  ['sapphire-editor-strikethrough', '../assets/icons/sapphire/editor-strikethrough.svg'],
  ['sapphire-editor-underline', '../assets/icons/sapphire/editor-underline.svg'],
  ['sapphire-editor-unordered-list', '../assets/icons/sapphire/editor-unordered-list.svg'],
  ['sapphire-ellipsis', '../assets/icons/sapphire/ellipsis.svg'],
  ['sapphire-email', '../assets/icons/sapphire/email.svg'],
  ['sapphire-email-unread', '../assets/icons/sapphire/email-unread.svg'],
  ['sapphire-event', '../assets/icons/sapphire/event.svg'],
  ['sapphire-events', '../assets/icons/sapphire/events.svg'],
  ['sapphire-eye', '../assets/icons/sapphire/eye.svg'],
  ['sapphire-eye-closed', '../assets/icons/sapphire/eye-closed.svg'],
  ['sapphire-file', '../assets/icons/sapphire/file.svg'],
  ['sapphire-filters', '../assets/icons/sapphire/filters.svg'],
  ['sapphire-flagged', '../assets/icons/sapphire/flagged.svg'],
  ['sapphire-flagged-filled', '../assets/icons/sapphire/flagged-filled.svg'],
  ['sapphire-folders', '../assets/icons/sapphire/folders.svg'],
  ['sapphire-follow-up', '../assets/icons/sapphire/follow-up.svg'],
  ['sapphire-forward', '../assets/icons/sapphire/forward.svg'],
  ['sapphire-groups', '../assets/icons/sapphire/groups.svg'],
  ['sapphire-inbox', '../assets/icons/sapphire/inbox.svg'],
  ['sapphire-info', '../assets/icons/sapphire/info.svg'],
  ['sapphire-info2', '../assets/icons/sapphire/info2.svg'],
  ['sapphire-knots', '../assets/icons/sapphire/knots.svg'],
  ['sapphire-knowledge', '../assets/icons/sapphire/knowledge.svg'],
  ['sapphire-loading', '../assets/icons/sapphire/loading.svg'],
  ['sapphire-mail-forward', '../assets/icons/sapphire/mail-forward.svg'],
  ['sapphire-note', '../assets/icons/sapphire/note.svg'],
  ['sapphire-notebook', '../assets/icons/sapphire/notebook.svg'],
  ['sapphire-notes', '../assets/icons/sapphire/notes.svg'],
  ['sapphire-overdue', '../assets/icons/sapphire/overdue.svg'],
  ['sapphire-pin', '../assets/icons/sapphire/pin.svg'],
  ['sapphire-pin-filled', '../assets/icons/sapphire/pin-filled.svg'],
  ['sapphire-plus', '../assets/icons/sapphire/plus.svg'],
  ['sapphire-priority', '../assets/icons/sapphire/priority.svg'],
  ['sapphire-priority-flag', '../assets/icons/sapphire/priority-flag.svg'],
  ['sapphire-project', '../assets/icons/sapphire/project.svg'],
  ['sapphire-quick-end-month', '../assets/icons/sapphire/quick-end-month.svg'],
  ['sapphire-quick-two-weeks', '../assets/icons/sapphire/quick-two-weeks.svg'],
  ['sapphire-radio-off', '../assets/icons/sapphire/radio-off.svg'],
  ['sapphire-radio-on', '../assets/icons/sapphire/radio-on.svg'],
  ['sapphire-recent', '../assets/icons/sapphire/recent.svg'],
  ['sapphire-reminder', '../assets/icons/sapphire/reminder.svg'],
  ['sapphire-repeat', '../assets/icons/sapphire/repeat.svg'],
  ['sapphire-reply', '../assets/icons/sapphire/reply.svg'],
  ['sapphire-reply-all', '../assets/icons/sapphire/reply-all.svg'],
  ['sapphire-resize-handle', '../assets/icons/sapphire/resize-handle.svg'],
  ['sapphire-scheduled', '../assets/icons/sapphire/scheduled.svg'],
  ['sapphire-search', '../assets/icons/sapphire/search.svg'],
  ['sapphire-sent', '../assets/icons/sapphire/sent.svg'],
  ['sapphire-share', '../assets/icons/sapphire/share.svg'],
  ['sapphire-snooze', '../assets/icons/sapphire/snooze.svg'],
  ['sapphire-sort', '../assets/icons/sapphire/sort.svg'],
  ['sapphire-spam', '../assets/icons/sapphire/spam.svg'],
  ['sapphire-spin-count', '../assets/icons/sapphire/spin-count.svg'],
  ['sapphire-stitch', './assets/icons/sapphire/stitch.svg'],
  ['sapphire-subsidiary', './assets/icons/sapphire/subsidiary.svg'],
  ['sapphire-subtask', './assets/icons/sapphire/subtask.svg'],
  ['sapphire-tags', '../assets/icons/sapphire/tags.svg'],
  ['sapphire-tasks', '../assets/icons/sapphire/tasks.svg'],
  ['sapphire-tasks-list', '../assets/icons/sapphire/tasks-list.svg'],
  ['sapphire-thread', '../assets/icons/sapphire/thread.svg'],
  ['sapphire-time', '../assets/icons/sapphire/time.svg'],
  ['sapphire-today', '../assets/icons/sapphire/today.svg'],
  ['sapphire-trash', '../assets/icons/sapphire/trash.svg'],
  ['sapphire-upcoming', '../assets/icons/sapphire/upcoming.svg'],
  ['sapphire-user', '../assets/icons/sapphire/user.svg'],
  ['sapphire-user-filled', '../assets/icons/sapphire/user-filled.svg'],
  ['sapphire-user-plus', '../assets/icons/sapphire/user-plus.svg'],
  ['sapphire-vip', '../assets/icons/sapphire/vip.svg'],
  ['sapphire-week', '../assets/icons/sapphire/week.svg'],

  // File Types
  ['sapphire-file-acrobat', '../assets/icons/sapphire/file-type/acrobat.svg'],
  ['sapphire-file-archive', '../assets/icons/sapphire/file-type/archive.svg'],
  ['sapphire-file-document', '../assets/icons/sapphire/file-type/document.svg'],
  ['sapphire-file-excel', '../assets/icons/sapphire/file-type/excel.svg'],
  ['sapphire-file-figma', '../assets/icons/sapphire/file-type/figma.svg'],
  ['sapphire-file-other', '../assets/icons/sapphire/file-type/other-file.svg'],
  ['sapphire-file-pdf', '../assets/icons/sapphire/file-type/pdf.svg'],
  ['sapphire-file-photoshop', '../assets/icons/sapphire/file-type/photoshop.svg'],
  ['sapphire-file-sketch', '../assets/icons/sapphire/file-type/sketch.svg'],
  ['sapphire-file-spreadsheet', '../assets/icons/sapphire/file-type/spreadsheet.svg'],
  ['sapphire-file-word', '../assets/icons/sapphire/file-type/word.svg'],
  ['sapphire-file-zip', '../assets/icons/sapphire/file-type/zip.svg'],

  // Countries
  ['AND', '../assets/countries/images/ad.svg'],
  ['ARE', '../assets/countries/images/ae.svg'],
  ['AFG', '../assets/countries/images/af.svg'],
  ['ATG', '../assets/countries/images/ag.svg'],
  ['AIA', '../assets/countries/images/ai.svg'],
  ['ALB', '../assets/countries/images/al.svg'],
  ['ARM', '../assets/countries/images/am.svg'],
  ['AGO', '../assets/countries/images/ao.svg'],
  ['ATA', '../assets/countries/images/aq.svg'],
  ['ARG', '../assets/countries/images/ar.svg'],
  ['ASM', '../assets/countries/images/as.svg'],
  ['AUT', '../assets/countries/images/at.svg'],
  ['AUS', '../assets/countries/images/au.svg'],
  ['ABW', '../assets/countries/images/aw.svg'],
  ['ALA', '../assets/countries/images/ax.svg'],
  ['AZE', '../assets/countries/images/az.svg'],
  ['BIH', '../assets/countries/images/ba.svg'],
  ['BRB', '../assets/countries/images/bb.svg'],
  ['BGD', '../assets/countries/images/bd.svg'],
  ['BEL', '../assets/countries/images/be.svg'],
  ['BFA', '../assets/countries/images/bf.svg'],
  ['BGR', '../assets/countries/images/bg.svg'],
  ['BHR', '../assets/countries/images/bh.svg'],
  ['BDI', '../assets/countries/images/bi.svg'],
  ['BEN', '../assets/countries/images/bj.svg'],
  ['BLM', '../assets/countries/images/bl.svg'],
  ['BMU', '../assets/countries/images/bm.svg'],
  ['BRN', '../assets/countries/images/bn.svg'],
  ['BOL', '../assets/countries/images/bo.svg'],
  ['BES', '../assets/countries/images/bq.svg'],
  ['BRA', '../assets/countries/images/br.svg'],
  ['BHS', '../assets/countries/images/bs.svg'],
  ['BTN', '../assets/countries/images/bt.svg'],
  ['BVT', '../assets/countries/images/bv.svg'],
  ['BWA', '../assets/countries/images/bw.svg'],
  ['BLR', '../assets/countries/images/by.svg'],
  ['BLZ', '../assets/countries/images/bz.svg'],
  ['CAN', '../assets/countries/images/ca.svg'],
  ['CCK', '../assets/countries/images/cc.svg'],
  ['COD', '../assets/countries/images/cd.svg'],
  ['CAF', '../assets/countries/images/cf.svg'],
  ['COG', '../assets/countries/images/cg.svg'],
  ['CHE', '../assets/countries/images/ch.svg'],
  ['CIV', '../assets/countries/images/ci.svg'],
  ['COK', '../assets/countries/images/ck.svg'],
  ['CHL', '../assets/countries/images/cl.svg'],
  ['CMR', '../assets/countries/images/cm.svg'],
  ['CHN', '../assets/countries/images/cn.svg'],
  ['COL', '../assets/countries/images/co.svg'],
  ['CRI', '../assets/countries/images/cr.svg'],
  ['CUB', '../assets/countries/images/cu.svg'],
  ['CPV', '../assets/countries/images/cv.svg'],
  ['CUW', '../assets/countries/images/cw.svg'],
  ['CXR', '../assets/countries/images/cx.svg'],
  ['CYP', '../assets/countries/images/cy.svg'],
  ['CZE', '../assets/countries/images/cz.svg'],
  ['DEU', '../assets/countries/images/de.svg'],
  ['DJI', '../assets/countries/images/dj.svg'],
  ['DNK', '../assets/countries/images/dk.svg'],
  ['DMA', '../assets/countries/images/dm.svg'],
  ['DOM', '../assets/countries/images/do.svg'],
  ['DZA', '../assets/countries/images/dz.svg'],
  ['ECU', '../assets/countries/images/ec.svg'],
  ['EST', '../assets/countries/images/ee.svg'],
  ['EGY', '../assets/countries/images/eg.svg'],
  ['ESH', '../assets/countries/images/eh.svg'],
  ['ERI', '../assets/countries/images/er.svg'],
  ['ESP', '../assets/countries/images/es.svg'],
  ['ETH', '../assets/countries/images/et.svg'],
  ['FIN', '../assets/countries/images/fi.svg'],
  ['FJI', '../assets/countries/images/fj.svg'],
  ['FLK', '../assets/countries/images/fk.svg'],
  ['FSM', '../assets/countries/images/fm.svg'],
  ['FRO', '../assets/countries/images/fo.svg'],
  ['FRA', '../assets/countries/images/fr.svg'],
  ['GAB', '../assets/countries/images/ga.svg'],
  ['GBR', '../assets/countries/images/gb.svg'],
  ['GRD', '../assets/countries/images/gd.svg'],
  ['GEO', '../assets/countries/images/ge.svg'],
  ['GUF', '../assets/countries/images/gf.svg'],
  ['GGY', '../assets/countries/images/gg.svg'],
  ['GHA', '../assets/countries/images/gh.svg'],
  ['GIB', '../assets/countries/images/gi.svg'],
  ['GRL', '../assets/countries/images/gl.svg'],
  ['GMB', '../assets/countries/images/gm.svg'],
  ['GIN', '../assets/countries/images/gn.svg'],
  ['GLP', '../assets/countries/images/gp.svg'],
  ['GNQ', '../assets/countries/images/gq.svg'],
  ['GRC', '../assets/countries/images/gr.svg'],
  ['SGS', '../assets/countries/images/gs.svg'],
  ['GTM', '../assets/countries/images/gt.svg'],
  ['GUM', '../assets/countries/images/gu.svg'],
  ['GNB', '../assets/countries/images/gw.svg'],
  ['GUY', '../assets/countries/images/gy.svg'],
  ['HKG', '../assets/countries/images/hk.svg'],
  ['HMD', '../assets/countries/images/hm.svg'],
  ['HND', '../assets/countries/images/hn.svg'],
  ['HRV', '../assets/countries/images/hr.svg'],
  ['HTI', '../assets/countries/images/ht.svg'],
  ['HUN', '../assets/countries/images/hu.svg'],
  ['IDN', '../assets/countries/images/id.svg'],
  ['IRL', '../assets/countries/images/ie.svg'],
  ['ISR', '../assets/countries/images/il.svg'],
  ['IMN', '../assets/countries/images/im.svg'],
  ['IND', '../assets/countries/images/in.svg'],
  ['IOT', '../assets/countries/images/io.svg'],
  ['IRQ', '../assets/countries/images/iq.svg'],
  ['IRN', '../assets/countries/images/ir.svg'],
  ['ISL', '../assets/countries/images/is.svg'],
  ['ITA', '../assets/countries/images/it.svg'],
  ['JEY', '../assets/countries/images/je.svg'],
  ['JAM', '../assets/countries/images/jm.svg'],
  ['JOR', '../assets/countries/images/jo.svg'],
  ['JPN', '../assets/countries/images/jp.svg'],
  ['KEN', '../assets/countries/images/ke.svg'],
  ['KGZ', '../assets/countries/images/kg.svg'],
  ['KHM', '../assets/countries/images/kh.svg'],
  ['KIR', '../assets/countries/images/ki.svg'],
  ['COM', '../assets/countries/images/km.svg'],
  ['KNA', '../assets/countries/images/kn.svg'],
  ['PRK', '../assets/countries/images/kp.svg'],
  ['KOR', '../assets/countries/images/kr.svg'],
  ['KWT', '../assets/countries/images/kw.svg'],
  ['CYM', '../assets/countries/images/ky.svg'],
  ['KAZ', '../assets/countries/images/kz.svg'],
  ['LAO', '../assets/countries/images/la.svg'],
  ['LBN', '../assets/countries/images/lb.svg'],
  ['LCA', '../assets/countries/images/lc.svg'],
  ['LIE', '../assets/countries/images/li.svg'],
  ['LKA', '../assets/countries/images/lk.svg'],
  ['LBR', '../assets/countries/images/lr.svg'],
  ['LSO', '../assets/countries/images/ls.svg'],
  ['LTU', '../assets/countries/images/lt.svg'],
  ['LUX', '../assets/countries/images/lu.svg'],
  ['LVA', '../assets/countries/images/lv.svg'],
  ['LBY', '../assets/countries/images/ly.svg'],
  ['MAR', '../assets/countries/images/ma.svg'],
  ['MCO', '../assets/countries/images/mc.svg'],
  ['MDA', '../assets/countries/images/md.svg'],
  ['MNE', '../assets/countries/images/me.svg'],
  ['MAF', '../assets/countries/images/mf.svg'],
  ['MDG', '../assets/countries/images/mg.svg'],
  ['MHL', '../assets/countries/images/mh.svg'],
  ['MKD', '../assets/countries/images/mk.svg'],
  ['MLI', '../assets/countries/images/ml.svg'],
  ['MMR', '../assets/countries/images/mm.svg'],
  ['MNG', '../assets/countries/images/mn.svg'],
  ['MAC', '../assets/countries/images/mo.svg'],
  ['MNP', '../assets/countries/images/mp.svg'],
  ['MTQ', '../assets/countries/images/mq.svg'],
  ['MRT', '../assets/countries/images/mr.svg'],
  ['MSR', '../assets/countries/images/ms.svg'],
  ['MLT', '../assets/countries/images/mt.svg'],
  ['MUS', '../assets/countries/images/mu.svg'],
  ['MDV', '../assets/countries/images/mv.svg'],
  ['MWI', '../assets/countries/images/mw.svg'],
  ['MEX', '../assets/countries/images/mx.svg'],
  ['MYS', '../assets/countries/images/my.svg'],
  ['MOZ', '../assets/countries/images/mz.svg'],
  ['NAM', '../assets/countries/images/na.svg'],
  ['NCL', '../assets/countries/images/nc.svg'],
  ['NER', '../assets/countries/images/ne.svg'],
  ['NFK', '../assets/countries/images/nf.svg'],
  ['NGA', '../assets/countries/images/ng.svg'],
  ['NIC', '../assets/countries/images/ni.svg'],
  ['NLD', '../assets/countries/images/nl.svg'],
  ['NOR', '../assets/countries/images/no.svg'],
  ['NPL', '../assets/countries/images/np.svg'],
  ['NRU', '../assets/countries/images/nr.svg'],
  ['NIU', '../assets/countries/images/nu.svg'],
  ['NZL', '../assets/countries/images/nz.svg'],
  ['OMN', '../assets/countries/images/om.svg'],
  ['PAN', '../assets/countries/images/pa.svg'],
  ['PER', '../assets/countries/images/pe.svg'],
  ['PYF', '../assets/countries/images/pf.svg'],
  ['PNG', '../assets/countries/images/pg.svg'],
  ['PHL', '../assets/countries/images/ph.svg'],
  ['PAK', '../assets/countries/images/pk.svg'],
  ['POL', '../assets/countries/images/pl.svg'],
  ['SPM', '../assets/countries/images/pm.svg'],
  ['PCN', '../assets/countries/images/pn.svg'],
  ['PRI', '../assets/countries/images/pr.svg'],
  ['PSE', '../assets/countries/images/ps.svg'],
  ['PRT', '../assets/countries/images/pt.svg'],
  ['PLW', '../assets/countries/images/pw.svg'],
  ['PRY', '../assets/countries/images/py.svg'],
  ['QAT', '../assets/countries/images/qa.svg'],
  ['REU', '../assets/countries/images/re.svg'],
  ['ROU', '../assets/countries/images/ro.svg'],
  ['SRB', '../assets/countries/images/rs.svg'],
  ['RUS', '../assets/countries/images/ru.svg'],
  ['RWA', '../assets/countries/images/rw.svg'],
  ['SAU', '../assets/countries/images/sa.svg'],
  ['SLB', '../assets/countries/images/sb.svg'],
  ['SYC', '../assets/countries/images/sc.svg'],
  ['SDN', '../assets/countries/images/sd.svg'],
  ['SWE', '../assets/countries/images/se.svg'],
  ['SGP', '../assets/countries/images/sg.svg'],
  ['SHN', '../assets/countries/images/sh.svg'],
  ['SVN', '../assets/countries/images/si.svg'],
  ['SJM', '../assets/countries/images/sj.svg'],
  ['SVK', '../assets/countries/images/sk.svg'],
  ['SLE', '../assets/countries/images/sl.svg'],
  ['SMR', '../assets/countries/images/sm.svg'],
  ['SEN', '../assets/countries/images/sn.svg'],
  ['SOM', '../assets/countries/images/so.svg'],
  ['SUR', '../assets/countries/images/sr.svg'],
  ['SSD', '../assets/countries/images/ss.svg'],
  ['STP', '../assets/countries/images/st.svg'],
  ['SLV', '../assets/countries/images/sv.svg'],
  ['SXM', '../assets/countries/images/sx.svg'],
  ['SYR', '../assets/countries/images/sy.svg'],
  ['SWZ', '../assets/countries/images/sz.svg'],
  ['TCA', '../assets/countries/images/tc.svg'],
  ['TCD', '../assets/countries/images/td.svg'],
  ['ATF', '../assets/countries/images/tf.svg'],
  ['TGO', '../assets/countries/images/tg.svg'],
  ['THA', '../assets/countries/images/th.svg'],
  ['TJK', '../assets/countries/images/tj.svg'],
  ['TKL', '../assets/countries/images/tk.svg'],
  ['TLS', '../assets/countries/images/tl.svg'],
  ['TKM', '../assets/countries/images/tm.svg'],
  ['TUN', '../assets/countries/images/tn.svg'],
  ['TON', '../assets/countries/images/to.svg'],
  ['TUR', '../assets/countries/images/tr.svg'],
  ['TTO', '../assets/countries/images/tt.svg'],
  ['TUV', '../assets/countries/images/tv.svg'],
  ['TWN', '../assets/countries/images/tw.svg'],
  ['TZA', '../assets/countries/images/tz.svg'],
  ['UKR', '../assets/countries/images/ua.svg'],
  ['UGA', '../assets/countries/images/ug.svg'],
  ['UMI', '../assets/countries/images/um.svg'],
  ['USA', '../assets/countries/images/us.svg'],
  ['URY', '../assets/countries/images/uy.svg'],
  ['UZB', '../assets/countries/images/uz.svg'],
  ['VAT', '../assets/countries/images/va.svg'],
  ['VCT', '../assets/countries/images/vc.svg'],
  ['VEN', '../assets/countries/images/ve.svg'],
  ['VGB', '../assets/countries/images/vg.svg'],
  ['VIR', '../assets/countries/images/vi.svg'],
  ['VNM', '../assets/countries/images/vn.svg'],
  ['VUT', '../assets/countries/images/vu.svg'],
  ['WLF', '../assets/countries/images/wf.svg'],
  ['WSM', '../assets/countries/images/ws.svg'],
  ['XKX', '../assets/countries/images/xk.svg'],
  ['YEM', '../assets/countries/images/ye.svg'],
  ['MYT', '../assets/countries/images/yt.svg'],
  ['ZAF', '../assets/countries/images/za.svg'],
  ['ZMB', '../assets/countries/images/zm.svg'],
  ['ZWE', '../assets/countries/images/zw.svg'],
] as const;

export type Icon = typeof icons[number][0];
