import { Injector } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TypedFormGroup } from "@modules/common/utils/form";

export type TeamRoleForm = FormGroup<TypedFormGroup<{
  id: string;
  title: string;
  teamId: string;
  addUsers: boolean;
  removeUsers: boolean;
  addDocuments: boolean;
  editDocuments: boolean;
  deleteDocuments: boolean;
  shareDocuments: boolean;
}>>;

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});
  
export class TeamRole {
  formBuilder = injector.get(FormBuilder);

  id: string;
  title: string;
  teamId: string;
  addUsers: boolean;
  removeUsers: boolean;
  addDocuments: boolean;
  editDocuments: boolean;
  deleteDocuments: boolean;
  shareDocuments: boolean;

  constructor(data: any = {}) {
    this.id = data?.id;
    this.title = data?.title;
    this.teamId = data?.teamId;
    this.addUsers = !!data.addUsers;
    this.removeUsers = !!data.removeUsers;
    this.addDocuments = !!data.addDocuments;
    this.editDocuments = !!data.editDocuments;
    this.deleteDocuments = !!data.deleteDocuments;
    this.shareDocuments = !!data.shareDocuments;
  }

  public asFormGroup(): TeamRoleForm {
    return this.formBuilder.group({
      id: this.id,
      title: this.title,
      teamId: this.teamId,
      addUsers: this.addUsers,
      removeUsers: this.removeUsers,
      addDocuments: this.addDocuments,
      editDocuments: this.editDocuments,
      deleteDocuments: this.deleteDocuments,
      shareDocuments: this.shareDocuments,
    });
  }

  static fromFormGroup(form: TeamRoleForm): TeamRole {
    const { value: formValues } = form;

    return new TeamRole(formValues);
  }

  asPayloadJSON() {
    return {
      title: this.title,
      teamId: this.teamId,
      addUsers: this.addUsers,
      removeUsers: this.removeUsers,
      addDocuments: this.addDocuments,
      editDocuments: this.editDocuments,
      deleteDocuments: this.deleteDocuments,
      shareDocuments: this.shareDocuments,
    };
  }
}

export const adminRole = new TeamRole({
  id: 'admin',
  title: 'Admin',
  addUsers: true,
  removeUsers: true,
  addDocuments: true,
  editDocuments: true,
  deleteDocuments: true,
  shareDocuments: true,
})

export const regularRole = new TeamRole({
  id: 'regular',
  title: 'Regular',
  addUsers: false,
  removeUsers: false,
  addDocuments: true,
  editDocuments: true,
  deleteDocuments: true,
  shareDocuments: true,
})
