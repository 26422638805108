import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { unauthGuard } from './services/unauth.guard';

// Components
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ForgotPasswordComponent } from '@modules/auth/components/forgot-password/forgot-password.component';
import { RestorePasswordComponent } from '@modules/auth/components/restore-password/restore-password.component';

const authRoutes: Routes = [
  { path: 'welcome', canActivate: [unauthGuard], component: WelcomeComponent },
  { path: 'forgot-password', canActivate: [unauthGuard], component: ForgotPasswordComponent },
  { path: 'restore-password', canActivate: [unauthGuard], component: RestorePasswordComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }
