import { environment } from "@environment";
import { Account } from "@modules/account/types/account";

export class TeamParticipant {
  avatar: string;
  firstName: string;
  fullName: string;
  id: string;
  joinedAt: Date;
  lastName: string;
  owner: boolean;
  roleId: string;
  teamId: string;
  status: 'pending' | 'accepted' | 'rejected';
  userName: string;

  constructor(data: any = {}) {
    this.avatar = data instanceof Account
      ? data.avatar
      : (data?.avatar && `${environment.baseUrl}/api/attachments/avatar/avatar/${data?.avatar}?t=${new Date().getTime()}&account-id=${data?.id}`);
    this.firstName = data?.firstName;
    this.fullName = [data?.firstName, data?.lastName].join(' ');
    this.id = data?.id;
    this.joinedAt = data.joinedAt && new Date(data.joinedAt);
    this.lastName = data?.lastName;
    this.owner = data?.owner;
    this.roleId = data?.roleId;
    this.teamId = data?.teamId
    this.status = data?.status;
    this.userName = data?.userName;
  }

  asPayloadJSON() {
    return {
      roleId: this.roleId
    };
  }
}
