// Common
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

// Types
import { Team } from '@modules/settings/types/team';
import { TeamRolesFilters } from '@modules/settings/types/team-roles-filters';
import { SortOrder } from '@modules/common/types/sort-order';
import { TableColumn } from '@modules/common/types/table-column';
import { TeamRole, TeamRoleForm, adminRole, regularRole } from '@modules/settings/types/team-role';
import { TeamRolesService } from '@modules/settings/services/team-roles.service';

// RX
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { AlertService } from '@modules/alert/services/alert.service';

@Component({
  selector: 'app-settings-teams-team-roles',
  templateUrl: './team-roles.component.html',
  styleUrls: ['./team-roles.component.less']
})
export class TeamsTeamRolesComponent implements OnInit, OnDestroy {

  public roles: TeamRole[] = [];
  public formGroups: TeamRoleForm[] = [];
  public count = 0;
  public columns: TableColumn[];
  public page = 0;
  public perPage = 10;
  public sortBy: TeamRolesFilters['sortBy'] = 'title';
  public sortOrder: SortOrder = 'asc';

  @Input() team: Team;

  private reload = new Subject<void>();
  private alive = new Subject<void>();
  private subscriptions: Subscription[] = [];

  @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<void>;
  @ViewChild('titleTemplate', { static: true }) titleTemplate: TemplateRef<void>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;

  constructor (
    private teamRolesService: TeamRolesService,
    private alertsService: AlertService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columns = [
      { key: 'controls.title', title: 'Role Name', sortable: true, sortKey: 'title', template: this.titleTemplate },
      { key: 'controls.addUsers', title: 'Add Users', template: this.checkboxTemplate },
      { key: 'controls.removeUsers', title: 'Remove Users', template: this.checkboxTemplate },
      { key: 'controls.addDocuments', title: 'Create Documents', template: this.checkboxTemplate },
      { key: 'controls.editDocuments', title: 'Edit Documents', template: this.checkboxTemplate },
      { key: 'controls.deleteDocuments', title: 'Delete Documents', template: this.checkboxTemplate },
      { key: 'controls.shareDocuments', title: 'Share Documents', template: this.checkboxTemplate },
      { key: null, title: '', template: this.actionsTemplate, align: 'right' },
    ];

    this.reload
      .pipe(
        debounceTime(600),
        switchMap(() => this.teamRolesService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage,
        }, { team: this.team })),
        tap(() => this.unsubscribeAllForms()),
        takeUntil(this.alive)
      )
      .subscribe(({ items: roles, count }) => {
        this.roles = roles;
        this.count = count || 0;
        this.subscribeToFormChanges();
      });

    this.reload.next();

    this.teamRolesService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: TeamRolesFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  removeRole(item: TeamRole) {
    this.teamRolesService.delete(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  removeRolePrompt(item: TeamRoleForm) {
    const role = TeamRole.fromFormGroup(item);

    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to remove Role ${role.title} from Team ${this.team.title}?`,
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'REMOVE', click: () => this.removeRole(role), close: true }
      ],
      showUntil: this.alive
    });
  }

  private unsubscribeAllForms() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
    this.formGroups = [];
  }

  private subscribeToFormChanges() {
    this.formGroups.push(adminRole.asFormGroup());
    this.formGroups.push(regularRole.asFormGroup());

    this.roles.forEach(role => {
      const formGroup = role.asFormGroup();

      const subscription = formGroup.valueChanges
        .pipe(
          debounceTime(600),
          map(() => TeamRole.fromFormGroup(formGroup)),
          switchMap(role => this.teamRolesService.update(role, { emit: false, toast: false })),
          takeUntil(this.alive)
        )
        .subscribe();

      this.subscriptions.push(subscription);
      this.formGroups.push(formGroup);
    });
  }
}
