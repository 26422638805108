<app-sidebar-item
  *nestedFor="
    let item of items;
    let index = index;
    let depth = depth;
    let expandable = expandable;
    let expanded = expanded;
    stateKey: StateKey.contactsSidebarGroupsTree + team?.id;
  "
  [title]="item.title"
  [icon]="item.icon"
  [depth]="depth + (team ? 2 : 0)"
  [expandable]="expandable"
  [expanded]="expanded.value"
  [active]="currentTeamId === team?.id && sidebarFilter === item.id"
  (onExpand)="expanded.next($event)"
  (appClick)="selectFilter(item.id)"
  [appSidebarContextMenu]="item.id"

  #elRef
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event, item.id, index)"
/>

<app-sidebar-items-divider />

<app-side-bar-groups
  (selectFilter)="selectFilter($event)"
  [team]="team"
/>

<app-sidebar-items-divider />

<app-sidebar-knots
  sidebarSplitViewKey="contactsSidebar"
  [team]="team"
/>

<app-sidebar-items-divider />

<app-sidebar-tags
  sidebarSplitViewKey="contactsSidebar"
  [team]="team"
/>

<ng-template #afterDropTemplate let-item=item>
  <app-contact-quick-form
    [autofocus]="true"
    [title]="item.title"
    [contact]="item"
    (more)="handleMore($event)"
    (close)="handleClose()"
  />
</ng-template>
