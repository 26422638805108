// Common
import { Component, Input, OnInit, SimpleChanges, OnChanges, Output, EventEmitter, NgZone, OnDestroy, Self, Inject } from '@angular/core';
import { beginningOfDay } from '@modules/common/utils/date';

// RX
import { interval, BehaviorSubject, merge, Observable, Subject, EMPTY } from 'rxjs';
import { takeUntil, filter, debounceTime, map, first, switchMap } from 'rxjs/operators';

// Services
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class EventsListComponent extends InfinityScrollListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no events';
  @Input() filters: Like<EventsFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() hoverPlacement: PopoverPlacement = 'right';
  @Input() scrollToDay: Observable<Date>;
  @Input() debug: 'score';

  @Output() viewDate: EventEmitter<Date> = new EventEmitter();
  @Output() clickEvent = new EventEmitter<CalendarEvent>();
  @Output() openEvent = new EventEmitter<CalendarEvent>();
  @Output() doubleClickEvent = new EventEmitter<CalendarEvent>();

  public itemHeight = 94;
  public showCountView = new BehaviorSubject(true);
  public isHover = false;
  public resetSelected = new Subject<void>();

  private isComponentInitialized = false;
  private scrollToDayChanges = new Subject<void>();

  constructor(
    private eventsService: EventsService,
    private eventsStateService: CalendarAppStateService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject
  ) {
    super(ngZone, scrollToPositionSubject);

    this.scrollToDayChanges
      .pipe(
        takeUntil(this.componentNotDestroyed),
        switchMap(() => this.scrollToDay || EMPTY)
      )
      .subscribe((date: Date) => date && this.scrollToDate(date));
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.eventsStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.eventsService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    this.scrollToDate(new Date);

    merge(
      this.currentIndex.asObservable(),
      this.itemsStreamObservable
    )
      .pipe(
        filter(() => this.currentIndex.value >= 0),
        filter(() => this.items && this.items.length > 0 && this.items[this.currentIndex.value] &&
          this.items[this.currentIndex.value]?.['startTime'] !== undefined
        ),
        map(() => this.items[this.currentIndex.value]['startTime'] as Date),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((date: Date) => this.viewDate.emit(date));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }

    if ('scrollToDay' in changes) {
      this.scrollToDayChanges.next();
    }
  }

  /**
   * Actions
   */

  scrollToDate(date: Date) {
    this.eventsService.search(
      {
        ...this.filters,
        limit: 1,
        toTime: beginningOfDay(date)
      })
      .pipe(
        first(),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({ count }) => {
        this.scrollToIndex(count); // TODO this is completely broken
        this.scrollToIndex(0);
        if (!this.isComponentInitialized) {
          this.isComponentInitialized = true;
          super.ngOnInit();
        }
      });
  }

  /**
   * Base Methods override
   */

  getItems(offset: number, limit: number): Observable<{ items: CalendarEvent[], count: number }> {
    return this.eventsService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.eventsStateService.setSelectedEvents([]);
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(appointment: CalendarEvent) {
    if (!appointment) { return; }
    this.doubleClickEvent.emit(appointment);
  }

  handleClickItem(appointment: CalendarEvent) {
    if (!appointment) { return; }
    this.clickEvent.emit(appointment);
  }
}
