// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Utils
import { isNil, uuid } from '@modules/common/utils/base';

// Types
import { Icon } from '@modules/icons/types/icons';
import { Subject } from 'rxjs';

// RX
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
})
export class CheckboxComponent implements OnInit, OnChanges, OnDestroy {

  @Input() inputFormControl: UntypedFormControl;
  @Input() title: string;
  @Input() appearance: 'new' | 'amethyst' | 'sapphire' = 'new';
  @Input() disabled = false;
  @Input() size: 's' | 'm' = 's';

  @Output() onCheck = new EventEmitter<boolean>();

  public uuid = uuid();
  public icon: Icon;
  public checked: boolean;
  public unset: boolean;

  private controlChanged = new Subject<void>();
  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.controlChanged
      .pipe(
        switchMap(() => this.inputFormControl?.valueChanges),
        startWith(this.inputFormControl.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.checked = !!value;
        this.unset = isNil(value);
        this.setIcons();
      });

    this.setIcons();
    this.controlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('appearance' in changes) {
      this.setIcons();
    }
    if ('inputFormControl' in changes) {
      this.controlChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  check(event) {
    event.stopPropagation();

    if (this.disabled || this.inputFormControl.disabled) { return; }

    this.inputFormControl.setValue(!this.inputFormControl.value);
    this.onCheck.emit(this.inputFormControl.value);
  }

  private setIcons() {
    const checked = this.inputFormControl?.value;

    switch (this.appearance) {
      case 'new':
        this.icon = 'check-new';
        break;
        case 'amethyst':
          this.icon = 'check-mark';
        break;
      case 'sapphire':
        this.icon = checked
          ? 'sapphire-checkbox-on'
          : (checked === false ? 'sapphire-checkbox-off' : 'sapphire-checkbox-null');
        break;
    }
  }
}
