// Common
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { LSEnum, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { SpaceStateService } from '@modules/common/services/space-state.service';

// Types
import { Application } from '@modules/common/types/application';
import { Space } from '@modules/settings/types/space';

@Component({
  selector: 'app-dynamic-panel',
  templateUrl: './dynamic-panel.component.html',
  styleUrls: ['./dynamic-panel.component.less']
})
export class DynamicPanelComponent implements OnInit, OnDestroy {

  @Input() splitViewKey = 'dp';
  @Input() stateKey: 'modal' | 'default' = 'default';

  public minimized: boolean;
  public formOpened: boolean;
  public selectedApp: Application;
  public appContextMenuOpened = false;
  public spacesContextMenuOpened = false;
  public Application = Application;
  public space: Space;
  public popoverClose = new Subject<void>();

  private alive = new Subject<void>();

  @LSEnum({ default: Application.mail, lsKey: 'dp.app', values: Object.values(Application) })
  state: LocalStorageItem<Application>;

  constructor(
    private splitViewService: SplitViewService,
    private changeDetector: ChangeDetectorRef,
    private dpService: DynamicPanelService,
    private stateService: SpaceStateService,
    @Inject(SPACE_ID) public spaceId: BehaviorSubject<string>
  ) {

    this.dpService.getFormItem()
      .pipe(takeUntil(this.alive))
      .subscribe(item => this.formOpened = !!item);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService.collapsed.get(this.splitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
        this.changeDetector.detectChanges();
      });

    this.state.get(this.stateKey)
      .pipe(takeUntil(this.alive))
      .subscribe(app => {
        this.selectedApp = app;
      });

    this.stateService.getCurrentSpace()
      .pipe(takeUntil(this.alive))
      .subscribe(space => {
        this.space = space;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleAppChange(app: Application) {
    this.state.set(app, this.stateKey);
  }

  handleSearch() {
    this.popoverClose.next();
  }

  handleCancel() {
    this.popoverClose.next();
  }
}
