<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Time Zone
    </div>
    <div class="body-secondary fixed-width">
      Changes made will affect all your data. You can also have the time zone change automatically based on your current location.
    </div>
  </div>

  <div class="block-col">
    <app-timezone-input
      class="block-col-item time-zone-picker"
      [control]="form.controls.timeZone"
      placeholder="Select Time Zone"
      appearance="sapphire"
    />

    <div class="block-col-container">
      <app-checkbox
        class="block-col-item"
        [inputFormControl]="form.controls.changeAutomatically"
        title="Change Automatically"
        appearance="amethyst"
      ></app-checkbox>

      <app-checkbox
        class="block-col-item"
        [inputFormControl]="form.controls.notifyOnTimezoneChanges"
        title="Notify me if Timezone changes"
        appearance="amethyst"
      ></app-checkbox>
    </div>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Time Format
    </div>
    <div class="body-secondary">
      Select the time format that best fits your schedule.
    </div>
  </div>

  <div class="block-col">
    <app-dropdown-input
      class="block-col-item button"
      [inputFormControl]="form.controls.timeFormat"
      placeholder="Time format"
      [options]="timeFormatOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Date Format
    </div>
    <div class="body-secondary">
      Select the date format that best fits your schedule.
    </div>
  </div>

  <div class="block-col">
    <app-dropdown-input
      class="block-col-item button"
      [inputFormControl]="form.controls.dateFormat"
      placeholder="Date format"
      [options]="dateFormatOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>
