// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Team } from '../types/team';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { TeamsFilters } from '../types/teams-filters';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { Account } from '@modules/account/types/account';

// Env
import { environment } from '@environment';

@Injectable()
export class TeamsService extends BaseRestService<Team, TeamsFilters> {
  private url = environment.baseUrl + '/api/account/teams';
  
  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(
    filters: Partial<TeamsFilters>,
    { account }: { account?: Account } = { account: null }
  ): Observable<BaseSearchResponse<Team>> {
    const requestParams = { params: new TeamsFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Team[], total: number }>(this.url, requestParams)
      .pipe(
        map(({ count, items, total }) => ({
          count,
          items: (items || []).map(item => new Team(item, account)),
          total
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  getOne(id: string): Observable<Team> {
    return this.http.get<{ team: object }>(`${environment.baseUrl}/api/account/teams/${id}`)
      .pipe(
        map(({ team }) => new Team(team)),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: Team,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<Team> {
    return this.http.post<{ success: boolean, item: Team }>(
      `${environment.baseUrl}/api/account/teams`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Team created.` });
        }),
        map(({ item }) => new Team(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: Team,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<Team> {
    return this.http.put<{ success: boolean, item: Team }>(
      environment.baseUrl + '/api/account/teams/' + instance.id,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Team updated' });
          }
        }),
        map(({ item }) => new Team(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  upsert(instance: Team, config?: FeedbackConfig): Observable<Team> {
    return instance.id ? this.update(instance, config) : this.create(instance, config);
  }

  @warmUpObservable
  delete(
    instance: Team,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/account/teams/${instance.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || 'Team successfully deleted' });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }
}
