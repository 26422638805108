<div class="filters">
  <app-input
    class="search-input"
    appearance="sapphire-outline"
    [inputFormControl]="searchControl"
    placeholder="Search Members"
    size="m"
    leftIcon="sapphire-search"
  />

  <div class="filler"></div>

  <div class="filter">
    Role:
    <app-dropdown-input
      [inputFormControl]="roleControl"
      [options]="rolesFilterOptions"
      appearance="sapphire-inline"
      size="s"
      placement="bottomRight"
    />
  </div>

  <div class="filter">
    Status:
    <app-dropdown-input
      [inputFormControl]="statusControl"
      [options]="statusOptions"
      appearance="sapphire-inline"
      size="s"
      placement="bottomRight"
    />
  </div>
</div>

<stch-table
  class="items"
  [items]="members"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  [placeholderTemplate]="placeholderTemplate"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
/>

<ng-template #placeholderTemplate>
  <div
    *ngIf="!members.length"
    class="placeholder"
  >
    <img
      class="placeholder-image"
      src="assets/placeholders/teams.png"
    />

    <div class="placeholder-text">
      You don`t have any Members right now, start by adding a Member
    </div>

    <app-button
      appearance="sapphire-text-accent"
      size="l"
      label="Invite Members"
      icon="sapphire-plus"
    />
  </div>
</ng-template>

<ng-template #nameTemplate let-value=value let-member=item>
  <stch-avatar [user]="member" />
  <div class="name-email">
    <div class="name">{{ value }} {{ member.owner ? '(owner)' : '' }}</div>
    <div class="email">{{ member.userName }}{{ mailUrl }}</div>
  </div>
</ng-template>

<ng-template #actionsTemplate let-value=value let-member=item>
  <app-button
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-ellipsis"
    matTooltip="More"

    stchPopover
    stchPopoverPlacement="bottomRight"
    stchPopoverAppearance="sapphire-context-menu"
    [stchPopoverFallbackPlacements]="['topRight']"
    [stchPopoverTemplate]="actionsDropdownTemplate"
    [stchPopoverContext]="{ member: member }"
    stchPopoverTrigger="click"
  />
</ng-template>

<ng-template #actionsDropdownTemplate let-member="member">
  <app-context-menu-item
    title="Delete Member"
    appearance="sapphire"
    [dangerous]="true"
    (execute)="removeParticipantPrompt(member)"
  />
</ng-template>

<ng-template #roleTemplate let-roleId="value" let-member="item">
  <app-dropdown-input
    [inputFormControl]="control"
    [options]="rolesOptions"
    appearance="sapphire-inline"
    placement="bottomLeft"
    size="s"
    [value]="roleId"
    (onChange)="changeRole(member, $event)"
  />
</ng-template>

<ng-template #statusTemplate let-status="value">
  <div class="status" [ngClass]="status">{{ status | titlecase }}</div>
</ng-template>
