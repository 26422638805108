// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Space } from '../types/space';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { SpaceParticipant } from '../types/space-participant';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { SpaceParticipantsFilters } from '../types/space-participants-filters';

// Env
import { environment } from '@environment';

@Injectable()
export class SpaceParticipantsService extends BaseRestService<SpaceParticipant, SpaceParticipantsFilters> {
 
  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(filters: Partial<SpaceParticipantsFilters>, { space }: { space: Space }): Observable<BaseSearchResponse<SpaceParticipant>> {
    const requestParams = { params: new SpaceParticipantsFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Space[], total: number }>(
      `${ environment.baseUrl }/api/account/spaces/${space.id}/participants`,
      requestParams
    )
      .pipe(
        map(({ count, items, total }) => ({
          count,
          items: (items || []).map(item => new SpaceParticipant(item)),
          total
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: SpaceParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<SpaceParticipant> {
    throw new Error('Method forbidden')
  }

  addParticipant(
    space: Space,
    email: string,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants`,
      { email }
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${email} added to space ${space.title}.` });
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: SpaceParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<SpaceParticipant> {
    return this.http.put<{ success: boolean, item: SpaceParticipant }>(
      `${environment.baseUrl}/api/account/spaces/${instance.spaceId}/participants/${instance.id}`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${instance.userName} role updated.` });
        }),
        map(({ item }) => new SpaceParticipant(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  accept(
    space: Space,
    accepted: boolean,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ) {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants/accept`,
      { accepted }
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `You have ${ accepted ? 'accepted' : 'rejected' } an invitation to participate in a space ${space.title}.` });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  deleteParticipant(
    space: Space,
    participant: SpaceParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants/${participant.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${participant.userName} removed from space ${space.title}.` });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }
}
