@if (notebook) {
  <div
    class="stitch-list-item"
    [class.selected]="selected && !isDragging"
    [class.hovered]="contextMenuOpened"
    [class.with-bar]="withBar"
    [class.short]="short"
    [class.bar-expanded]="barExpanded"

    [appNotebookContextMenu]="notebook"
    (appNotebookContextMenuOpened)="contextMenuOpened = $event"
    (appNotebookContextMenuOpenNotebook)="open.emit()"
    (appNotebookContextMenuOpenNote)="openNote.emit($event)"

    appDraggable
    [appDraggableDisabled]="!dragEnabled"
    [appDraggableData]="dragData"
    [appDraggablePlaceholder]="dragPlaceholder"
    (appDraggableDraggingChanged)="isDragging = $event"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"
  >
    <div class="stitch-list-item-selection"></div>
    <div class="stitch-list-item-body">
      <div class="stitch-list-item-body-title">
        <div
          class="stitch-list-item-body-title-text"
          [class.blank]="!notebook.title"
        >
          {{ notebook.title || 'Untitled Notebook' }}
        </div>

        <stch-postpone-indicators
          class="stitch-list-item-body-title-postpone-indicators"
          [snoozed]="notebook.snoozed"
          [followed]="notebook.followed"
        />
      </div>

      <div class="stitch-list-item-body-item inline">
        Created: {{ notebook.createdAt | relativeDate : true : false }}
        <div class="stitch-list-item-body-item-separator"></div>
        Updated: {{ notebook.updatedAt | relativeDate : true : false }}
      </div>

      @if (!withTags && !withKnots && !short) {
        <div class="stitch-list-item-body-item">
          {{ notebook.description || 'No description' }}
        </div>
      }

      @if (withTags) {
        <app-list-item-tags
          class="stitch-list-item-body-knowledge"
          [tags]="notebook.tags"
        />
      }

      @if (withKnots) {
        <app-list-item-knots
          class="stitch-list-item-body-knowledge"
          [knots]="notebook.knots"
        />
      }

      @if (withBar) {
        <stch-bottom-bar
          [stitchItem]="notebook"
          (expand)="barExpanded = $event"
        />
      }

      @if (notebook.pinned || notebook.flagged) {
        <div class="stitch-list-item-body-markers">
          @if (notebook.pinned) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-pin"
            />
          }
          @if (notebook.flagged) {
            <mat-icon
              class="stitch-list-item-body-markers-item"
              svgIcon="sapphire-flagged"
            />
          }
        </div>
      }
    </div>

    @if (dragEnabled && actionsButtonEnabled) {
      <div class="stitch-list-item-hover-actions" [appClickStopPropagation]="true">
        <stch-quick-action
          icon="sapphire-email"
          (onClick)="createMail($event)"

          matTooltip="Send by email"
        />

        <stch-quick-action
          icon="sapphire-stitch"

          matTooltip="Stitch To"

          [appStitchToPopover]="notebook"
          (appStitchToPopoverOpened)="contextMenuOpened = $event"
        />

        <stch-quick-action
          icon="sapphire-trash"
          [active]="notebook.deleted"
          (onClick)="delete()"

          [matTooltip]="notebook?.deleted ? 'Restore from Trash' : 'Trash'"
        />

        <stch-quick-action
          icon="sapphire-ellipsis"

          matTooltip="More actions"

          stchPopover
          stchPopoverAppearance="sapphire-context-menu"
          stchPopoverPlacement="bottomLeft"
          stchPopoverTrigger="click"
          [stchPopoverShowUntil]="popoverClose"
          [stchPopoverTemplate]="quickActionsContextMenuTemplate"
          (stchPopoverVisibleChange)="contextMenuOpened = $event"

          [appClickStopPropagation]="true"
        />
      </div>
    }

    <div class="drop-zone"></div>

    @if (debug) { <div class="debug">{{ notebook.score }}</div> }
  </div>
} @else {
  <stch-skeleton [withBar]="withBar" [short]="short" />
}

<ng-template #dragPlaceholder>
  <app-notebook [item]="notebook" [dragEnabled]="false" [withBar]="withBar" [short]="short"/>
</ng-template>

<!--Hover actions context menu-->
<ng-template #quickActionsContextMenuTemplate>
  <app-context-menu-item
    icon="sapphire-pin"
    activeIcon="sapphire-pin-filled"
    appearance="sapphire"
    [title]="notebook.pinned ? 'Unpin' : 'Pin'"
    [active]="notebook.pinned"
    (execute)="pin($event)"
  />

  <app-context-menu-item
    icon="sapphire-flagged"
    activeIcon="sapphire-flagged-filled"
    appearance="sapphire"
    [title]="notebook.flagged ? 'Remove Favorites' : 'Add to Favorite'"
    [active]="notebook.flagged"
    (execute)="flag($event)"
  />

  <app-context-menu-divider appearance="sapphire" />

  <app-context-menu-item
    icon="sapphire-snooze"
    appearance="sapphire"
    [title]="!!notebook.snoozed ? 'UnSnooze' : 'Snooze'"
    [active]="!!notebook.snoozed"
    (execute)="snooze()"
  />

  <app-context-menu-item
    icon="sapphire-follow-up"
    appearance="sapphire"
    [title]="!!notebook.followed ? 'UnFollow' : 'Follow Up'"
    [active]="!!notebook.followed"
    (execute)="followUp()"
  />
</ng-template>
