import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

@Pipe({
  name: 'between'
})
export class BetweenPipe implements PipeTransform {

  transform(value: number, a: number, b: number, inclusive: boolean = true): unknown {
    if (
      isNil(value) || isNil(a) || isNil(b) ||
      typeof value !== 'number' || typeof a !== 'number' || typeof b !== 'number' ||
      isNaN(value) || isNaN(a) || isNaN(b)
    ) { return false }

    const min = Math.min(a, b)
    const max = Math.max(a, b)

    return inclusive
      ? value >= min && value <= max
      : value > min && value < max;
  }

}
