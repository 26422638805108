import { Routes } from '@angular/router';

// General
import { AccountComponent } from './components/general/account/account.component';
import { TimeDateComponent } from './components/general/time-date/time-date.component';
import { PlansComponent } from './components/general/plans/plans.component';
import { BillingComponent } from './components/general/billing/billing.component';
import { ChargesComponent } from './components/general/charges/charges.component';
import { NotificationsComponent } from './components/general/notifications/notifications.component';
import { SecurityComponent } from './components/general/security/security.component';

// Spaces
import { SpacesSpacesComponent } from './components/spaces/spaces/spaces.component';
import { SpacesSpaceComponent } from './components/spaces/space/space.component';
import { SpacesTeamsComponent } from './components/spaces/teams/teams.component';
import { SpacesTeamComponent } from './components/spaces/team/team.component';

// Mail App
import { GeneralComponent as MessagesGeneralComponent } from './components/messages/general/general.component';
import { ImapComponent } from './components/messages/imap/imap.component';
import { PermissionsComponent } from './components/messages/permissions/permissions.component';
import { ImportComponent } from './components/messages/import/import.component';
import { SignaturesComponent } from './components/messages/signatures/signatures.component';
import { AutoRepliesComponent } from './components/messages/auto-replies/auto-replies.component';
import { TemplatesComponent } from './components/messages/templates/templates.component';
import { QuickRepliesComponent } from './components/messages/quick-replies/quick-replies.component';

// Calendar App
import { CalendarComponent } from './components/calendar/calendar/calendar.component';
import { GeneralComponent as CalendarGeneralComponent } from './components/calendar/general/general.component';

// Tasking App
import { GeneralComponent as TaskingGeneralComponent } from './components/tasking/general/general.component';
import { DueDateComponent } from './components/tasking/due-date/due-date.component';

// Notes App
import { GeneralComponent as NotesGeneralComponent } from './components/notes/general/general.component';
import { AdditionalComponent } from './components/notes/additional/additional.component';

import { ContactsComponent } from './components/contacts/contacts.component';
import { FilesComponent } from './components/files/files.component';
import { SharedComponent } from './components/common/shared/shared.component';
import { RulesComponent } from './components/rules/rules.component';

export const routes: Routes = [
  { path: 'general', children: [
    { path: 'account', component: AccountComponent },
    { path: 'time', component: TimeDateComponent },
    { path: 'plans', component: PlansComponent },
    { path: 'billing', component: BillingComponent },
    { path: 'charges', component: ChargesComponent },
    { path: 'notifications', component: NotificationsComponent },
    { path: 'security', component: SecurityComponent },
    { path: '', redirectTo: 'account', pathMatch: 'full' },
  ] },
  { path: 'spaces', children: [
    { path: '', component: SpacesSpacesComponent, },
    { path: ':id', component: SpacesSpaceComponent },
  ] },

  { path: 'teams', children: [
    { path: '', component: SpacesTeamsComponent, },
    { path: ':id', component: SpacesTeamComponent },
  ] },
  { path: 'mail', children: [
    { path: 'general', component: MessagesGeneralComponent },
    { path: 'imap-pop', component: ImapComponent },
    { path: 'permissions', component: PermissionsComponent },
    { path: 'import', component: ImportComponent },
    { path: 'signatures', component: SignaturesComponent },
    { path: 'auto-reply', component: AutoRepliesComponent },
    { path: 'templates', component: TemplatesComponent },
    { path: 'quick-reply', component: QuickRepliesComponent },
    { path: 'shared/:stitchType', component: SharedComponent },
    { path: '', redirectTo: 'general', pathMatch: 'full' },
  ] },
  { path: 'calendar', children: [
    { path: 'default', component: CalendarGeneralComponent },
    { path: 'calendar', component: CalendarComponent },
    { path: 'shared/:stitchType', component: SharedComponent },
    { path: '', redirectTo: 'default', pathMatch: 'full' },
  ] },
  { path: 'tasking', children: [
    { path: 'default', component: TaskingGeneralComponent },
    { path: 'due_dates', component: DueDateComponent },
    { path: 'shared/:stitchType', component: SharedComponent },
    { path: '', redirectTo: 'default', pathMatch: 'full' },
  ] },
  { path: 'notes', children: [
    { path: 'default', component: NotesGeneralComponent },
    { path: 'additional', component: AdditionalComponent },
    { path: 'shared/:stitchType', component: SharedComponent },
    { path: '', redirectTo: 'default', pathMatch: 'full' },
  ] },
  { path: 'contacts', children: [
    { path: '', component: ContactsComponent, pathMatch: 'full' },
    { path: 'shared/:stitchType', component: SharedComponent },
  ] },
  { path: 'files', children: [
    { path: '', component: FilesComponent, pathMatch: 'full' },
    { path: 'shared/:stitchType', component: SharedComponent },
  ] },
  { path: 'rules', component: RulesComponent },
  { path: '', redirectTo: 'general', pathMatch: 'full' },
];
