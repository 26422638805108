<div
  class="header"
  [class.active]="active"
  [style.paddingLeft.px]="8 + depth * 28"
>
  <div
    class="collapse-icon-container"
    [class.minimized]="minimized"
  >
    <mat-icon
      class="collapse-icon"
      svgIcon="sapphire-chevron-right"
      [class.no-icon]="!expandable"
      [class.expanded]="expanded"
      (click)="trigger($event)"
    />
  </div>

  <div
    class="icon-container"
    *ngIf="iconPadding"
  >
    <mat-icon
      class="icon"
      *ngIf="icon && !withCheckbox"
      [svgIcon]="icon"
      [style.color]="iconColor"
    />

    <mat-icon
      class="icon"
      *ngIf="withCheckbox"
      [svgIcon]="checked ? 'sapphire-checkbox-on' : 'sapphire-checkbox-off'"
      [style.color]="iconColor"
      (click)="onCheck.emit(); $event.stopPropagation()"
    />
  </div>

  <div *ngIf="badge" class="badge-container">
    <ng-container
      [ngTemplateOutlet]="badge.template"
      [ngTemplateOutletContext]="badge.context"
    />
  </div>

  <div
    class="title"
    [class.minimized]="minimized"
  >
    {{ title }}
  </div>

  <div
    class="actions"
    [class.minimized]="minimized"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      *ngFor="let action of actions"
      class="action"
      [svgIcon]="action.icon"
      (click)="$event.stopPropagation()"
      stchPopover
      [stchPopoverArrow]="true"
      [stchPopoverContent]="action.template"
      [stchPopoverContext]="action.context"
      stchPopoverPlacement="right"
      stchPopoverTrigger="click"
      [stchPopoverShowUntil]="action.until"
    />

    <ng-content />
  </div>
</div>
