// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-layout',
  templateUrl: './mail-layout.component.html',
  styleUrls: ['./mail-layout.component.less', '../../styles.less']
})
export class MailLayoutComponent {

}
