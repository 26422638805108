// Common
import { Pipe, PipeTransform } from '@angular/core';
import { generateSecondaryColor } from '@modules/common/utils/color';

@Pipe({
  name: 'secondaryColor'
})
export class SecondaryColorPipe implements PipeTransform {

  transform(color: string): string {
    return generateSecondaryColor(color);
  }
}
