<stch-table
  [items]="formGroups"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
/>

<ng-template #titleTemplate let-form=item let-control=value>
  <app-input
    appearance="sapphire-inline"
    [inputFormControl]="control"
    placeholder="Title"
    size="s"
    [disabled]="form.controls.id.value === 'admin' || form.controls.id.value === 'regular'"
  />
</ng-template>

<ng-template #checkboxTemplate let-form=item let-control=value>
  <app-checkbox
    [inputFormControl]="control"
    appearance="sapphire"
    [disabled]="form.controls.id.value === 'admin' || form.controls.id.value === 'regular'"
    size="s"
  />
</ng-template>

<ng-template #actionsTemplate let-form=item>
  <app-button
    *ngIf="form.controls.id.value"
    appearance="sapphire-secondary-ghost"
    size="xs"
    icon="sapphire-ellipsis"
    matTooltip="More"

    stchPopover
    stchPopoverPlacement="bottomRight"
    stchPopoverAppearance="sapphire-context-menu"
    [stchPopoverFallbackPlacements]="['topRight']"
    [stchPopoverTemplate]="actionsDropdownTemplate"
    [stchPopoverContext]="{ role: form }"
    stchPopoverTrigger="click"
  />
</ng-template>

<ng-template #actionsDropdownTemplate let-role="role">
  <app-context-menu-item
    title="Delete Role"
    appearance="sapphire"
    [dangerous]="true"
    (execute)="removeRolePrompt(role)"
  />
</ng-template>
