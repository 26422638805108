<div
  class="container"
  #container
  [style.height.px]="initialHeight"
>
  <ng-content />

  @if (isResizing) {
    <div class="overlay"></div>
  }

  <mat-icon
    class="resize-handle"
    svgIcon="sapphire-resize-handle"
    (mousedown)="onResizeStart($event)"
  />
</div>
