<app-split-view
  side="left"
  key="contactsSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="120"
  [withIcon]="true"
>
  <div class="sidebar" split-left-pane *stchSpaceRerender>
    <stch-side-bar-teams
      [template]="sidebarTemplate"
      splitViewKey="contactsSidebar"
    />
  </div>

  <app-split-view
    split-right-pane
    side="left"
    key="contactsList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="300"
    [withIcon]="true"
  >
    <app-contacts-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    />

    <div class="full-form" split-right-pane>
      <app-contacts-main-view *stchSpaceRerender />
    </div>
  </app-split-view>
</app-split-view>

<ng-template #sidebarTemplate let-team="team">
  <app-contacts-side-bar [team]="team"/>
</ng-template>
