<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByContactsFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'contacts'"
          svgIcon="filters"
          appContactsListContextMenu
          [appContactsListContextMenuWithAdvancedSearch]="true"
          [appContactsListContextMenuStateKey]="contactsListStateKey"
          (appContactsListContextMenuChange)="handleContactsListStateChange($event)"
        ></app-context-menu-state-indicator>
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'groups'"
          svgIcon="filters"
          appGroupsListContextMenu
          [appGroupsListContextMenuWithAdvancedSearch]="true"
          [appGroupsListContextMenuStateKey]="groupsListStateKey"
          (appGroupsListContextMenuChange)="handleGroupsListStateChange($event)"
        ></app-context-menu-state-indicator>
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    ></app-tabs>
  </div>

  <app-quick-form
    *ngIf="
      selectedTab === 'groups' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Group"
  >
    <app-group-quick-form
      [group]="quickGroup"
      [autofocus]="true"
      (more)="handleNewGroup($event)"
    ></app-group-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedTab === 'contacts' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Contact"
  >
    <app-contact-quick-form
      [contact]="quickContact"
      [autofocus]="true"
      (more)="handleNewContact($event)"
    ></app-contact-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-contacts-list
      *ngIf="selectedTab === 'contacts'"
      [filters]="contactsFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openContact)="handleClickContact($event)"
      (clickContact)="handleClickContact($event)"
      (doubleClickContact)="handleDoubleClickContact($event)"
    ></app-contacts-list>

    <app-groups-list
      *ngIf="selectedTab === 'groups'"
      [filters]="groupsFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openGroup)="handleClickGroup($event)"
      (openContact)="handleClickContact($event)"
      (clickGroup)="handleClickGroup($event)"
      (doubleClickGroup)="handleDoubleClickGroup($event)"
    ></app-groups-list>
  </div>
</div>
