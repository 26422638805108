// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// Types
import { Upload } from '@modules/common/types/upload';

// Rx
import { Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'stch-upload',
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.less',
})
export class UploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() upload: Upload;
  @Input() uploadInProgress = false;
  @Input() size: 's' | 'm' | 'l' = 'm';

  @Output() retry: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  public showDoneIcon: boolean = false;

  private uploadChanged = new Subject<void>();
  private alive: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.uploadChanged
      .pipe(
        switchMap(() => this.upload.progress),
        filter(progress => progress === 100),
        tap(() => this.showDoneIcon = true),
        switchMap(() => timer(2000)),
        tap(() => this.showDoneIcon = false),
        takeUntil(this.alive)
      )
      .subscribe();

    this.uploadChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('upload' in changes) { this.uploadChanged.next(); }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

}
