<app-split-view
  side="left"
  key="eventsSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="120"
  [withIcon]="true"
>
  <div class="sidebar" split-left-pane *stchSpaceRerender>
    <stch-side-bar-teams
      [template]="sidebarTemplate"
      splitViewKey="eventsSidebar"
    />
  </div>

  <app-split-view
    split-right-pane
    side="left"
    key="eventsList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="260"
    [withIcon]="true"
  >
    <app-events-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    />

    <div class="full-form" split-right-pane>
      <stch-calendar-app-main-view *stchSpaceRerender />
    </div>
  </app-split-view>
</app-split-view>

<ng-template #sidebarTemplate let-team="team">
  <app-events-side-bar [team]="team"/>
</ng-template>
