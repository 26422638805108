// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-contacts-layout',
  templateUrl: './contacts-layout.component.html',
  styleUrls: ['./contacts-layout.component.less', '../../styles.less']
})
export class ContactsLayoutComponent {

}
