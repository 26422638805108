// Types
import { Like } from '@modules/common/types/like';
import { Filters } from '@modules/common/types/filters';

export class RowsFilters extends Filters {
  projectId?: string;
  title?: string;

  constructor (filters?: Like<RowsFilters>) {
    super(filters);
    this.projectId = filters?.projectId;
    this.title = filters?.title;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.projectId) { result['project_id'] = this.projectId; }
    if (this.title) { result['title'] = this.title; }

    return result;
  }
}
