<div
  class="heading"
  (resize)="handleResize($event)"
>
  <app-circular-progress
    *ngIf="task.progress >= 0"
    [progress]="task.progress"
    size="20"
    [priority]="form.controls.priority.value"
    (onClick)="handleComplete()"
  />

  <app-input
    class="input"
    appearance="sapphire-inline"
    [inputFormControl]="form.controls.title"
    placeholder="New Task"
    [validationMessages]="{ required: 'Can\'t be blank' }"
    [withErrors]="submitted"
    size="m"
  />

  <div class="icons">
    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="bug"
      [active]="selectedTab === 'debug'"
      matTooltip="Debug"
      (onClick)="selectedTab = 'debug'"
    />

    <app-button
      *ngIf="!contracted"
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-stitch"
      [disabled]="!task?.id"
      matTooltip="Stitch To"
      [appStitchToPopover]="task"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-priority-flag"
      matTooltip="Priority"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-archive2"
      [active]="form.controls.archived.value"
      [matTooltip]="form.controls.archived.value ? 'Move To Archive' : 'Restore From Archive'"
      (onClick)="archive()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-trash"
      [active]="form.controls.deleted.value"
      [matTooltip]="form.controls.deleted.value ? 'Move To Trash' : 'Restore From Trash'"
      (onClick)="delete()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-share"
      matTooltip="Share"
      [disabled]="!task?.id"
      [stchShareContextMenu]="task"
      [stchShareContextMenuDisabled]="!task?.id"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-ellipsis"
      matTooltip="More Options"
      [appProjectContextMenu]="task"
      appProjectContextMenuMode="popover"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>
</div>

<div class="tabs">
  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [(value)]="selectedTab"
    [selectedStateKey]="tabsStateKey"
    size="m"
  />

  <div class="filler"></div>

  <app-button appearance="sapphire-secondary" size="xs" label="Assign" />

  <stch-knowledge-button
    [active]="displayKnowledge"
    (click)="showKnowledge()"
  />
</div>

<div
  class="form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>

  <div class="form-heading">
    <div class="form-heading-nav">
      <div class="form-heading-nav-separator"></div>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'task'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appTasksListContextMenu
        (appTasksListContextMenuChange)="subTasksListState.next($event)"
      ></app-context-menu-state-indicator>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'stitch'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appStitchListContextMenu
        (appStitchListContextMenuChange)="stitchListState = $event"
      ></app-context-menu-state-indicator>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'lab'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appKnowledgeChartContextMenu
        (appKnowledgeChartContextMenuChange)="chartState = $event"
        matTooltip="Graph Filters"
      ></app-context-menu-state-indicator>
    </div>

    <div
      *ngIf="displayKnowledge"
      class="form-heading-nav-underline"
    ></div>

    <app-tags
      class="form-tags"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[task]"
      [control]="form.controls['tags']"
      stateKey="ff-tags"
    ></app-tags>

    <app-knots
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[task]"
      [control]="form.controls['knots']"
      stateKey="ff-knots"
    ></app-knots>

    <app-connections
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[task]"
      [control]="form.controls['connections']"
      stateKey="ff-connections"
    ></app-connections>

    <div class="form-heading-nav-underline"></div>

    <div class="form-heading-quick-controls">
      <div class="form-heading-quick-controls-item no-border">
        <app-priority-input
          [control]="form.controls.priority"
          [withLabel]="true"
        ></app-priority-input>
      </div>
    </div>
  </div>

  <app-scroll
    *ngIf="selectedTab !== 'lab'"
    [ngSwitch]="selectedTab"
    class="form-scroll"
    (top)="displayScrollShadow = !$event"
  >
    <div
      *ngIf="displayScrollShadow"
      class="form-scroll-shadow"
    ></div>

    <app-task-form-subtasks
      *ngSwitchCase="'task'"
      [task]="task"
      [filters]="tasksFilters"
      [triggerRefresh]="triggerRefreshSubtasks"
    ></app-task-form-subtasks>

    <div
      *ngSwitchCase="'details'"
      class="details"
      [class.contracted]="contracted"
    >
      <div class="details-row">
        <app-date-range-picker
          [style.width.%]="100"
          [fromDate]="form.controls.fromDate"
          [toDate]="form.controls.toDate"
          [fromTime]="form.controls.fromTime"
          [toTime]="form.controls.toTime"
          [reminders]="form.controls.reminders"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Project</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Add To Project"
          [suggestions]="suggestions"
          [inputFormControl]="form.controls.projectId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Assign To</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Assign To"
          [suggestions]="assigneeSuggestions"
          [inputFormControl]="form.controls.assigneeId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div
        class="details-row"
        *ngIf="form.controls.projectId.value"
      >
        <div class="details-row-label">Section</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Section"
          [suggestions]="sectionsSuggestions"
          [inputFormControl]="form.controls.sectionId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div
        class="details-row"
        *ngIf="form.controls.projectId.value"
      >
        <div class="details-row-label">Column</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Column"
          [suggestions]="columnsSuggestions"
          [inputFormControl]="form.controls.columnId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div
        class="details-row"
        *ngIf="form.controls.projectId.value"
      >
        <div class="details-row-label">Row</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Row"
          [suggestions]="rowsSuggestions"
          [inputFormControl]="form.controls.rowId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Description</div>
        <app-textarea
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.description"
          [rows]="3"
          [withClear]="true"
          placeholder="Description"
          [validationMessages]="{ required: 'Please enter a value' }"
          [withErrors]="submitted"
        />
      </div>
    </div>

    <app-attachments-list
      class="form-attachments"
      *ngSwitchCase="'attachments'"
      [stitchItem]="stitchItem"
      [inputControl]="form.controls.uploads"
      contextMenuStateVariant="tasks"
    />

    <app-linked-info-list
      *ngSwitchCase="'stitch'"
      class="form-linked-info"
      [stitch]="task"
      [state]="stitchListState"
      (clickStitchItem)="handleClickItem($event)"
      (dblClickStitchItem)="handleDblClickItem($event)"
    ></app-linked-info-list>

    <stch-comments
      *ngSwitchCase="'comments'"
      class="form-linked-info"
      [stitch]="task"
    ></stch-comments>

    <stch-json-viewer
      *ngSwitchCase="'debug'"
      [json]="task?.debug"
    ></stch-json-viewer>
  </app-scroll>

  <app-knowledge-chart
    *ngIf="selectedTab === 'lab'"
    class="form-lab"
    [sizeChanges]="displayKnowledge"
    [item]="task"
    [state]="chartState"
  ></app-knowledge-chart>
</div>
