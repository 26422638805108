// Common
import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";

// Types
import { Space } from '@modules/settings/types/space';
import { Tab } from '@modules/common/types/tab';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';
import { Account } from '@modules/account/types/account';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { SpacesService } from '@modules/settings/services/spaces.service';
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { SpaceParticipantsService } from '@modules/settings/services/space-participants.service';

// RX
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';

// Components
import { SpacesSpaceRoleFormComponent } from './space-role-form/space-role-form.component';

@Component({
  selector: 'app-settings-spaces-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.less']
})
export class SpacesSpaceComponent implements OnInit, OnDestroy {

  public space: Space;
  public form = new Space().asFormGroup();
  public total = 0;
  public currentPlan: SubscriptionPlan;
  public account: Account;
  public selectedTab = 'members';
  public tabs: Tab[] = [
    { title: 'Members', value: 'members'},
    { title: 'Details', value: 'details'},
    { title: 'Permissions', value: 'roles'},
  ];

  private alive = new Subject<void>();

  constructor (
    private alertsService: AlertService,
    private spacesService: SpacesService,
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private spaceParticipantsService: SpaceParticipantsService,
    private injector: Injector,
    private elementRef: ElementRef,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.accountService.getAccount(true),
      this.plansService.search()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.currentPlan = plans.find(plan => plan.id === account.subscriptionPlanId);
        this.account = account;
      });

    this.route.params
      .pipe(
        map(params => params['id']),
        switchMap(id =>
          this.spacesService.getOne(id)
        ),
        tap(space => {
          this.space = space;
          this.total = this.space.participants.length;
          this.form = this.space.asFormGroup();
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.spacesService.update(Space.fromFormGroup(this.form))
        ),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openParticipantForm() {
    this.alertsService.show({
      title: 'Add participant',
      body: `Enter participant's email address:`,
      inputs: [
        { type: 'simple', label: 'Email', name: 'email' }
      ],
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'ADD', click: data => this.addParticipant(data.email), close: true }
      ],
      showUntil: this.alive
    });
  }

  addParticipant(email: string) {
    this.spaceParticipantsService.addParticipant(this.space, email)
      .pipe(
        switchMap(() => this.spacesService.getOne(this.space.id)),
        takeUntil(this.alive)
      )
      .subscribe(space => {
        this.spaceParticipantsService.forceRefresh();
      });
  }

  handleTabChange(tab: string) {
    this.selectedTab = tab;
  }

  handleBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigate(['/settings/spaces']);
    }
  }

  handleUpgrade() {
    this.router.navigate(['/settings/general/plans']);
  }

  openRoleForm() {
    this.alertsService.show({
      appearance: 'sapphire-modal-custom',
      component: SpacesSpaceRoleFormComponent,
      context: { space: this.space },
      origin: this.elementRef.nativeElement,
      injector: this.injector,
    });
  }
}
