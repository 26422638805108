<app-dropdown-base
  [placeholder]="placeholder"
  [withClear]="withClear"
  [appearance]="appearance"
  [size]="size"
  [disabled]="disabled"
  [placement]="placement"
  [popoverHide]="popoverHide"
  [valueTemplate]="valueTemplate"
  [popoverTemplate]="popoverTemplate"
  [control]="control"
  [withErrors]="withErrors"
  [validationMessages]="validationMessages"
/>

<ng-template #valueTemplate>
  {{
    dateFormat === 'week'
      ? (control?.value | date : "MMM yyyy ('Week' w)")
      : (control?.value | date : 'd MMM y')
  }}
</ng-template>

<ng-template #popoverTemplate>
  <div class="dropdown">
    <stch-date-picker [control]="control" />
  </div>
</ng-template>
