<app-dropdown-base
  [class.inline]="inline"
  [control]="inputFormControl"
  [placeholder]="placeholder"
  [withClear]="withClear"
  [appearance]="appearance"
  [invertedColor]="invertedColor"
  [size]="size"
  [disabled]="disabled || !options"
  [icon]="currentOption?.icon || icon"
  [iconOnly]="iconOnly"
  [iconColor]="currentOption?.iconColor"
  [placement]="placement"
  [popoverHide]="popoverHide"
  [valueTemplate]="valueTemplate"
  [popoverTemplate]="popoverTemplate"
/>

<ng-template #valueTemplate>
  {{ currentOption?.title }}
</ng-template>

<ng-template #popoverTemplate>
  <stch-dropdown-container [appearance]="appearance | firstWord">
    <app-dropdown-item
      *ngFor="let option of options"
      [appearance]="appearance | firstWord"
      [option]="option"
      [itemTemplate]="itemTemplate"
      [active]="option.value === inputFormControl?.value || option.value === value"
      (click)="handleSelect(option)"
    />
  </stch-dropdown-container>
</ng-template>
