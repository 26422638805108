// Common
import { Component, ElementRef, EventEmitter, Input, OnChanges,
  OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// RX
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-multiple-input',
  templateUrl: './autocomplete-multiple-input.component.html',
  styleUrls: ['./autocomplete-multiple-input.component.less'],
})
export class AutocompleteMultipleInputComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() inputFormControl: UntypedFormControl;
  @Input() suggestions?: AutocompleteFactory<unknown>;
  @Input() valueKey?: string;
  @Input() title: string;
  @Input() placeholder = '';
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() containerStyles: any = {};
  @Input() noBorder = false;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() withClear = false;
  @Input() useInputText = false;
  @Input() useInputTextGetValue?: (value: string) => any;
  @Input() appearance: 'default' | 'amethyst' | 'sapphire' = 'default';
  @Input() invertedColor = false;
  @Input() itemStyles?: any = {};
  @Input() itemTitlePattern?: string;
  @Input() sideOptions?: DropdownSelectItem<unknown>[];
  @Input() sideValue?: string;
  @Input() optionsDraggable = false;

  // Outputs
  @Output() select = new EventEmitter<DropdownSelectItem<unknown>>();

  // Public
  public innerControl = new UntypedFormControl();
  public options: DropdownSelectItem<unknown>[] = [];
  public popoverCustomTrigger = new Subject<void>();
  public popoverHide = new Subject<void>();
  public selectedItems: DropdownSelectItem<unknown>[] = [];
  public focused: boolean;
  public loading = false;
  public selectedSideOption: DropdownSelectItem<unknown>;
  public sidePopoverHide = new Subject<void>();

  // Private
  private alive: Subject<void> = new Subject();
  private suggestionsChanged = new Subject<void>();
  private inputControlChanged = new Subject<void>();
  private cachedItems: DropdownSelectItem<unknown>[] = [];
  private loadMissingItems = new Subject<any>();
  private sideOption = new BehaviorSubject<DropdownSelectItem<unknown>>(null);

  // View Children
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestionsChanged
      .pipe(
        switchMap(() => combineLatest([
          this.innerControl.valueChanges.pipe(startWith(<string>null)),
          this.sideOption.pipe(startWith(this.sideOption.value))
        ])),
        filter(() => !!this.suggestions),
        tap(() => { this.loading = true; }),
        debounceTime(600),
        switchMap(([title, sideValue]) => this.suggestions(
          title,
          null,
          { except: this.selectedItems.map(i => i.value), sideValue: sideValue?.value }
        )),
        takeUntil(this.alive)
      )
      .subscribe(options => {
        this.loading = false;
        this.options = options;
        if (options.length && this.focused) {
          this.popoverCustomTrigger.next();
        }
      });

    this.suggestionsChanged.next();

    this.innerControl.valueChanges
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(value => {
        if (!value) {
          this.popoverHide.next();
        }
      });

    this.loadMissingItems
      .pipe(
        filter(() => !!this.suggestions),
        switchMap(missingItems => this.suggestions(undefined, missingItems)),
        takeUntil(this.alive)
      )
      .subscribe(missingItems => {
        if (missingItems.length) {
          this.cachedItems = [...this.cachedItems, ...missingItems];
          this.inputControlChanged.next();
        }
      });

    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl.valueChanges.pipe(startWith(this.inputFormControl.value))),
        map(values => Array.isArray(values) ? values : []),
        tap(values => {
          const missingItems = values
            .filter(value => !this.cachedItems.some(cachedItem => this.compare(cachedItem.value, value)));

          if (missingItems.length) {
            this.loadMissingItems.next(missingItems);
          }
        }),
        takeUntil(this.alive)
      )
      .subscribe(values => {
        this.selectedItems = values
          .map(value => this.cachedItems.find(cachedItem => this.compare(cachedItem.value, value)))
          .filter(item => !!item);
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('suggestions' in changes) {
      this.suggestionsChanged.next();
    }

    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }

    if (('sideOptions' in changes || 'sideValue' in changes) && this.sideOptions) {
      this.selectedSideOption = this.sideValue
        ? this.sideOptions.find(option => option.value === this.sideValue)
        : this.sideOptions[0];

      this.sideOption.next(this.selectedSideOption);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFocus() {
    this.focused = true;

    if (this.innerControl.value) {
      this.popoverCustomTrigger.next();
    }
  }

  handleBlur() {
    this.focused = false;
    this.handleUseInputText(false);
  }

  handleClick() {
    this.popoverCustomTrigger.next();
  }

  handleSelect(option: DropdownSelectItem<unknown>, focusAfterSelect = true) {
    if (!this.inputFormControl) { return; }

    const currentValue = this.inputFormControl.value || [];

    if (currentValue.some(item => this.compare(item, option.value))) { return; }

    this.innerControl.setValue('');

    this.cachedItems.push(option);

    this.inputFormControl.setValue([...currentValue, option.value ]);
    this.inputFormControl.markAsDirty();

    this.select.emit(option);

    focusAfterSelect && this.inputElement.nativeElement.focus();
    this.popoverHide.next();
  }

  handleSideSelect(option: DropdownSelectItem<unknown>) {
    this.selectedSideOption = option;
    this.sideOption.next(option);
    this.sidePopoverHide.next();
  }

  handleClear() {
    this.inputFormControl.setValue([]);
    this.inputFormControl.markAsDirty();
  }

  handleDropped(item: DropdownSelectItem<unknown>) {
    this.inputFormControl.setValue(this.inputFormControl.value.filter(value => !this.compare(value, item?.value)));
    this.inputFormControl.markAsDirty();
  }

  removeItem(item: DropdownSelectItem<unknown>) {
    if (!this.inputFormControl) { return; }

    this.inputFormControl.setValue(this.inputFormControl.value.filter(value => !this.compare(value, item.value)));
    this.inputFormControl.markAsDirty();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter' || event.code === 'Comma') {
      event.stopPropagation();
      event.preventDefault(); // prevent form submit

      this.handleUseInputText();
    }
  }

  compare(value1, value2) {
    if (this.valueKey) {
      return value1?.[this.valueKey] === value2?.[this.valueKey];
    } else {
      return value1 === value2;
    }
  }

  private handleUseInputText(focusAfterSelect = true) {
    if (!this.useInputText) { return; }
    if (!this.innerControl.value?.trim()?.length) { return; }

    this.handleSelect(
      this.useInputTextGetValue
        ? this.useInputTextGetValue(this.innerControl.value)
        : { title: this.innerControl.value, value: this.innerControl.value },
      focusAfterSelect
    );
  }
}
