// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Note } from '@modules/notes/types/note';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

// Rx
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notebook',
  templateUrl: './notebook.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './notebook.component.less'],
})
export class NotebookComponent extends BaseStitchComponent<Notebook> {
  @Output() openNote = new EventEmitter<Note>();

  moveDragDataTypes = [DragDataTypes.note, DragDataTypes.notebook];
  dragDataType = DragDataTypes.notebook;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.notebook &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    injector: Injector,
    protected notebooksService: NotebooksService,
    private notesService: NotesService,
  ) { super(injector, notebooksService); }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    (() => {
      if (dragData.type === DragDataTypes.note) {
        return this.notesService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { notebookId: this.item.id },
          { message }
        );
      } else if (dragData.type === DragDataTypes.notebook) {
        return this.notebooksService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { parentId: this.item.id },
          { message }
        );
      }
    })()
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe()
  }

  get notebook(): Notebook {
    return this.item;
  }
}
