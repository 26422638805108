// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Team } from '../types/team';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { TeamRole } from '../types/team-role';
import { TeamRolesFilters } from '../types/team-roles-filters';

// Env
import { environment } from '@environment';

@Injectable()
export class TeamRolesService extends BaseRestService<TeamRole, TeamRolesFilters> {
 
  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(filters: Partial<TeamRolesFilters>, { team }: { team: Team }): Observable<BaseSearchResponse<TeamRole>> {
    const requestParams = { params: new TeamRolesFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Team[], total: number }>(
      `${ environment.baseUrl }/api/account/teams/${team.id}/roles`,
      requestParams
    )
      .pipe(
        map(({ count, items, total }) => ({
          count,
          items: (items || []).map(item => new TeamRole(item)),
          total
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: TeamRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<TeamRole> {
    return this.http.post<{ success: boolean, item: Team }>(
      `${environment.baseUrl}/api/account/teams/${ instance.teamId }/roles`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Role created.` });
        }),
        map(({ item }) => new TeamRole(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: TeamRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<TeamRole> {
    return this.http.put<{ success: boolean, item: TeamRole }>(
      `${ environment.baseUrl }/api/account/teams/${ instance.teamId }/roles/${ instance.id }`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Role updated' });
          }
        }),
        map(({ item }) => new TeamRole(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  delete(
    instance: TeamRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/account/teams/${instance.teamId}/roles/${instance.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || 'Team successfully deleted' });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }
}
