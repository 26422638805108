// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { AlertService } from '@modules/alert/services/alert.service';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { RRule } from 'rrule'

// RX
import { Subject, of } from 'rxjs';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-recurrence-picker',
  templateUrl: './recurrence-picker.component.html',
  styleUrls: ['./recurrence-picker.component.less'],
})
export class RecurrencePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: FormControl<string>;
  @Input() referenceDate: FormControl<Date>;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() placement: PopoverPlacement = 'bottomLeft';

  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<void>;

  public popoverHide = new Subject<void>();
  public focused = false;
  public placeholder: string;
  public innerControl = new FormControl<string>(null);

  private alive: Subject<void> = new Subject();
  private inputControlChanged = new Subject<void>();

  constructor(
    private alertsService: AlertService
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        switchMap(() => this.control?.valueChanges || of(null)),
        startWith(this.control?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        let rule: RRule;

        try {
          rule = RRule.fromString(value);
        } catch (e) {}

        this.placeholder = rule ? rule.toText() : 'Does Not Repeat';

        this.innerControl.setValue(value);
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect() {
    this.popoverHide.next();
  }

  handleCustom() {
    this.alertsService.show({
      appearance: 'sapphire-modal',
      title: 'Custom Recurrence',
      template: this.modalTemplate,
      width: 394,
      rightButtons: [
        {
          title: 'Cancel',
          close: true,
          appearance: 'sapphire-secondary',
          size: 's'
        },
        {
          title: 'Save',
          close: true,
          appearance: 'sapphire-primary',
          size: 's',
          click: () => this.handleSave(),
        }
      ]
    });
  }

  handleSave() {
    this.control.setValue(this.innerControl.value);
  }
}
