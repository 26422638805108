<div class="panel">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <app-dropdown-label
        appDPEventsContextMenu
        (appDPEventsContextMenuOpened)="contextMenuOpened = $event"
        (appDPEventsContextMenuFilterChanged)="selectFilter($event)"
        [collapsed]="!contextMenuOpened"
      >
        Events
      </app-dropdown-label>
      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          svgIcon="filters"
          appEventsListContextMenu
          [appEventsListContextMenuStateKey]="eventsListStateKey"
          (appEventsListContextMenuChange)="handleEventsListStateChange($event)"
        ></app-context-menu-state-indicator>

        <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
      </div>
    </div>

    <app-tabs
      class="panel-heading-tabs"
      selectedStateKey="dpEvents"
      [values]="tabs"
      [(value)]="selectedTab"
    ></app-tabs>
  </div>

  <app-quick-form
    class="panel-quick-form"
    placeholder="Add New Event"
  >
    <app-event-quick-form
      (more)="handleNewEvent($event)"
    ></app-event-quick-form>
  </app-quick-form>

  <app-date-picker-2
    class="panel-date-picker"
    *ngIf="selectedTab === 'today'"
    [inputFormControl]="todayFormControl"
    storeDateKey="kpEventsToday"
    [collapseable]="true"
    collapsedStateKey="dpEventsToday"
    (eventDropped)="handleDrop($event)"
  ></app-date-picker-2>

  <div class="panel-list">
    <stch-calendar-day
      *ngIf="selectedTab === 'today'"
      class="events-today-calendar"
      [viewDate]="todayFormControl.value"
      [events]="events"
      (dateClicked)="handleDateClicked($event)"
      (eventDropped)="handleDrop($event)"
    ></stch-calendar-day>

    <app-events-list
      *ngIf="selectedTab === 'events'"
      [filters]="eventsFilters"
      (openEvent)="openEvent($event)"
      (doubleClickEvent)="openEvent($event)"
    ></app-events-list>
  </div>
</div>

<ng-template #popoverFormTemplate>
  <app-quick-form
    [inline]="true"
  >
    <app-event-quick-form
      class="popover-form"
      [autofocus]="true"
      [(event)]="calendarEvent"
      (more)="handlePopoverFormMore($event)"
      (close)="handlePopoverFormClose()"
    ></app-event-quick-form>
  </app-quick-form>
</ng-template>
