<div
  class="trigger"
  [class.active]="opened"
  [class.disabled]="disabled"
  [ngClass]="appearance"

  stchPopover
  stchPopoverPlacement="bottomLeft"
  stchPopoverTrigger="click"
  [stchPopoverDisabled]="disabled"
  [stchPopoverTemplate]="popoverTemplate"
  [stchPopoverShowUntil]="closePopover"
  [stchPopoverFallbackPlacements]="['bottomRight', 'bottomLeft']"
  (stchPopoverVisibleChange)="opened = $event"
>
  @if(!(dateControl.value || timeControl.value)) {
    <mat-icon
      class="trigger-icon"
      svgIcon="sapphire-date"
    />
    Add Date
  }

  {{ dateControl?.value | date: 'MMM dd' }}{{ dateControl?.value && timeControl.value ? ', ' : '' }}
  {{ timeControl?.value | date: 'hh:mm a' }}

  @if (!disabled) {
    <mat-icon
      class="icon-close"
      svgIcon="sapphire-close"
      (click)="handleClear()"
    />
  }
</div>

<ng-template #popoverTemplate>
  <div class="header">
    <div
      class="header-input">
      <mat-icon class="icon" svgIcon="sapphire-date" />
      <div [class.filled]="!!innerDateControl.value">
        {{ (innerDateControl.value | date : 'yy/MM/dd') || 'Add Date' }}
      </div>

      @if(innerDateControl.value) {
        <mat-icon
          class="icon-close"
          svgIcon="sapphire-close"
          (click)="innerDateControl.setValue(null)"
        />
      }
    </div>
    <div
      class="header-input"

      stchPopover
      stchPopoverPlacement="bottomLeft"
      stchPopoverTrigger="click"
      [stchPopoverTemplate]="timeSelector"
      [stchPopoverFallbackPlacements]="[]"
      [stchPopoverNoBorder]="true"
    >
      <mat-icon class="icon" svgIcon="sapphire-time" />
      <div [class.filled]="!!innerTimeControl.value">
        {{ (innerTimeControl.value | date : 'hh/mm a') || 'Add Time' }}
      </div>

      @if(innerTimeControl.value) {
        <mat-icon
          class="icon-close"
          svgIcon="sapphire-close"
          (click)="innerTimeControl.setValue(null)"
        />
      }
    </div>
  </div>
  <stch-date-picker
    [control]="innerDateControl"
    [showQuickDateActions]="true"
  />
  <div class="footer">
    <app-button
      appearance="sapphire-primary"
      size="s"
      (click)="handleSave()"
      label="Apply"
    />
  </div>
</ng-template>

<ng-template #timeSelector>
  <stch-time-selector [control]="innerTimeControl"/>
</ng-template>
