<div
  #contentContainer
  class="quick-form"

  appDroppable
  appDroppableHoverClass="drag-over"
  appDroppableZIndex="10"
  (appDroppableDrop)="handleDrop($event)"
  [style.min-height.px]="(inline || expanded) ? formsSizes[currentFormSelector] : 24"
  [@collapseMotion]="{
    value: inline || expanded ? 'expanded' : 'collapsed',
    params: { height: formsSizes[currentFormSelector], contentHeight: contentHeight }
  }"
  (keyup.enter)="enterKeyPressed.next(); $event.stopPropagation()"
>
  <div
    class="quick-form-container"
    [class.visible]="inline || expanded"
  >
    @switch (currentFormSelector) {
      @case ('message-folder') { <ng-content select="app-message-folder-quick-form"/> }
      @case('message') { <ng-content select="app-message-quick-form"/> }
      @case('event') { <ng-content select="app-event-quick-form"/> }
      @case('calendar') { <ng-content select="app-calendar-quick-form"/> }
      @case('task') { <ng-content select="app-task-quick-form"/> }
      @case('project') { <ng-content select="app-project-quick-form"/> }
      @case('note') { <ng-content select="app-note-quick-form"/> }
      @case('notebook') { <ng-content select="app-notebook-quick-form"/> }
      @case('group') { <ng-content select="app-group-quick-form"/> }
      @case('contact') { <ng-content select="app-contact-quick-form"/> }
      @case('folder') { <ng-content select="app-folder-quick-form"/> }
      @case('file') { <ng-content select="app-file-quick-form"/> }
    }
  </div>

  <div
    class="quick-form-heading"
    [class.expanded]="inline || expanded"
    (click)="expand()"
  >
    <div class="quick-form-heading-title">{{ placeholder }}</div>
    <mat-icon
      class="quick-form-heading-icon"
      svgIcon="sapphire-chevron-down"
    />
  </div>
</div>
