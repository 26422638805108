<div class="skeleton">
  <div class="skeleton-item header"></div>
  <div class="skeleton-item middle"></div>
  <div
    *ngIf="!short"
    class="skeleton-item bottom"
  ></div>
  <div
    *ngIf="withBar"
    class="skeleton-bar"
  >
    <div class="skeleton-bar-group">
      <div class="skeleton-bar-group-item"></div>
      <div class="skeleton-bar-group-item"></div>
      <div class="skeleton-bar-group-item"></div>
    </div>
    <div class="skeleton-bar-group right">
      <div class="skeleton-bar-group-item"></div>
      <div class="skeleton-bar-group-item"></div>
    </div>
  </div>
</div>
