import { environment as prodEnvironment } from './environment.prod';

export const environment = {
  ...prodEnvironment,
  // This needs to be replaced later on by
  // a post-pocessing hook in the built javascript file.
  // Right now we do this while running the Docker image
  baseUrl: 'https://__REPLACE_BASE_URL__',
  authDomains: ['__REPLACE_BASE_URL__'],
};
