// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-message-folder-quick-form',
  templateUrl: './message-folder-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './message-folder-quick-form.component.less'],
})
export class MessageFolderQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() folder: MessageFolder = new MessageFolder();

  // Outputs
  @Output() more: EventEmitter<MessageFolder> = new EventEmitter<MessageFolder>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<MessageFolder> = new EventEmitter();

  constructor (
    private foldersService: MessageFoldersService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('folder' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const folder = MessageFolder.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(folder);
      return;
    }

    this.saveInProgress = true;

    this.foldersService.create(folder)
      .pipe(takeUntil(this.alive))
      .subscribe(
        folderInstance => {
          this.afterSave.emit(folderInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.folder) { return; }
    this.form = this.folder.asFormGroup();
    super.reset();
  }

  clickMore() {
    this.more.emit(MessageFolder.fromFormGroup(this.form));
    super.clickMore();
  }
}
