// Common
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@environment';

// Types
import { Team } from '@modules/settings/types/team';
import { TeamParticipant } from '@modules/settings/types/team-participant';
import { Account } from '@modules/account/types/account';
import { TeamParticipantsFilters } from '@modules/settings/types/team-participants-filters';
import { SortOrder } from '@modules/common/types/sort-order';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { TableColumn } from '@modules/common/types/table-column';
import { adminRole, regularRole } from '@modules/settings/types/team-role';

// RX
import { Subject, merge } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { AccountService } from '@modules/account/services/account.service';
import { TeamParticipantsService } from '@modules/settings/services/team-participants.service';
import { TeamRolesService } from '@modules/settings/services/team-roles.service';

@Component({
  selector: 'app-settings-teams-team-participants',
  templateUrl: './team-participants.component.html',
  styleUrls: ['./team-participants.component.less']
})
export class TeamsTeamParticipantsComponent implements OnInit, OnDestroy {

  public members: TeamParticipant[] = [];
  public count = 0;
  public columns: TableColumn[];
  public page = 0;
  public perPage = 10;
  public sortBy: TeamParticipantsFilters['sortBy'] = 'joinedAt';
  public sortOrder: SortOrder = 'asc';
  public searchControl = new FormControl<string>('');
  public mailUrl = environment.mailUrl;
  public statusControl = new FormControl<'pending' | 'accepted'>(null);
  public roleControl = new FormControl<string>(null);
  public statusOptions: DropdownSelectItem<string>[] = [
    { title: 'All Statuses', value: null },
    { title: 'Accepted', value: 'accepted' },
    { title: 'Pending', value: 'pending' },
  ];
  public rolesOptions: DropdownSelectItem<string>[] = [];
  public rolesFilterOptions: DropdownSelectItem<string>[] = [];
  public account: Account;

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @Input() team: Team;

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<void>;
  @ViewChild('roleTemplate', { static: true }) roleTemplate: TemplateRef<void>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<void>;

  constructor (
    private teamParticipantsService: TeamParticipantsService,
    private alertsService: AlertService,
    private accountService: AccountService,
    private teamRolesService: TeamRolesService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columns = [
      { key: 'fullName', title: 'User Name', sortable: true, template: this.nameTemplate },
      { key: null, title: 'Last Active', value: '15 min ago', legend: 'Not Implemented' },
      { key: 'status', title: 'Status', template: this.statusTemplate },
      { key: 'joinedAt', title: 'Joined On', sortable: true, dateFormat: 'd MMM y' },
      { key: 'roleId', title: 'Role', template: this.roleTemplate },
      { key: null, title: '', template: this.actionsTemplate, align: 'right' },
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.teamParticipantsService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage,
          title: this.searchControl.value,
          status: this.statusControl.value,
          roleId: this.roleControl.value,
        }, { team: this.team })),
        takeUntil(this.alive)
      ) 
      .subscribe(({ items: members, count }) => {
        this.members = members;
        this.count = count || 0;
      });

    this.reload.next();

    this.teamParticipantsService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });

    merge(
      this.searchControl.valueChanges,
      this.statusControl.valueChanges,
      this.roleControl.valueChanges,
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });

    this.accountService.getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe(account => {
        this.account = account;
      });

    this.teamRolesService.findAll({
      sortBy: 'title',
      sortOrder: this.sortOrder,
      limit: this.perPage,
      offset: this.page * this.perPage,
    }, { team: this.team })
      .pipe(takeUntil(this.alive))
      .subscribe(roles => {
        this.rolesOptions = [adminRole, regularRole, ...roles].map(role => ({ title: role.title, value: role.id }));
        this.rolesFilterOptions = [{ title: 'All Roles', value: null }, ...this.rolesOptions];
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: TeamParticipantsFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  removeParticipant(item: TeamParticipant) {
    this.teamParticipantsService.deleteParticipant(this.team, item)
      .pipe(takeUntil(this.alive))
      .subscribe(space => {
        this.reload.next();
      });
  }

  removeParticipantPrompt(item: TeamParticipant) {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to remove Participant ${item.userName} from Space ${this.team.title}?`,
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'REMOVE', click: () => this.removeParticipant(item), close: true }
      ],
      showUntil: this.alive
    });
  }

  changeRole(user: TeamParticipant, roleId: string) {
    user.roleId = roleId;

    this.teamParticipantsService.update(user)
      .pipe(takeUntil(this.alive))
      .subscribe();
  }
}
