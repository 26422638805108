<stch-inputs-group class="group" size="m">
  @for(buttonSet of buttonSets[toolbarType]; track $index) {
    <div class="container">
      @for(button of buttonSet; track $index) {
        <div class="button" [class.active]="state[button.state]">
          <mat-icon
            class="icon"
            [svgIcon]="button.icon"
            [matTooltip]="button.tooltip"
            (click)="execCommand(button.command)"
          />
        </div>
      }
    </div>
  }
</stch-inputs-group>
