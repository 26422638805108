// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// RX
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Environment
import { environment } from '@environment';

// Types
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { AutoReplyTemplate } from '@modules/account/types/auto-reply-template';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

@Injectable()
export class AutoReplyTemplatesService extends BaseService {

  constructor (
    private http: HttpClient,
    private toasterService: ToasterService,
  ) { super(); }

  search(filters?: unknown, config?: object): Observable<BaseSearchResponse<AutoReplyTemplate>> {
    return this.refreshRequired.pipe(
      switchMap(() => this.http.get<{ count: number, items: AutoReplyTemplate[] }>(environment.baseUrl + '/api/mail/auto-reply')),
      map(({ count, items = [] }) => ({ count: count, items: items.map(template => new AutoReplyTemplate(template)) }))
    );
  }

  create(instance: AutoReplyTemplate): Observable<AutoReplyTemplate> {
    return this.http.post<{ success: boolean, data: AutoReplyTemplate }>(
      environment.baseUrl + '/api/mail/auto-reply', instance.asPayloadJSON()
    )
      .pipe(map((response: any) => new AutoReplyTemplate(response.data)));
  }

  update(instance: AutoReplyTemplate): Observable<AutoReplyTemplate> {
    return this.http.put<{ success: boolean, data: AutoReplyTemplate }>(
      environment.baseUrl + '/api/mail/auto-reply/' + instance.id, instance.asPayloadJSON()
    )
      .pipe(map((response: any) => new AutoReplyTemplate(response.data)));
  }

  upsert(instance: AutoReplyTemplate, { emit, toast, message }: FeedbackConfig = { emit: true }): Observable<AutoReplyTemplate> {
    return (
      instance.id
        ? this.update(instance)
        : this.create(instance)
    )
      .pipe(
        tap(() => {
          emit && this.forceRefresh();
          toast && this.toasterService.show({
            text: message || `Quick reply template ${ instance.id ? 'updated' : 'created' } successfully`
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  delete(instance: AutoReplyTemplate, { emit, toast, message }: FeedbackConfig = { emit: true }): Observable<boolean> {
    return this.http.delete<boolean>(environment.baseUrl + '/api/mail/auto-reply/' + instance.id)
      .pipe(
        tap(() => {
          emit && this.forceRefresh();
          toast && this.toasterService.show({
            text: message || 'Template removed successfully'
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }
}
