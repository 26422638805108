<div class="heading">
  <app-button
    appearance="sapphire-secondary-ghost"
    size="m"
    icon="sapphire-arrow-left"
    (click)="handleBack()"
  />

  <app-color-input
    [control]="form.controls.color"
    appearance="sapphire"
    palette="spaces"
  />

  <app-input
    appearance="sapphire-inline"
    [inputFormControl]="form.controls.title"
    placeholder="Name"
    size="l"
    [validationMessages]="{ required: 'Please enter a value' }"
    [withErrors]="true"
  />

  <div class="filler"></div>

  <app-button
    appearance="sapphire-secondary"
    size="m"
    icon="sapphire-ellipsis"
  />

  <app-button
    *ngIf="selectedTab === 'members' || selectedTab === 'details'"
    appearance="sapphire-primary"
    size="m"
    label="Invite Members"
    icon="sapphire-plus"
    (click)="openParticipantForm()"
  />

  <app-button
    *ngIf="selectedTab === 'roles'"
    appearance="sapphire-primary"
    size="m"
    label="Add Role"
    icon="sapphire-plus"
    (click)="openRoleForm()"
  />
</div>

<app-tabs
  appearance="sapphire"
  class="tabs"
  [values]="tabs"
  [value]="selectedTab"
  (valueChange)="handleTabChange($event)"
  size="m"
/>

<div class="body">
  <div
    *ngIf="space && selectedTab === 'members'"
    class="stats"
  >
    <div class="stats-block">
      <div class="stats-block-value">{{ total }}</div>
      <div class="stats-block-title">Member</div>
    </div>
    <div class="stats-block">
      <div class="stats-block-value">N/A</div>
      <div class="stats-block-title">Admin</div>
    </div>
    <div class="stats-block">
      <div class="stats-block-value">N/A</div>
      <div class="stats-block-title">Can Edit</div>
    </div>
    <div class="stats-block">
      <div class="stats-block-value">N/A</div>
      <div class="stats-block-title">Can View Only</div>
    </div>
  </div>

  <div
    *ngIf="space && selectedTab === 'members'"
    class="label"
  >
    Workspace Participants
  </div>

  <app-settings-spaces-space-participants
    *ngIf="space && selectedTab === 'members'"
    [space]="space"
  />

  <app-settings-spaces-space-roles
    *ngIf="space && selectedTab === 'roles'"
    [space]="space"
  />

  <div
    class="details"
    *ngIf="selectedTab === 'details'"
  >
    <div class="details-block">
      <div class="details-block-title">
        Your plan is {{ currentPlan.title || 'N/A' }}
        <app-button
          appearance="sapphire-secondary"
          size="s"
          label="Upgrade"
          (click)="handleUpgrade()"
        />
      </div>
      <div class="details-block-text no-padding">{{ currentPlan.description }}</div>
      <div class="details-block-price">
        $
        {{ account.chargeType === 'monthly' ? currentPlan.monthlyPrice : currentPlan.annuallyPrice }}
        per
        {{ account.chargeType === 'monthly' ? 'month' : 'year' }}</div>
    </div>
    <div class="details-block">
      <div class="details-block-title">Team Description</div>
      <div class="details-block-text">{{ space.description }}</div>
      <div class="details-block-divider"></div>
      <div class="details-block-title">Creation Date</div>
      <div class="details-block-text">{{ space.createdAt | date : 'd MMM y' }}</div>
    </div>
  </div>
</div>
