// Common
import { Component, Injector } from '@angular/core';

// Services
import { MessagesService } from '@modules/messages/services/messages.service';

// Types
import { Message } from '@modules/messages/types/message';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './message.component.less']
})
export class MessageComponent extends BaseStitchComponent<Message> {
  moveDragDataTypes = [];
  dragDataType = DragDataTypes.message;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.message &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)


  constructor(
    injector: Injector,
    messagesService: MessagesService,
  ) { super(injector, messagesService); }

  handleMove(dragData: DragData) {}

  get message(): Message {
    return this.item;
  }
}
