<div class="container">
  <app-settings-messages-quick-reply/>

  @for(template of quickReplyTemplates; track template.id; let first = $first) {
    <app-settings-messages-quick-reply
      [template]="template"
      [first]="first"
    />
  }
</div>
