<div class="postpone-indicators">
  <ng-container *ngIf="snoozed | past">
    <mat-icon
      class="postpone-indicators-item snooze"
      svgIcon="sapphire-snooze"
    />
    <span *ngIf="!(followed | past)">{{ snoozed | date: 'MMM dd, hh:mm aa' }}</span>
  </ng-container>

  <ng-container *ngIf="followed | past">
    <mat-icon
      class="postpone-indicators-item follow"
      svgIcon="sapphire-follow-up"
    />
    <span *ngIf="!(snoozed | past)">{{ followed | date: 'MMM dd, hh:mm aa' }}</span>
  </ng-container>
</div>
