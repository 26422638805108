// Common
import { UntypedFormGroup, Validators } from '@angular/forms';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Message } from '@modules/messages/types/message';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { ChildStitch } from '@modules/common/types/child-stitch';
import { ContactEmail, ContactPhone } from '@modules/contacts/types/contact-info';
import { Country } from '@modules/common/types/country';
import { Stitch } from '@modules/common/types/stitch';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { Like } from '@modules/common/types/like';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

export class Contact extends ChildStitch {
  avatar?: string;
  files?: Attachment[];
  groupId?: string;
  emails?: ContactEmail[];
  phones?: ContactPhone[];
  role?: string;
  jobTitle?: string;
  address?: string;
  country?: Country;
  birthDate?: Date;
  title?: string;
  vip?: boolean;

  constructor(data: any = {}) {
    super(data);
    this.containerId = data?.containerId || data?.groupId;

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.avatar = data.avatar;
      this.groupId = data.groupId ?? data.containerId;
      this.emails = data.emails?.map((email) => new ContactEmail(email)) || [];
      this.phones = data.phones?.map((phone) => new ContactPhone(phone)) || [];
      this.title = data.title;
      this.role = data.role;
      this.jobTitle = data.jobTitle;
      this.address = data.address;
      this.country = Country.fromIso3(data.country || '');
      this.birthDate = data.birthDate && new Date(data.birthDate);
      this.vip = data.vip;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Contact {
    const formValues = form.value;

    return new Contact({
      address: formValues.address,
      archived: formValues.archived,
      avatar: formValues.avatar,
      birthDate: formValues.birthDate,
      color: formValues.color,
      connections: formValues.connections,
      country: formValues.country,
      deleted: formValues.deleted,
      description: formValues.description,
      emails: (formValues.emails || []).map((email) => new ContactEmail(email)),
      flagged: formValues.flagged,
      followed: formValues.followed,
      groupId: formValues.groupId,
      id: formValues.id,
      jobTitle: formValues.jobTitle,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      phones: (formValues.phones || []).map((phone) => new ContactPhone(phone)),
      pinned: formValues.pinned,
      position: formValues.position,
      snoozed: formValues.snoozed,
      starred: formValues.starred,
      tags: formValues.tags,
      temp: formValues.temp,
      title: formValues.title,
      uploads: formValues.uploads,
      vip: formValues.vip,
    });
  }

  static fromDragData(dragItem: DragData): Contact {
    return <Contact>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Contact {
    return new Contact({
      title: filters.query || filters.contacts.name,
      tags: filters.tags,
      knots: filters.knots,
      country: filters.contacts.country,
      description: filters.contacts.description,
      emails: [filters.contacts.email].filter(email => !!email),
      phones: [filters.contacts.phone].filter(phone => !!phone),
      groupId: filters.contacts.containersIds?.[0],
      jobTitle: filters.contacts.jobTitle,
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
      vip: folder === 'vip',
    });
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): {
    changes: Like<Contact>;
    message: string
  } {
    let message = 'Successfully moved to ';
    switch (sidebar) {
      case 'all_contacts':
        return { changes: { parentId: null, groupId: null }, message: message += 'Orphan' };
      case 'all_groups':
        break;
      case 'upcoming':
      case 'recent':
      case 'frequent':
      case 'vip':
        return { changes: { vip: true }, message: message += 'VIP' };
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  /**
   * Helpers
   */

  public static getTitleFromMail(message, account): { email: string, name: string } {
    let participants = [];

    if (message.from.email !== account?.email) {
    } else if (message.to.some(({ email }) => email !== account?.email)) {
      participants = message.to;
    } else if (message.cc.some(({ email }) => email !== account?.email)) {
      participants = message.cc;
    } else if (message.bcc.some(({ email }) => email !== account?.email)) {
      participants = message.bcc;
    }

    for (const { email, name } of participants) {
      if (email === account.email) { continue; }

      return { email, name };
    }
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'emails', 'groupId']);
  }

  /**
   * Instance methods
   */

  getStitchType(): StitchType {
    return StitchType.contact;
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      address: [this.address],
      archived: [this.archived],
      avatar: [this.avatar],
      birthDate: [this.birthDate],
      color: [this.color],
      connections: [this.connections],
      country: [this.country?.asIso3()],
      createdAt: [this.createdAt],
      deleted: [this.deleted],
      description: [this.description],
      emails: ContactEmail.asFormArray(this.emails),
      flagged: [this.flagged],
      followed: [this.followed],
      groupId: [this.groupId],
      id: [this.id],
      jobTitle: [this.jobTitle],
      knots: [this.knots],
      linkedInfo: [this.linkedInfo],
      phones: ContactPhone.asFormArray(this.phones),
      pinned: [this.pinned],
      position: [this.position],
      snoozed: [this.snoozed],
      social: [''],
      tags: [this.tags],
      temp: [this.temp],
      title: [this.title, Validators.required],
      updatedAt: [this.updatedAt],
      uploads: [this.uploads || [], FileValidators.size(26214400)],
      vip: [this.vip],
    });
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      avatar: this.avatar,
      color: this.color,
      createdAt: this.createdAt,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      followed: this.followed,
      groupId: this.groupId,
      emails: this.emails?.filter(({ value }) => !!value),
      phones: this.phones?.filter(({ value }) => !!value),
      pinned: this.pinned,
      vip: this.vip,
      title: this.title,
      updatedAt: this.updatedAt,
      jobTitle: this.jobTitle,
      address: this.address,
      country: this.country.asIso3(),
      birthDate: this.birthDate,
      snoozed: this.snoozed,
      noNotification: this.noNotification,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title, this.createdAt, this.createdAt, this.createdAt, this.createdAt
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.description = data.bodyText;
      this.emails = data.from.map(recipient => (new ContactEmail({ value: recipient.address })));
    } else if (data instanceof Contact) {
      this.avatar = data.avatar;
      this.groupId = data.groupId;
      this.emails = data.emails;
      this.phones = data.phones;
      this.title = data.title;
      this.role = data.role;
      this.jobTitle = data.jobTitle;
      this.address = data.address;
      this.country = data.country;
      this.birthDate = data.birthDate;
      this.vip = data.vip;
    }
  }
}
